import type { Locale, EnLocaleVariant } from '@/root/shared-types'
import { localeFormats } from '@/root/shared-consts'

const getDateLocale = (locale: Locale, enVariant: EnLocaleVariant = 'en-US'): string => {
  if (locale === 'en') {
    return enVariant
  }
  return localeFormats[locale]
}

export function timeFormat(date: Date, locale: Locale = 'en', enVariant: EnLocaleVariant = 'en-US'): string {
  const dateLocale = getDateLocale(locale, enVariant)
  return date.toLocaleDateString(dateLocale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    numberingSystem: 'latn',
  })
}
