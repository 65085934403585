import { useIntl } from 'react-intl'
import { useConfig } from '@/root/shared-hooks'
import { AvailableTheme, getMessages } from '@/lang'
import { bluewaveMessages } from '@/lang/bluewave/messages'
import { admaritimeMessages } from '@/lang/admaritime/messages'
import { Messages } from '@/root/shared-types'
import { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat'
import { Options as IntlMessageFormatOptions } from 'intl-messageformat/src/core'

export type Key = keyof typeof bluewaveMessages | keyof typeof admaritimeMessages
export const useGetTranslations = () => {
  const { formatMessage } = useIntl()
  const { theme } = useConfig()
  const currentMessages: Messages = getMessages(theme as AvailableTheme)

  const getTranslation = (
    key: Key,
    values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>,
    opts?: IntlMessageFormatOptions
  ) => {
    try {
      return formatMessage(currentMessages[key], values, opts)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`translation is missing for key: ${key}, but that's ok for now`)
      return key
    }
  }

  return { getTranslation }
}
