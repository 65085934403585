import { Dispatch, SetStateAction } from 'react'
import { ContentContainer } from '@/root/components/shared'
import { useAuth, useCustomRouter, useGetAuthToken, useGetTranslations } from '@/root/shared-hooks'
import { HeaderNavBarProps } from '@/root/shared-types'
import { Button } from '@/root/ui/admaritime'
import Image from 'next/image'
import Link from 'next/link'
import { APP_ROUTES } from '@/root/shared-consts'

import { FC } from 'react'
import { Hamburger } from '@/root/ui/admaritime/Hamburger/Hamburger'

interface MainHeaderNavBarProps extends HeaderNavBarProps {
  mobileMenuOpen: boolean
  setMobileMenuOpen: Dispatch<SetStateAction<boolean>>
}

const MainHeaderNavBar: FC<MainHeaderNavBarProps> = ({ menuItems, mobileMenuOpen, setMobileMenuOpen }) => {
  const { getTranslation } = useGetTranslations()
  const { isAuth } = useGetAuthToken()
  const { logOut } = useAuth()
  const { push } = useCustomRouter()
  return (
    <div className="bg-white">
      <ContentContainer>
        <div className="flex h-28 justify-between pe-6 ps-3 md:h-32 lg:pe-[30px]">
          <Link href="/" className="darken flex w-[240px] items-center md:shrink-0 lg:w-fit">
            <Image alt="admaritime logo" src="/admaritime/images/logo.png" width="275" height="84" />
          </Link>

          <nav className="flex flex-col justify-center">
            {!mobileMenuOpen && <Hamburger onClick={() => setMobileMenuOpen(true)} />}

            <ul className="text-primary-base hidden items-center text-base font-bold md:flex">
              {menuItems.map(({ id, link, subject, className }) => {
                if (className === 'login') {
                  return (
                    <li key={id} className="ms-5 shrink-0 lg:ms-0">
                      {isAuth ? (
                        <Button variant="contained" onClick={logOut}>
                          Log out
                        </Button>
                      ) : (
                        <Button variant="contained" onClick={() => push(APP_ROUTES.signup)}>
                          {getTranslation('headerRegisterOrLoginButton')}
                        </Button>
                      )}
                    </li>
                  )
                }

                return (
                  <li key={id} className="darken ms-5 text-center lg:ms-0 lg:px-7">
                    <Link href={link}>{subject}</Link>
                  </li>
                )
              })}
            </ul>
          </nav>
        </div>
      </ContentContainer>
    </div>
  )
}

export default MainHeaderNavBar
