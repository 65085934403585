import { apiInstance, appInstance, cmsInstance, applePayInstance } from './axios/index'
import { currentConfig } from './theme'

export {
  appInstance as axios,
  cmsInstance as cmsAxios,
  apiInstance as apiAxios,
  currentConfig,
  applePayInstance as applePayAxios,
}
