import { useGetTranslations } from '@/root/shared-hooks'
import { TravelDuration } from '@/root/shared-types'
import * as R from 'ramda'

export const useTravelDurationMessage = (duration: TravelDuration) => {
  const { getTranslation } = useGetTranslations()

  if (!duration) return ''

  const { hours, minutes } = duration

  const getDurationMessage = R.cond([
    [R.always(hours > 0 && minutes > 0), R.always(getTranslation('durationWithHoursAndMinutes', { hours, minutes }))],
    [R.always(hours > 0), R.always(getTranslation('durationOnlyWithHours', { hours }))],
    [R.always(minutes > 0), R.always(getTranslation('durationOnlyWithMinutes', { minutes }))],
    [R.T, R.always('')],
  ])

  return getDurationMessage()
}
