import { FC } from 'react'
import { TableCell } from '@/root/components/admaritime/ItemsTable/components/TableRow/TableCell'

export const TableHeader: FC<{ columns: string[]; className?: string }> = ({ columns }) => {
  return (
    <div className="flex">
      {columns.map((cell) => (
        <TableCell title={cell} key={cell} isHeader />
      ))}
    </div>
  )
}
