import { createContext, ReactNode } from 'react'

type ContextType = {
  onClose: () => void
  onResize: () => void
  semiOpened: boolean
  setSemiOpened: (isSemiOpened: boolean) => void
  setDrawerBody: (body: JSX.Element) => void
  drawerBody: ReactNode | null
}
export const DrawerContext = createContext<ContextType>({
  semiOpened: true,
  onClose: () => {
    null
  },
  onResize: () => {
    null
  },
  setSemiOpened: () => {
    return
  },
  setDrawerBody: () => null,
  drawerBody: null,
})
