import { HeadRow, useDayPicker } from 'react-day-picker'

export function Head(): JSX.Element {
  const { styles, components } = useDayPicker()
  const HeadRowComponent = components?.HeadRow ?? HeadRow
  return (
    <thead
      style={styles.head}
      className="border-neutral-normal-lighter text-primary-base translate-y-2 border-b text-lg "
    >
      <HeadRowComponent />
    </thead>
  )
}
