import { FC, PropsWithChildren, useEffect } from 'react'
import { AvailableTheme, currentTranslations, getCurrentTranslations } from '@/lang'
import { IntlProvider } from 'react-intl'
import { rtlLocales } from '@/root/shared-consts'
import { useConfig, useCustomRouter } from '@/root/shared-hooks'

const I18nProvider: FC<PropsWithChildren> = ({ children }) => {
  const { locale } = useCustomRouter()
  const { theme } = useConfig()
  const currentLocaleTranslations = getCurrentTranslations(theme as AvailableTheme)[
    locale as keyof typeof currentTranslations
  ]

  useEffect(() => {
    if (rtlLocales.includes(locale)) {
      document.documentElement.setAttribute('dir', 'rtl')
      return
    }
    document.documentElement.setAttribute('dir', 'ltr')
  }, [locale])

  return (
    <IntlProvider locale={locale} messages={currentLocaleTranslations}>
      {children}
    </IntlProvider>
  )
}

export default I18nProvider
