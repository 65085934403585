import { FC } from 'react'
import { Customer, Group as GroupProps } from '@/root/shared-types'
import TableTitle from '@/root/modules/admaritime/CustomerInformation/PassengersAndGroups/components/TableTitle/TableTitle'

import TableHeader from '@/root/modules/admaritime/CustomerInformation/PassengersAndGroups/components/TableHeader/TableHeader'
import ProfileButton from '@/root/modules/admaritime/CustomerInformation/PassengersAndGroups/components/ProfileButon/ProfileButton'
import { useGetTranslations, useModal } from '@/root/shared-hooks'
import { Group } from '@/root/modules/admaritime/CustomerInformation/PassengersAndGroups/components/Group/Group'
import { ProfilePopUp } from '@/root/components/admaritime/ProfilePopUp/ProfilePopUp'
import { GroupsIcon } from '@/root/ui/shared/icons/GroupsIcon'

interface GroupsTableProps {
  groups: GroupProps[]
  customers: Customer[]
}

export const GroupsTable: FC<GroupsTableProps> = ({ groups, customers }) => {
  const { getTranslation } = useGetTranslations()
  const { setModalBody } = useModal()
  return (
    <div>
      <TableTitle icon={<GroupsIcon />} title={getTranslation('passengersAndGroupsTitle')} />
      <TableHeader
        labels={[getTranslation('groupsNameLabel'), getTranslation('numberOfGroupsLabel')]}
        className="grid grid-cols-[25%_70%_5%]"
      />
      <div className="flex flex-col justify-center">
        {groups.length === 0 && (
          <h1 className="text-neutral-medium-light my-10 w-96 self-center text-center text-lg font-bold uppercase">
            {getTranslation('profileNoGroupsPlaceholder')}
          </h1>
        )}
        {groups.map((group) => (
          <Group {...group} key={group.companyId} data-testid={`group-row-${group.legalName}`} customers={customers} />
        ))}
        <ProfileButton
          label={getTranslation('profileAddNewGroupButton')}
          className="mt-5 h-14 self-center"
          onClick={() => setModalBody(<ProfilePopUp type="addNewGroup" customers={customers} />)}
        />
      </div>
    </div>
  )
}
