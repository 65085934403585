import { faker } from '@faker-js/faker'

export const generateRandomCustomer = () => {
  return {
    firstName: faker.person.lastName(),
    lastName: faker.person.lastName(),
    email: faker.internet.email(),
    phoneNumber: faker.string.numeric(9),
    code: faker.string.numeric(4),
  }
}
