import { makeSafeRequest, handleStream } from './api/api-helpers'
import { decodeJWT } from './proxy/proxy-helpers'
import {
  getTermsByLocale,
  getItemsFromReservation,
  getItemsWithoutFee,
  getNumOfPriceCategorySubTypesFromItems,
  getVesselTitleFromReservation,
  getDepartureAndArrivalDataFromReservation,
  getPortsFromSailPackage,
  getDepartureAndArrivalFromSailPackage,
  getVehiclesFromItemsAndReservation,
  getSailRefIdsFromReservation,
  getRefundStatusesFromReservation,
  getPhoneProp,
  getItemsWithFee,
} from './reservation/reservation-helpers'
import { formatToFixedNumber, getDateFromISODate } from '@/root/shared-helpers/utils/utils'
import {
  dateToFormat,
  dateToFormatFromISODate,
  dateToFormattedString,
  formatedDateToAnotherFormat,
  isDateInPast,
} from '@/root/shared-helpers/date/dateHelpers'
import { checkIsDayDisabled } from '@/root/shared-helpers/day-picker/dayPicker'
import {
  validatePhoneNumber,
  validateEmail,
  validatePassword,
  isValidEmailOrPhoneNumber,
} from '@/root/shared-helpers/validators/validators'

export {
  makeSafeRequest,
  decodeJWT,
  getTermsByLocale,
  getItemsFromReservation,
  getNumOfPriceCategorySubTypesFromItems,
  getVesselTitleFromReservation,
  getItemsWithoutFee,
  getDepartureAndArrivalDataFromReservation,
  getPortsFromSailPackage,
  getDepartureAndArrivalFromSailPackage,
  getVehiclesFromItemsAndReservation,
  getSailRefIdsFromReservation,
  handleStream,
  getRefundStatusesFromReservation,
  getPhoneProp,
  formatToFixedNumber,
  getDateFromISODate,
  dateToFormat,
  dateToFormattedString,
  checkIsDayDisabled,
  getItemsWithFee,
  dateToFormatFromISODate,
  validatePhoneNumber,
  validateEmail,
  validatePassword,
  isValidEmailOrPhoneNumber,
  isDateInPast,
  formatedDateToAnotherFormat,
}
