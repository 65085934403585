import { FC } from 'react'
import { ItemsTable, RouteInfo } from '@/root/components/admaritime'
import { BookTicketPartProps } from '@/root/shared-types'
import { dateFormats } from '@/root/shared-consts'
import { dateToFormatFromISODate } from '@/root/shared-helpers'
import { useAppConfig } from '@/root/shared-hooks/appConfig/useAppConfig'

export const BookTicketPart: FC<BookTicketPartProps> = ({
  items,
  arrivalPort,
  departurePort,
  departure = '',
  columns,
}) => {
  const departureValue = dateToFormatFromISODate(departure, dateFormats.FULL_DATE_TIME_UPPER).toUpperCase()
  const { formatMoney } = useAppConfig()

  return (
    <div className="border-base-very-light flex flex-col gap-5 border-t py-10 last:border-b first:border-t-0 last:border-t-0 md:grid md:grid-cols-2 md:gap-10">
      <span className="flex justify-between">
        <RouteInfo routeInfo={{ portFrom: departurePort, portTo: arrivalPort }} />
        <p className="text-primary-base whitespace-pre-line text-xl font-bold">{departureValue}</p>
      </span>
      <ItemsTable items={items} columns={columns} formatMoney={formatMoney} />
    </div>
  )
}
