import { ButtonHTMLAttributes, FC } from 'react'
import { Button } from '@/root/ui/admaritime'

interface ActionButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  dataTestId?: string
  isLoading?: boolean
}

export const ActionButton: FC<ActionButtonProps> = ({ disabled, onClick, dataTestId, isLoading, children }) => (
  <Button
    className="flex max-h-16 w-40 items-center justify-center self-center text-xl font-bold uppercase"
    variant="black"
    disabled={disabled}
    data-testid={dataTestId}
    onClick={onClick}
  >
    {children}
  </Button>
)
