import { FC } from 'react'
import { Group, Customer as CustomerProps } from '@/root/shared-types'
import TableTitle from '@/root/modules/admaritime/CustomerInformation/PassengersAndGroups/components/TableTitle/TableTitle'
import { GroupsIcon } from '@/root/ui/shared/icons/GroupsIcon'
import TableHeader from '@/root/modules/admaritime/CustomerInformation/PassengersAndGroups/components/TableHeader/TableHeader'
import ProfileButton from '@/root/modules/admaritime/CustomerInformation/PassengersAndGroups/components/ProfileButon/ProfileButton'
import { useGetTranslations, useModal } from '@/root/shared-hooks'
import Customer from '@/root/modules/admaritime/CustomerInformation/PassengersAndGroups/components/Customer/Customer'
import { ProfilePopUp } from '@/root/components/admaritime/ProfilePopUp/ProfilePopUp'

interface CustomersTableProps {
  customers: CustomerProps[]
  groups: Group[]
}

export const CustomersTable: FC<CustomersTableProps> = ({ customers, groups }) => {
  const { getTranslation } = useGetTranslations()
  const { setModalBody } = useModal()

  return (
    <div>
      <TableTitle icon={<GroupsIcon />} title={getTranslation('savedPassengersTitle')} />
      <TableHeader
        labels={[getTranslation('fullNameLabel'), getTranslation('dateOfBirthLabel'), getTranslation('groupsLabel')]}
        className="grid-cols-[25%_25%_45%_5%]"
      />
      {customers?.map((customer, index) => (
        <Customer
          {...customer}
          groups={groups}
          key={customer.customerId}
          isOwner={index === 0}
          data-testid={`passenger-row-${customer.fullName}`}
        />
      ))}
      <div className="flex justify-center py-5">
        <ProfileButton
          label={getTranslation('addPassengerBtn')}
          className="text-normal h-14"
          onClick={() => setModalBody(<ProfilePopUp type="addNewPassenger" groups={groups} />)}
        />
      </div>
    </div>
  )
}
