import React, { Dispatch, FC, SetStateAction } from 'react'
import Image from 'next/image'
import { Button } from '@/root/ui/admaritime'
import { useCustomRouter, useGetSailPackage, useGetTranslations } from '@/root/shared-hooks'
import { SailPackagesBars } from '@/root/modules/admaritime/TaxiRoutes/components/SailPackagerBars/SailPackagesBars'

import { ImageProps, LocalizedLocation, RouteCardProps, SailPackagesGroups } from '@/root/shared-types'
import { DateTime } from 'luxon'
import { dateFormats, APP_ROUTES } from '@/root/shared-consts'

interface TaxiRoutesCardProps {
  routeCard: RouteCardProps<SailPackagesGroups>
  setImage: Dispatch<SetStateAction<ImageProps | undefined>>
  setSelectedRoute: Dispatch<SetStateAction<string | undefined>>
  selectedRoute?: string
  image?: ImageProps
}

export const TaxiRoutesCard: FC<TaxiRoutesCardProps> = ({
  routeCard,
  image,
  setImage,
  setSelectedRoute,
  selectedRoute,
}) => {
  const handleRouteChange = (event: React.ChangeEvent<HTMLInputElement>, image: ImageProps) => {
    setSelectedRoute(event.target.id)
    setImage(image)
  }
  const { getTranslation } = useGetTranslations()
  const router = useCustomRouter()
  const { setSailPackage } = useGetSailPackage()

  const baseUrl = APP_ROUTES.selectDateSail
  const dateTo = DateTime.now().toFormat(dateFormats.ISO_DATE)
  const url = `${baseUrl}/${selectedRoute}/${dateTo}`

  const allSailPackages = [
    ...routeCard.sailPackages.semiLoop,
    ...routeCard.sailPackages.fullDay,
    ...routeCard.sailPackages.fullLoop,
  ]
  const sailPackage = allSailPackages.find((pkg) => pkg.code === selectedRoute)

  const onButtonClick = () => {
    router.push({ pathname: url })
    setSailPackage({
      code: selectedRoute || '',
      backwardSailPackage: sailPackage?.backwardSailPackage,
      roundtrip: sailPackage?.roundtrip || false,
      sailRefs: [],
      route: routeCard.code,
      title: sailPackage?.titles?.en || '',
      titles: sailPackage?.titles as LocalizedLocation,
    })
  }

  return (
    <div className="mx-auto flex w-full max-w-screen-xl flex-col justify-between overflow-hidden rounded-xl bg-white shadow-md">
      <div className="flex">
        <SailPackagesBars
          selectedRoute={selectedRoute}
          handleRouteChange={handleRouteChange}
          semiLoopSailPackages={routeCard.sailPackages.semiLoop}
          fullDaySailPackages={routeCard.sailPackages.fullDay}
          fullLoopSailPackages={routeCard.sailPackages.fullLoop}
          portFrom={routeCard.from}
        />
        {image && (
          <Image
            src={`/api${image.url}`}
            alt={image.alternativeText}
            width={270}
            height={250}
            className="hidden h-[250px] w-[260px] shrink-0 object-cover object-left-top md:block"
          />
        )}
      </div>
      <div className="flex justify-center py-2">
        <Button className="text-xl uppercase" variant="contained" disabled={!selectedRoute} onClick={onButtonClick}>
          {getTranslation('continueButton')}
        </Button>
      </div>
    </div>
  )
}
