import { FC, InputHTMLAttributes } from 'react'
import { useGetTranslations } from '@/root/shared-hooks'
import { Button, CustomerInfoIcon } from '@/root/ui/admaritime'
import { Control, FieldErrors, FieldValues, UseFormTrigger } from 'react-hook-form'
import { CustomerFormField } from '@/root/components/admaritime'

interface CustomerInfoProps {
  fieldsIds: string[]
  fieldValuesContent: Record<string, { label: string; type: InputHTMLAttributes<HTMLInputElement>['type'] }>
  control: Control
  errors: FieldErrors
  trigger: UseFormTrigger<FieldValues>
  onBlur?(): void
  fillRandomData?(): void
}

export const CustomerInfo: FC<CustomerInfoProps> = ({
  fieldsIds,
  fieldValuesContent,
  control,
  errors,
  trigger,
  onBlur,
  fillRandomData,
}) => {
  const { getTranslation } = useGetTranslations()
  return (
    <div className="mb-5 flex w-full flex-col gap-3 md:pe-5">
      <span className="flex items-start gap-5">
        <CustomerInfoIcon color="base" width={20} className="mb-1" />
        <h1 className="text-2xl font-bold text-primary-base">
          {getTranslation('confirmationContactFormCustomerInformation')}
        </h1>
        <Button className="border" onClick={fillRandomData}>
          Fill random
        </Button>
      </span>
      <form className="flex flex-col flex-wrap justify-between gap-1 md:flex-row">
        {fieldsIds.map((id) => (
          <CustomerFormField
            control={control}
            key={id}
            id={id}
            {...fieldValuesContent[id]}
            errors={errors}
            trigger={trigger}
            onBlur={onBlur}
          />
        ))}
      </form>
    </div>
  )
}
