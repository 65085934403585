import React, { FC } from 'react'

interface LabelAndParameterProps {
  label: string
  parameter: string
}

export const LabelAndParameter: FC<LabelAndParameterProps> = ({ label, parameter }) => {
  return (
    <div className="flex flex-col justify-center gap-1 border-b border-neutral-normal-lighter">
      <p className="text-xs text-base-normal-light">{label}</p>
      <p className="text-sm text-primary-base">{parameter}</p>
    </div>
  )
}
