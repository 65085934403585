import { FC } from 'react'
import { Page } from '@/root/shared-types'
import { PageTitle } from '@/root/modules/admaritime'
import { ContentContainer } from '@/root/ui/admaritime'

type DefaultPageProps = Page
export const DefaultPage: FC<DefaultPageProps> = ({ subject, body }) => {
  return (
    <ContentContainer>
      <PageTitle>{subject}</PageTitle>
      <div className="flex w-full flex-col gap-3 text-lg" dangerouslySetInnerHTML={{ __html: body }}></div>
    </ContentContainer>
  )
}
