import { useEffect, useState } from 'react'

interface SetQrImgSrcProps {
  reservationId: number
  sailRefId: number
}

export const useSetQrImgSrc = ({ sailRefId, reservationId }: SetQrImgSrcProps) => {
  const [qrImgSrc, setQrImgSrc] = useState('')

  useEffect(() => {
    // client side only
    setQrImgSrc(`${window.origin}/api/reservation/${reservationId}/sail/${sailRefId}/qr`)
  }, [reservationId, sailRefId])

  return { qrImgSrc }
}
