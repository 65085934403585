import { FC, PropsWithChildren, ButtonHTMLAttributes } from 'react'
import cn from 'classnames'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'contained' | 'default' | 'black' | 'blue'
  label?: string
}

const buttonVariants = {
  contained: 'font-bold text-white bg-secondary rounded-lg py-4 px-8 darken border-none',
  default: 'font-bold text-secondary darken',
  black: 'bg-black py-4 px-8 text-white rounded-lg border',
  blue: 'text-primary-base',
}

const Button: FC<PropsWithChildren<ButtonProps>> = ({ children, variant = 'default', className, label, ...props }) => {
  return (
    <button
      className={cn('block disabled:bg-neutral-normal-light', buttonVariants[variant], className, label)}
      {...props}
    >
      {label}
      {children}
    </button>
  )
}

export default Button
