import React, { FC } from 'react'
import { VesselParameters } from '@/root/shared-types'
import { LabelAndParameter } from '@/root/modules/admaritime/Fleet/components/LabelAndParameter/LabelAndParameter'
import { useGetTranslations } from '@/root/shared-hooks'

export const VesselParametersBar: FC<VesselParameters> = ({
  crew,
  passengers,
  vehicles,
  length,
  tonnage,
  maxSpeed,
  built,
}) => {
  const { getTranslation } = useGetTranslations()
  return (
    <div className="grid-rows-[1fr 1fr 40px] grid h-[158px] grid-cols-3 rounded-b-xl bg-white px-5">
      <LabelAndParameter label={getTranslation('vesselCardLabelLength')} parameter={length} />
      <LabelAndParameter label={getTranslation('vesselCardLabelTonnage')} parameter={tonnage} />
      <LabelAndParameter label={getTranslation('vesselCardLabelMaxSpeed')} parameter={maxSpeed} />
      <LabelAndParameter label={getTranslation('vesselCardLabelCrew')} parameter={crew} />
      <LabelAndParameter label={getTranslation('vesselCardLabelPassengers')} parameter={passengers} />
      <LabelAndParameter label={getTranslation('vesselCardLabelVehicles')} parameter={vehicles} />

      <div className="col-span-full self-center text-xs text-primary-base">
        <p className="">
          {getTranslation('vesselCardLabelBuilt')}
          <span className="ps-2 font-bold">{built} </span>
        </p>
      </div>
    </div>
  )
}
