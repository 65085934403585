import { FC, PropsWithChildren } from 'react'
import { ConfigContext } from './ConfigContext'
import { BoraTheme } from '@/root/shared-types'
import { getCurrentConfig } from '@/configs/theme'

interface Props extends PropsWithChildren {
  theme: BoraTheme
}

const ConfigProvider: FC<Props> = ({ children, theme }) => {
  return <ConfigContext.Provider value={getCurrentConfig(theme)}>{children}</ConfigContext.Provider>
}

export default ConfigProvider
