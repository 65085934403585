import { FC } from 'react'
import { Col, Row } from '@/root/shared-types'
import { TableBody } from '@/root/modules/admaritime/TariffsPage/components/TableBody/TableBody'
import { TableHeader } from '@/root/components/admaritime'

interface TariffsTableProps {
  rows: Row[]
  cols: Col[]
}

export const TariffsTable: FC<TariffsTableProps> = ({ rows, cols }) => (
  <div className="w-full">
    <TableHeader cols={cols} className="grid grid-cols-2" />
    <TableBody rows={rows} />
  </div>
)
