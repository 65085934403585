import { IconProps } from '@/root/shared-types/ui/ui-types'
import { FC } from 'react'
import Icon from '@/root/ui/shared/Icon'

export const ChevronDownIcon: FC<IconProps> = ({ width = 10, height = 6, ...rest }) => (
  <Icon
    data-testid="ChevronDownIcon"
    type="ChevronDown"
    width={width}
    height={height}
    alt="ChevronDownIcon"
    ignoreRtl
    {...rest}
  />
)
