import { FC } from 'react'
import { useGetTranslations } from '@/root/shared-hooks'
import { ChevronDownIconWhite, Icon } from '@/root/ui/admaritime'

interface HeaderProps {
  onGoBack(): void
  displayGoBackButton: boolean
  onClose?(): void
}

const Header: FC<HeaderProps> = ({ onGoBack, displayGoBackButton, onClose }) => {
  const { getTranslation } = useGetTranslations()
  return (
    <div className="flex h-[85px] w-full items-center justify-center rounded-t-xl bg-base bg-manual-header bg-contain bg-right bg-no-repeat text-large text-white">
      {displayGoBackButton && (
        <button onClick={onGoBack} className="absolute left-5">
          <Icon type="BottomArrow" alt="arrow" width={16} className="rotate-90" ignoreRtl />
        </button>
      )}
      <span className="flex flex-col items-center">
        {onClose && (
          <button onClick={onClose}>
            <ChevronDownIconWhite />
          </button>
        )}
        <p>{getTranslation('userManualTitle')}</p>
      </span>
    </div>
  )
}

export default Header
