import { FC } from 'react'
import { ReservationItem } from '@/root/shared-types'
import { TableRow } from '@/root/components/admaritime/ItemsTable/components/TableRow/TableRow'
import { TableHeader } from '@/root/components/admaritime/ItemsTable/components/TableHeader/TableHeader'

interface ItemsTableProps {
  items: ReservationItem[]
  columns: string[]
  formatMoney: (price: number, hideCurrency?: boolean) => string
}
export const ItemsTable: FC<ItemsTableProps> = ({ items, columns, formatMoney }) => {
  return (
    <div className="flex w-full flex-col gap-3" data-testid="items-table">
      <TableHeader columns={columns} />
      {items.map((item) => (
        <TableRow formatMoney={formatMoney} key={item.sailPackageSeqN} item={item} className="flex" />
      ))}
    </div>
  )
}
