import { FormatMoney } from 'format-money-js'
import { priceCustomConfigSchema } from '@/root/utils/currency/validation'

import type { FormatMoneyOptions } from 'format-money-js/src/format-money'

const fallbackConfig: FormatMoneyOptions = {
  decimalPoint: '.',
  separator: ',',
  decimals: 2,
  symbol: ' €',
  append: true,
}

export const buildFormatter =
  (config: FormatMoneyOptions = fallbackConfig) =>
  (price: number, hideCurrency = false) => {
    const workingConfig = priceCustomConfigSchema.isValidSync(config) ? config : fallbackConfig
    const fm = new FormatMoney({ ...workingConfig, ...(hideCurrency && { symbol: '' }) })
    return `${fm.from(price) ?? ''}`
  }
