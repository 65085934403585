import { FC } from 'react'
import { MenuItem, UpperFooterItem } from '@/root/shared-types'
import Link from 'next/link'
import { SiteMapItemContainer } from '@/root/modules/admaritime/SiteMap/components/SitemapItemContainer/SiteMapItemContainer'
import { useOpenUserManual } from '@/root/shared-hooks/useOpenUserManual/useOpenUserManual'

interface SiteMapItemProps {
  item: UpperFooterItem | MenuItem
}

export const SiteMapItem: FC<SiteMapItemProps> = ({ item }) => {
  const { onManualOpen } = useOpenUserManual()

  if ('content' in item) {
    return (
      <div className="h-fit">
        <SiteMapItemContainer>
          <h2>{item.title}</h2>
        </SiteMapItemContainer>
        {
          <div className="flex flex-col gap-3 ps-3 pt-5">
            {item.content.map(
              ({ link, title }) =>
                link && (
                  <Link href={link} key={link + 'sitemap'} className="text-sm text-neutral">
                    {title}
                  </Link>
                )
            )}
          </div>
        }
      </div>
    )
  }
  if ('modal' in item) {
    return (
      <SiteMapItemContainer>
        {item.link === 'userGuideModal' ? (
          <button onClick={onManualOpen}>{item.subject}</button>
        ) : (
          <Link key={item.id} href={item.link}>
            {item.subject}
          </Link>
        )}
      </SiteMapItemContainer>
    )
  }
}
