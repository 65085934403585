import { axios } from '@/configs'

class ApplePayService {
  async getPaymentPayload(reservationId: number) {
    try {
      const {
        data: { paymentPayload },
      } = await axios.get(`/payment-payload/${reservationId}`)
      return paymentPayload
    } catch (error) {
      console.error(`Failed to fetch payment payload for reservation ID ${reservationId}:`, error)
    }
  }

  async LogApiCall(type: string, message: string): Promise<void> {
    const data = { type, message }

    try {
      const response = await axios.post('/apple-pay/log-api-call', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      console.log(response.data)
    } catch (error) {
      console.log(error)
    }
  }
}

const applePayService = new ApplePayService()

export default applePayService
