import winston from 'winston'
import * as R from 'ramda'

const { timestamp, combine, json: logType, printf, colorize } = winston.format

const consoleFormat = combine(
  colorize({
    message: true,
    level: true,
    colors: { info: 'green', error: 'red', debug: 'blue' },
  }),
  printf(({ level, message, ...rest }) => {
    const tStamp = new Date().toLocaleString()
    try {
      const body = R.isEmpty(rest) ? '' : `\n${JSON.stringify(rest)}`
      return `${tStamp} [${level}] ${message}${body}`
    } catch (e) {
      return `${tStamp} [${level}] ${message}`
    }
  })
)

const format = combine(timestamp(), logType())

const consoleLevel = process.env.LOG_LEVEL || 'info'
const formatForTheEnv = process.env.NODE_ENV === 'development' ? consoleFormat : format

const transports = [
  new winston.transports.Console({
    format: formatForTheEnv,
    level: consoleLevel,
  }),
]

const logger = winston.createLogger({
  level: consoleLevel,
  format,
  transports,
})

export { logger }
