import React, { FC } from 'react'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

interface ColorFilterProps {
  color: string
}

const ImageColorByFilter: FC<ColorFilterProps> = ({ color }) => {
  const colorHexWithHash = publicRuntimeConfig.twTheme?.colors[color]
  const colorHex = colorHexWithHash?.replace('#', '')

  const getColorValue = (start: number) =>
    colorHex ? (parseInt(colorHex.slice(start, start + 2), 16) / 255).toFixed(2) : '0.0'

  const red = getColorValue(0)
  const green = getColorValue(2)
  const blue = getColorValue(4)

  return (
    <svg style={{ display: 'none' }}>
      <defs>
        <filter id={`colorFilter-${color}`}>
          <feColorMatrix
            type="matrix"
            values={`
              0 0 0 0 ${red}
              0 0 0 0 ${green}
              0 0 0 0 ${blue}
              0 0 0 1 0
          `}
          />
        </filter>
      </defs>
    </svg>
  )
}

export default ImageColorByFilter
