import axios from 'axios'
import useSWRImmutable from 'swr/immutable'

export const useGetPassengersData = () => {
  const fetchCompaniesAndPassengers = async () => {
    const {
      data: { content: companies },
    } = await axios({
      url: `/api/user/companies`,
    })

    const { data: passengers } = await axios({
      url: `/api/user/customers`,
    })

    return { companies, passengers }
  }
  const { data, error, isLoading, mutate } = useSWRImmutable('passengersData', fetchCompaniesAndPassengers)
  return { data, error, isLoading, mutate }
}
