import React, { ChangeEvent, FC, HTMLAttributes } from 'react'
import RouteRadioBar from '@/root/modules/admaritime/TaxiRoutes/components/RouteRadioBar/RouteRadioBar'
import { ImageProps, LocalizedLocation, SailPackageForCard } from '@/root/shared-types'
import cn from 'classnames'
import { useCustomRouter } from '@/root/shared-hooks'

interface SailPackagesBarProps extends HTMLAttributes<HTMLDivElement> {
  sailPackages: SailPackageForCard[]
  handleRouteChange: (event: ChangeEvent<HTMLInputElement>, image: ImageProps) => void
  showTitle: boolean
  title: string
  selectedRoute?: string
  type?: 'semiloop' | 'fullDay' | 'fullLoop'
  portFrom?: LocalizedLocation
}

export const SailPackagesBar: FC<SailPackagesBarProps> = ({
  sailPackages,
  selectedRoute,
  handleRouteChange,
  showTitle,
  title,
  className,
  type = 'semiloop',
  portFrom,
}) => {
  const { locale } = useCustomRouter()

  return (
    <div className={cn('w-full flex flex-col gap-3', className)}>
      {showTitle && <h1 className="text-primary-base mx-auto text-xl font-bold">{title}</h1>}
      {sailPackages.map(({ code, image, titles, title }) => (
        <RouteRadioBar
          key={code}
          selectedRoute={selectedRoute}
          handleRouteChange={handleRouteChange}
          title={titles ? titles[locale] : title}
          code={code}
          type={type}
          image={image}
          portFrom={portFrom ? portFrom[locale] : ''}
        />
      ))}
    </div>
  )
}
