import { useEffect, useState } from 'react'
import { applePayService } from '@/root/shared-api'
import { useAppConfig } from '@/root/shared-hooks/appConfig/useAppConfig'
import axios from 'axios'
import { ApplePayPaymentAuthorizedEvent, PaymentPayload } from '@/root/shared-types'
import { paymentResponseStatuses } from '@/root/shared-consts'

export const useApplePay = ({ reservationId }: { reservationId: number }) => {
  const [isApplePayAvailable, setIsApplePayAvailable] = useState(false)
  const [isSessionActive, setIsSessionActive] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined' && window.ApplePaySession) {
      setIsApplePayAvailable(true)
    }
  }, [])

  const { defaultCountry, formatMoney } = useAppConfig()

  const applePay = async (paymentPayload: PaymentPayload) => {
    if (!isApplePayAvailable) {
      return
    }

    try {
      const { Amount: amount, OrderNumber: orderNumber, StoreName: storeName } = paymentPayload

      const totalAmount = formatMoney(amount, true)
      const countryCode = defaultCountry
      const currencyCode = 'AED'

      const paymentRequest = {
        countryCode,
        currencyCode,
        total: {
          label: storeName,
          amount: totalAmount,
        },
        supportedNetworks: ['amex', 'masterCard', 'visa'],
        merchantCapabilities: ['supports3DS'],
        shippingMethods: [],
        supportedCountries: [countryCode, 'EE', 'DE'],
      }

      const session = new window.ApplePaySession(3, paymentRequest)
      setIsSessionActive(true)
      await applePayService.LogApiCall('paymentRequest', JSON.stringify(paymentRequest))
      await applePayService.LogApiCall('begin payment', JSON.stringify(paymentPayload))

      session.onvalidatemerchant = async () => {
        try {
          const response = await axios.post(`${paymentPayload.UpdateStatusUrl}/PaymentAPI/Create`, paymentPayload, {
            headers: { 'Content-Type': 'application/json' },
          })

          const validationData = response.data

          if (validationData.success) {
            await applePayService.LogApiCall('Success PaymentAPI/Create', JSON.stringify(validationData))
            session.completeMerchantValidation(validationData.PaymentSession)
          } else {
            session.abort()
            await applePayService.LogApiCall('Error AppleSession', JSON.stringify(validationData))
            setIsSessionActive(false)
          }
        } catch (error) {
          await applePayService.LogApiCall('Error status', JSON.stringify(error))
          session.abort()
          setIsSessionActive(false)
        }
      }

      session.onpaymentauthorized = async (event: ApplePayPaymentAuthorizedEvent) => {
        const paymentData = event.payment.token.paymentData
        const encodedPaymentData = btoa(JSON.stringify(paymentData))

        try {
          const response = await axios.post(
            `${paymentPayload.UpdateStatusUrl}/PaymentProcess/ProcessDigitalPayment`,
            {
              TransRef: orderNumber,
              Token: encodedPaymentData,
              PayType: 'ApplePay',
            },
            {
              headers: { 'Content-Type': 'application/json' },
            }
          )

          const processPaymentResponse = response.data
          await applePayService.LogApiCall('ProcessDigitalPayment-response', JSON.stringify(processPaymentResponse))

          if (
            processPaymentResponse.BankStatus === paymentResponseStatuses.SUCCESS ||
            processPaymentResponse.BankStatus === paymentResponseStatuses.AUTHORIZED
          ) {
            session.completePayment({ status: window.ApplePaySession.STATUS_SUCCESS })
          } else {
            session.completePayment({ status: window.ApplePaySession.STATUS_FAILURE })
          }
          setIsSessionActive(false)
        } catch (error) {
          await applePayService.LogApiCall('Error-status', JSON.stringify(error))

          setIsSessionActive(false)
        }
      }

      session.oncancel = async (payload: PaymentPayload) => {
        await applePayService.LogApiCall('ApplePayment session.oncancel', JSON.stringify(payload))
        if (isSessionActive) {
          session.abort()
          setIsSessionActive(false)
        }
      }

      session.begin()
    } catch (error) {
      await applePayService.LogApiCall('ApplePayment Exception', JSON.stringify(error))
    }
  }

  const getPayload = async () => await applePayService.getPaymentPayload(reservationId)

  return {
    isApplePayAvailable,
    applePay,
    getPayload,
  }
}
