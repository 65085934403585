import {
  getDepartureAndArrivalDataFromReservation,
  getItemsFromReservation,
  getNumOfPriceCategorySubTypesFromItems,
  getPhoneProp,
  getRefundStatusesFromReservation,
  getSailRefIdsFromReservation,
  getVehiclesFromItemsAndReservation,
  getVesselTitleFromReservation,
} from '@/root/shared-helpers'
import { Reservation, TicketData } from '@/root/shared-types/reservation/reservation-types'

export function usePrintedTicketData(staticReservation: Reservation | undefined): {
  reservation: TicketData | undefined
  error: Error | undefined
  isLoading: boolean
} {
  const { reservation, error, isLoading } = {
    reservation: staticReservation,
    error: undefined,
    isLoading: false,
  }

  if (!reservation) {
    return { error, isLoading, reservation }
  }

  const { reservationId, reservationOwner } = reservation

  const {
    departureThere,
    departurePortThere,
    arrivalThere,
    arrivalPortThere,
    departureBack,
    departurePortBack,
    arrivalBack,
    arrivalPortBack,
  } = getDepartureAndArrivalDataFromReservation(reservation)

  const { itemsThere, itemsBack } = getItemsFromReservation(reservation)

  const { vesselTitleBack, vesselTitleThere } = getVesselTitleFromReservation(reservation)

  const { numOfCars: numOfCarsThere, numOfPassengers: numOfPassengersThere } =
    getNumOfPriceCategorySubTypesFromItems(itemsThere)

  const { numOfCars: numOfCarsBack, numOfPassengers: numOfPassengersBack } =
    getNumOfPriceCategorySubTypesFromItems(itemsBack)

  const vehiclesThere = getVehiclesFromItemsAndReservation(reservation, itemsThere)
  const vehiclesBack = getVehiclesFromItemsAndReservation(reservation, itemsBack)

  const { sailRefIdThere, sailRefIdBack } = getSailRefIdsFromReservation(reservation)

  const { isRefundThere, isRefundBack } = getRefundStatusesFromReservation(reservation)
  const { expireAt } = reservation

  const { email = '', fullName = '', lastName = '', firstName = '' } = reservationOwner || {}

  const intlCode = getPhoneProp('intlCode')(reservationOwner)
  const phoneNumber = getPhoneProp('phoneNumber')(reservationOwner)

  const {
    feeAmount = 0,
    feeAmountWithoutTaxes = 0,
    feeTaxAmount = 0,
    amountFormatted = '',
    currency = 'EUR',
    amount = 0,
  } = reservation?.paymentInfo?.totalPrice || {}

  return {
    reservation: {
      there: {
        departure: departureThere,
        departurePort: departurePortThere,
        arrival: arrivalThere,
        arrivalPort: arrivalPortThere,
        items: itemsThere,
        vesselTitle: vesselTitleThere,
        numOfCars: numOfCarsThere,
        numOfPassengers: numOfPassengersThere,
        vehicles: vehiclesThere,
        sailRefId: sailRefIdThere,
        isRefund: isRefundThere,
      },
      back: {
        departure: departureBack,
        departurePort: departurePortBack,
        arrival: arrivalBack,
        arrivalPort: arrivalPortBack,
        items: itemsBack,
        vesselTitle: vesselTitleBack,
        numOfCars: numOfCarsBack,
        numOfPassengers: numOfPassengersBack,
        vehicles: vehiclesBack,
        sailRefId: sailRefIdBack,
        isRefund: isRefundBack,
      },
      customerInfo: {
        email,
        fullName,
        lastName,
        firstName,
        phoneNumber: phoneNumber ? `+${intlCode} ${phoneNumber}` : '',
      },
      reservationId,
      isRoundTrip: reservation.sailPackages?.length === 2,
      status: reservation.status,
      terms: reservation.terms ?? [],
      paymentInfo: reservation.paymentInfo,
      expireAt,
      currency,
      amount,
      totalToPay: amountFormatted,
      feeAmount,
      charges: {
        quantity: 1,
        price: feeAmountWithoutTaxes,
        vat: feeTaxAmount,
        subtotal: feeAmount,
      },
      route: reservation.routes[0],
      token: reservation.token,
    },
    error,
    isLoading,
  }
}
