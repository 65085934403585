import { FieldValues, useForm } from 'react-hook-form'
import { useState } from 'react'
import { useCreateOrEditFamily, useFixRHFAutocomplete, useGetTranslations, useModal } from '@/root/shared-hooks'
import { CreatePassengerProps, Customer, DropdownOption, Group, Member, RouteDropdownOption } from '@/root/shared-types'
import { useDropdown } from '@/root/shared-hooks'
import {
  defaultValuesDefiner,
  fieldValuesContentAndFieldsIdsDefiner,
  getNameFromCustomer,
} from '@/root/components/admaritime/ProfilePopUp/helpers/helpers'
import * as R from 'ramda'

interface useGetAddToGroupPopupProps {
  type: 'addToGroup'
  groups: Group[]
  customer?: Customer
}

interface useGetAddNewGroupPopupProps {
  type: 'addNewGroup' | 'editGroup'
  customers: Customer[]
  members?: Member[]
  group?: Group
  customerIds?: number[]
}

interface useGetAddNewPassengerPopupProps {
  type: 'addNewPassenger' | 'editPassenger'
  groups: Group[]
  customer?: Customer
}

type UseGetProfilePopUpProps =
  | useGetAddNewGroupPopupProps
  | useGetAddToGroupPopupProps
  | useGetAddNewPassengerPopupProps

export const useGetProfilePopUpProps = (props: UseGetProfilePopUpProps) => {
  const { getTranslation } = useGetTranslations()
  const { createPassenger, createFamily } = useCreateOrEditFamily()
  const { onClose } = useModal()
  const { type } = props

  const [isLoading, setIsLoading] = useState(false)

  const [selectedGroup, setSelectedGroup] = useState<RouteDropdownOption>({
    name: { ar: '', en: '' },
    id: '',
  })

  const defaultGroups = type === 'addNewPassenger' || type === 'editPassenger' ? props.customer?.companies || [] : []
  const [selectedGroups, setSelectedGroups] = useState<RouteDropdownOption[]>(
    defaultGroups.map(({ companyId, legalName }) => ({ id: companyId, name: { en: legalName } }))
  )

  const defaultSelectedCustomers = type === 'addNewGroup' || type === 'editGroup' ? props.members || [] : []
  const [selectedCustomer, setSelectedCustomer] = useState<RouteDropdownOption>({
    name: { ar: '', en: '' },
    id: '',
  })

  const [selectedCustomers, setSelectedCustomers] = useState<RouteDropdownOption[]>(
    defaultSelectedCustomers.map(({ customerId, fullName }) => ({
      id: customerId,
      name: { en: fullName },
    }))
  )
  const defaultValuesRest =
    type === 'addNewPassenger' || type === 'editPassenger'
      ? { customer: props.customer }
      : type === 'addNewGroup' || type === 'editGroup'
      ? { members: props.members, groupName: props.group?.legalName }
      : {}

  const defaultValues = defaultValuesDefiner({
    type,
    ...defaultValuesRest,
  })

  const {
    watch,
    control,
    formState: { errors },
    trigger,
    getValues,
  } = useForm<FieldValues>({
    defaultValues,
  })

  useFixRHFAutocomplete({ getValues, trigger, watch })

  let dropdownOptions: DropdownOption[] = []
  let onSelectOption: (option: DropdownOption) => void = () => void 0

  if (type === 'addToGroup' || type === 'addNewPassenger' || type === 'editPassenger') {
    const { groups } = props

    dropdownOptions = groups.map(({ companyId, legalName }) => ({ id: companyId, name: { en: legalName } }))

    onSelectOption = (group: DropdownOption) =>
      setSelectedGroups((prev) => {
        const exists = prev.some((g) => g.id === group.id)
        if (exists) {
          return prev.filter((g) => g.id !== group.id)
        } else {
          return [...prev, group]
        }
      })
  } else if (type === 'addNewGroup' || type === 'editGroup') {
    const { customers } = props

    dropdownOptions = customers.map(({ customerId, fullName, birthday }) => ({
      id: customerId,
      name: { en: `${fullName} (${birthday || ''})` },
    }))

    onSelectOption = (customer: DropdownOption) =>
      setSelectedCustomers((prev) => {
        const exists = prev.some((c) => c.id === customer.id)
        if (exists) {
          return prev.filter((c) => c.id !== customer.id)
        } else {
          return [...prev, customer]
        }
      })
  }

  const returnGroupDropdown = type === 'addToGroup' || type === 'addNewPassenger' || type === 'editPassenger'

  const dropdownProps = useDropdown({
    options: dropdownOptions,
    selectedOption: returnGroupDropdown ? selectedGroup : selectedCustomer,
    setSelectedOption: returnGroupDropdown ? setSelectedGroup : setSelectedCustomer,
    placeHolder: '',
    onSelectOption,
    selectedOptions: returnGroupDropdown ? selectedGroups : selectedCustomers,
  })

  const titles = {
    addToGroup: getTranslation('profileAddToGroupPopUpTitle'),
    addNewGroup: getTranslation('profileAddNewGroupPopUpTitle'),
    editGroup: getTranslation('profileEditGroupPopUpTitle'),
    addNewPassenger: getTranslation('profileAddNewPassengerPopUpAddTitle'),
    editPassenger: getTranslation('profileAddNewPassengerPopUpAddTitle'),
  }
  const isUnchanged = R.equals(defaultValues, getValues())

  const isDropdownOptionsUnchanged =
    type === 'addNewPassenger' || type === 'editPassenger'
      ? R.equals(
          selectedGroups.map(({ id }) => id),
          defaultGroups.map(({ companyId }) => companyId)
        )
      : R.equals(
          selectedCustomers.map(({ id }) => id),
          defaultSelectedCustomers.map(({ customerId }) => customerId)
        )

  const isDisabledOnSaveDefiner = {
    addToGroup: selectedGroups.length === 0,
    addNewGroup: !Object.values(getValues()).every((value) => value),
    addNewPassenger: !Object.values(getValues()).every((value) => value),
    editPassenger: !Object.values(getValues()).every((value) => value) || (isUnchanged && isDropdownOptionsUnchanged),
    editGroup: isUnchanged && isDropdownOptionsUnchanged,
  }

  const onClick = async () => {
    setIsLoading(true)
    if (type === 'addNewGroup' || type === 'editGroup') {
      const customerIds = selectedCustomers.map(({ id }) => id as number)
      await createFamily({
        type: 'FAMILY',
        legalName: getValues('groupName'),
        companyId: props.group?.companyId,
        customerIds,
      })
    }

    if (type === 'addNewPassenger' || type === 'addToGroup' || type === 'editPassenger') {
      const { customer } = props
      const customerInfo =
        type === 'addToGroup'
          ? {
              firstName: getNameFromCustomer(customer).firstName,
              lastName: getNameFromCustomer(customer).lastName,
              birthday: customer?.birthday,
              gender: customer?.gender,
            }
          : getValues()

      const id = props.customer?.customerId ? { id: { customerBoraId: props.customer?.customerId } } : {}
      await createPassenger({
        ...customerInfo,
        companyIds: selectedGroups.map(({ id }) => id),
        ...id,
      } as CreatePassengerProps)
    }
    onClose()
  }

  return {
    fieldsIds: fieldValuesContentAndFieldsIdsDefiner({ type, getTranslation })?.fieldsIds,
    fieldValuesContent: fieldValuesContentAndFieldsIdsDefiner({ type, getTranslation })?.fieldValuesContent,
    control,
    errors,
    trigger,
    getValues,
    disabled: isDisabledOnSaveDefiner[type],
    title: titles[type],
    dropdownProps,
    onClick,
    isLoading,
  }
}
