import { FC } from 'react'
import Button, { ButtonProps } from '@/root/ui/admaritime/Button/Button'

export const AuthButton: FC<ButtonProps> = ({ children }) => (
  <Button
    variant="contained"
    className="relative flex w-full items-center justify-center text-lg font-bold uppercase drop-shadow-lg"
  >
    {children}
  </Button>
)

export default AuthButton
