import { ArrowFullIcon } from '@/root/ui/shared/icons/ArrowFullIcon'
import { LocationPointerIcon } from '@/root/ui/shared/icons/LocationPointerIcon'
import { FC } from 'react'

interface TaxiRouteInfoProps {
  direction: string[]
  duration: string
}

export const TaxiRouteInfo: FC<TaxiRouteInfoProps> = ({ direction, duration }) => (
  <div className="m-6 flex justify-between rounded border-2 border-base-normal-lighter py-5">
    <div className="-ms-[12.5px] flex flex-col justify-center gap-12">
      {direction.map((dir) => (
        <div key={dir} className="flex">
          <span className="flex w-fit flex-col items-center bg-white ">
            <div className="-mt-1">
              <ArrowFullIcon />
            </div>
            <LocationPointerIcon />
          </span>
          <p className="mt-2 text-xl">{dir}</p>
        </div>
      ))}
    </div>
    <span className="-me-[25.3px] flex w-12 flex-col items-center justify-center text-wrap text-center ">
      <div className="bg-white [&>p]:text-error" dangerouslySetInnerHTML={{ __html: duration }} />
      <div className="rotate-180">
        <ArrowFullIcon />
      </div>
    </span>
  </div>
)
