import { FC, HTMLAttributes, ReactNode } from 'react'
import Button from '../Button/Button'
import { useGetTranslations } from '@/root/shared-hooks'

interface CookiesBannerProps extends HTMLAttributes<HTMLElement> {
  textField: ReactNode
  onAgree: () => void
}

const CookiesBanner: FC<CookiesBannerProps> = ({ textField, onAgree }) => {
  const { getTranslation } = useGetTranslations()

  return (
    <section className="fixed inset-x-0 bottom-0 z-50 flex w-full items-center justify-center bg-white p-4 md:static md:h-16">
      <span className="w-full text-[10px] text-adm-gray-400 md:w-auto md:text-xs">{textField}</span>
      <Button
        onClick={onAgree}
        label={getTranslation('cookiesAgreement')}
        className="m-4 h-8 w-28 rounded bg-adm-gray-400 text-xs text-white transition-colors duration-300 hover:bg-neutral"
      />
    </section>
  )
}

export default CookiesBanner
