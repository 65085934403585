import { FC, PropsWithChildren, useState } from 'react'
import { DrawerContext } from './DrawerContext'

const DrawerProvider: FC<PropsWithChildren> = ({ children }) => {
  const [drawerBody, setDrawerBody] = useState<JSX.Element>()
  const [semiOpened, setSemiOpened] = useState(false)
  const onClose = () => {
    setDrawerBody(undefined)
  }

  const onResize = () => {
    setSemiOpened((prevState) => {
      if (prevState) {
        onClose()
      }
      return !prevState
    })
  }

  return (
    <DrawerContext.Provider
      value={{
        onResize,
        onClose,
        semiOpened,
        setSemiOpened,
        setDrawerBody,
        drawerBody,
      }}
    >
      {children}
    </DrawerContext.Provider>
  )
}

export default DrawerProvider
