import { useState, useEffect } from 'react'

const useIsMobile = (breakpoint = 1024): boolean => {
  const [isMobile, setIsMobile] = useState<boolean>(false)

  useEffect(() => {
    setIsMobile(window.innerWidth < breakpoint)
    const handleResize = () => {
      setIsMobile(window.innerWidth < breakpoint)
    }

    handleResize()

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [breakpoint])

  return isMobile
}

export default useIsMobile
