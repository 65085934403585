import { ChangeEvent, FC, useState } from 'react'
import { UaePassIconWhite } from '@/root/ui/shared/icons/UaePassIconWhite'
import { useGetTranslations } from '@/root/shared-hooks'
import { AuthContent, Heading } from '@/root/modules/admaritime/LoginPage'
import { AuthMethod, HeadingType, LoginPageTab } from '@/root/shared-types/ui/ui-types'
import { Button } from '@/root/ui/admaritime'

interface SignTabProps {
  variant: Exclude<LoginPageTab, 'recovery'>
}

export const SignTab: FC<SignTabProps> = ({ variant }) => {
  const { getTranslation } = useGetTranslations()

  type AuthMethodHeadingType = Exclude<HeadingType, 'signup_uae' | 'login_uae' | 'recovery'>

  const [authMethod, setAuthMethod] = useState<AuthMethod>('email')
  const [authMethodHeadingType, setAuthMethodHeadingType] = useState<AuthMethodHeadingType>(
    variant === 'signup' ? 'signup_email' : 'login_email'
  )

  const getHeadingType = (variant: 'signup' | 'login', authMethod: AuthMethod): AuthMethodHeadingType => {
    return `${variant}_${authMethod}` as AuthMethodHeadingType
  }

  const handleAuthMethodChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newAuthMethod = event.target.value as AuthMethod
    setAuthMethod(newAuthMethod)
    setAuthMethodHeadingType(getHeadingType(variant, newAuthMethod))
  }

  const uaePassHeadingType = variant === 'signup' ? 'signup_uae' : 'login_uae'

  return (
    <div className="flex w-full flex-col items-center gap-7 px-4 py-7 xxs:px-7">
      <Heading type={authMethodHeadingType} />

      <AuthContent authMethod={authMethod} onAuthMethodChange={handleAuthMethodChange} />

      <Heading type={uaePassHeadingType} />

      <Button
        className="flex w-full max-w-[340px] items-center justify-center gap-3 rounded-[20px] !px-2 text-sm font-bold xxs:text-base sm:ltr:text-lg"
        variant="black"
      >
        <div className="w-8 shrink-0">
          <UaePassIconWhite />
        </div>
        <p>{getTranslation('signUAEPassTitle')}</p>
      </Button>
    </div>
  )
}
export default SignTab
