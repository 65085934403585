import { DateTime } from 'luxon'
import { dateFormats } from '@/root/shared-consts'

export const dateToFormat = (date: string, format: (typeof dateFormats)[keyof typeof dateFormats], locale = 'en') =>
  DateTime.fromISO(date).setLocale(locale).toFormat(format)

export const dateToFormattedString = (date: Date, format: string, locale = 'en') =>
  DateTime.fromJSDate(date).setLocale(locale).toFormat(format)

export const dateToFormatFromISODate = (date: string, format: string, locale = 'en') =>
  DateTime.fromISO(date)
    .setLocale(locale)
    .toFormat(format)
    .replace(/(\d{1,2}:\d{2} [APM]{2})/, '\n$1')

export const isDateInPast = (timestamp: number): boolean => {
  const currentTime = Date.now()
  return timestamp < currentTime
}

export const formatedDateToAnotherFormat = (date: string, format: (typeof dateFormats)[keyof typeof dateFormats]) =>
  DateTime.fromISO(date).toFormat(format)
