import axios, { AxiosInstance } from 'axios'
import { logger } from '@/logger'

const appInstance: AxiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
})

const cmsInstance: AxiosInstance = axios.create({
  baseURL: process.env.CMS_API_BASE,
})

const applePayInstance: AxiosInstance = axios.create({
  baseURL: process.env.MPAY_API_BASE,
  headers: {
    'Content-Type': 'application/json',
  },
})

const apiInstance: AxiosInstance = axios.create({
  baseURL: process.env.API_BASE,
  headers: {
    'X-Bora-Tenant': process.env.TENANT,
  },
  auth: {
    username: process.env.API_USERNAME as string,
    password: process.env.API_PASSWORD as string,
  },
})

apiInstance.interceptors.request.use(function (config) {
  if (process.env.DEBUG_API_REQUEST && typeof window === 'undefined') {
    logger.info(`F-EDGE API call: ${config.url}`, {
      TENANT: process.env.TENANT,
      method: config.method?.toUpperCase(),
      url: `${config.baseURL}${config.url}`,
      headers: config.headers,
    })
  }

  return config
})

export { appInstance, cmsInstance, apiInstance, applePayInstance }
