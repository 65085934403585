import { FC } from 'react'
import { useGetTranslations } from '@/root/shared-hooks'
import { RefundAmountInfoProps } from '@/root/shared-types'
import { formatToFixedNumber } from '@/root/shared-helpers'
import { TheeDotsLoader } from '@/root/ui/shared'

export const RefundAmountInfo: FC<RefundAmountInfoProps> = ({
  amount,
  currency,
  formattedPercent,
  totalRefundAmount,
  isFetching,
}) => {
  const { getTranslation } = useGetTranslations()

  return (
    <div className="text-neutral flex flex-col gap-2">
      <span className="flex gap-2">
        {getTranslation('amountPaid')} {isFetching ? <TheeDotsLoader /> : formatToFixedNumber({ value: amount })}{' '}
        {currency}
      </span>
      <span className="flex gap-2">
        {getTranslation('percentRefund')} {isFetching ? <TheeDotsLoader /> : formattedPercent}
      </span>
      <span className="flex gap-2">
        {getTranslation('amountRefund')}{' '}
        {isFetching ? <TheeDotsLoader /> : formatToFixedNumber({ value: totalRefundAmount })} {currency}
      </span>
    </div>
  )
}
