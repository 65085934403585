import { FC } from 'react'
import { TableHeader } from '@/root/components/admaritime/ItemsTable/components/TableHeader/TableHeader'
import { TableCell } from '@/root/components/admaritime/ItemsTable/components/TableRow/TableCell'
import { ChargesTableProps } from '@/root/shared-types'
import { InfoIcon } from '@/root/ui/admaritime'
import cn from 'classnames'
import { Tooltip } from '@/root/components/admaritime'
import { useGetTranslations } from '@/root/shared-hooks'
import { useAppConfig } from '@/root/shared-hooks/appConfig/useAppConfig'

export const ChargesTable: FC<ChargesTableProps> = ({
  columns,
  vat,
  subtotal,
  quantity,
  price,
  description,
  isRoundTrip,
}) => {
  const { formatMoney } = useAppConfig()

  const tableContent = [
    description,
    quantity,
    formatMoney(price, true),
    formatMoney(vat, true),
    formatMoney(subtotal, true),
  ]

  const { getTranslation } = useGetTranslations()

  return (
    <div
      className={cn('flex flex-col gap-3 border-base-very-light py-[22px]', {
        'border-b': isRoundTrip,
        'border-y': !isRoundTrip,
      })}
      data-testid="charges-table"
    >
      <TableHeader columns={columns} />
      <div className="flex" data-testid="charges-table-row">
        {tableContent.map((title, index) => (
          <TableCell
            title={String(title)}
            key={`${title} ${index}`}
            icon={
              index === 0 && (
                <Tooltip
                  title={getTranslation('confirmationOnlineChargesModalTitle')}
                  body={getTranslation('confirmationOnlineChargesModalDescription')}
                >
                  <InfoIcon color="secondary" className="cursor-pointer" />
                </Tooltip>
              )
            }
          />
        ))}
      </div>
    </div>
  )
}
