import * as yup from 'yup'
import { FormatMoneyOptions } from 'format-money-js/src/format-money'

export const priceCustomConfigSchema = yup.object<FormatMoneyOptions>({
  decimalPoint: yup.string().required(),
  separator: yup.string().required(),
  decimals: yup.number().required(),
  symbol: yup.string().required(),
  append: yup.boolean().required(),
})
