import { FC } from 'react'
import { ContentContainer } from '@/root/ui/admaritime'
import { PageTitle } from '@/root/components/bluewave/PageTitle/PageTitle'
import { useFooterItems, useGetTranslations, useMenuItems } from '@/root/shared-hooks'
import { SiteMapItem } from '@/root/modules/admaritime/SiteMap/components/SiteMapItem/SiteMapItem'
import { getMenuItemsByLine } from '@/root/modules/admaritime/AppLayout/helpers/getMenuItemsByLine'

export const SiteMap: FC = () => {
  const { getTranslation } = useGetTranslations()
  const { footerItems } = useFooterItems()
  const { menuItems } = useMenuItems()
  const topHeaderMenuItems = getMenuItemsByLine(menuItems, 1)
  const items = [
    ...(footerItems?.upper.filter((fi) => fi.content.some((content) => content.link)) || []),
    ...(topHeaderMenuItems || []),
  ]
  const oddItems = items.filter((_, index) => index % 2 !== 0)
  const evenItems = items.filter((_, index) => index % 2 === 0)

  return (
    <ContentContainer>
      <PageTitle>{getTranslation('sitemapPageTitle')}</PageTitle>
      <div className="grid w-full grid-cols-2 gap-6">
        <div className="flex flex-col gap-3">
          {evenItems.map((item) => (
            <SiteMapItem item={item} key={'id' in item ? item.id : item.title} />
          ))}
        </div>
        <div className="flex flex-col gap-3">
          {oddItems.map((item) => (
            <SiteMapItem item={item} key={'id' in item ? item.id : item.title} />
          ))}
        </div>
      </div>
    </ContentContainer>
  )
}
