import React, { FC } from 'react'

import Image from 'next/image'

export interface Notes {
  id: number
  title: string
  body: string
  icon: string
}

const NotesFooterSection: FC<Notes> = ({ title, body, icon }) => {
  return (
    <div className="flex flex-col gap-5 px-8 py-[70px]">
      <Image src={`/admaritime/icons/${icon}.svg`} alt={icon} width={103} height={102} />
      <h1 className="text-2xl font-bold">{title}</h1>
      <p className="text-justify text-lg font-normal">{body}</p>
    </div>
  )
}

export default NotesFooterSection
