import { useState, useEffect } from 'react'

interface UseCookieConsentReturnType {
  consent: boolean
  setConsent: () => void
  isInitialized: boolean
}

const useCookieConsent = (): UseCookieConsentReturnType => {
  const [consent, setConsentState] = useState(false)
  const [isInitialized, setIsInitialized] = useState(false)

  useEffect(() => {
    const isAgreed = localStorage?.getItem('cookieConsent')
    if (isAgreed === 'true') {
      setConsentState(true)
    }
    setIsInitialized(true)
  }, [])

  const setConsent = () => {
    localStorage?.setItem('cookieConsent', 'true')
    setConsentState(true)
  }

  return { consent, setConsent, isInitialized }
}

export default useCookieConsent
