import { FC, useState, useEffect } from 'react'
import { Banner, Button, InputField } from '@/root/ui/admaritime'
import { useAuth, useGetTranslations } from '@/root/shared-hooks'
import { isValidEmailOrPhoneNumber, validatePassword } from '@/root/shared-helpers'
import { useCustomRouter } from '@/root/shared-hooks'
import { useForm, FieldValues } from 'react-hook-form'
import * as R from 'ramda'

export const EmailForm: FC = () => {
  const { getTranslation } = useGetTranslations()
  const { logIn } = useAuth()

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<FieldValues>({
    defaultValues: {
      login: '',
      password: '',
    },
    mode: 'all',
  })

  const loginValue = watch('login')
  const passwordValue = watch('password')
  const { locale } = useCustomRouter()

  useEffect(() => {
    reset({
      password: '',
    })
  }, [locale, reset])

  const [isSubmitted, setIsSubmitted] = useState(false)
  const isResponseFromBESuccess = false //todo: Must be changed !!!

  const bannerBody = isResponseFromBESuccess ? 'Success' : getTranslation('userNotExist')
  const bannerVariant = isResponseFromBESuccess ? 'success' : 'error'

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (isSubmitted) {
      timer = setTimeout(() => {
        setIsSubmitted(false)
      }, 10000)
    }
    return () => clearTimeout(timer)
  }, [isSubmitted])

  const onSubmit = (data: FieldValues) => {
    R.identity(data)
    setIsSubmitted(true)
  }

  const isDisabled = R.any(Boolean, [isSubmitted, errors.login, !loginValue, errors.password, !passwordValue])

  return (
    <>
      {isSubmitted && <Banner variant={bannerVariant} body={bannerBody} bodyClassName="!text-base" />}

      <form className="flex w-full flex-col" onSubmit={handleSubmit(onSubmit)}>
        <InputField
          type="text"
          placeholder={getTranslation('signFieldMailPlaceholder')}
          register={register}
          name="login"
          value={loginValue}
          validate={(value) => isValidEmailOrPhoneNumber(value) || getTranslation('invalidFormatText')}
          errors={errors}
        />

        <InputField
          type="password"
          placeholder={getTranslation('signFieldPasswordPlaceholder')}
          register={register}
          name="password"
          value={passwordValue}
          validate={(value) => validatePassword(value) || getTranslation('invalidPasswordText')}
          errors={errors}
        />

        <Button
          className="mt-9 flex w-full justify-center text-lg font-bold uppercase drop-shadow-lg"
          disabled={isDisabled}
          variant="contained"
          type="submit"
          onClick={() => logIn({ method: 'emailOrPhone', body: { email: loginValue, password: passwordValue } })}
        >
          {getTranslation('signInEnter')}
        </Button>
      </form>
    </>
  )
}

export default EmailForm
