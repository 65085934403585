import useSWR from 'swr/immutable'
import axios, { AxiosError } from 'axios'
import { SailContent } from '@/root/shared-types'
import { SalesApiError } from '@/root/shared-types/api/ApiError'

type UseGetSailsParams = { sailPackage: string; date: string }

const fetchSails = async ({ sailPackage, date }: UseGetSailsParams): Promise<SailContent[]> => {
  if (!sailPackage || !date) {
    throw new SalesApiError('Sail package ID and date are required', 'INVALID_REQUEST')
  }

  try {
    const { data } = await axios.get<SailContent[]>(`/api/sails/${sailPackage}/${date}`)
    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      throw new SalesApiError(error.message, error.response?.status?.toString())
    }
    throw new SalesApiError('Unknown error occurred')
  }
}

export const useGetSails = ({ sailPackage, date }: UseGetSailsParams) => {
  const {
    data: sailContent,
    error,
    isValidating,
  } = useSWR<SailContent[], AxiosError>(`get-sails ${sailPackage} ${date}`, () => fetchSails({ sailPackage, date }))

  return {
    sailContent,
    error: error && new SalesApiError(error.message, error.response?.status?.toString()),
    isLoading: isValidating,
  }
}
