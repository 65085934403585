import { ContentContainer } from '@/root/components/shared'
import { FC } from 'react'
import LinksAndInfoItem from '../LinksAndInfoItem/LinksAndInfoItem'
import { LowerFooterItem, UpperFooterItem } from '@/root/shared-types'

interface FooterLinksAndInfoProps {
  upperFooterItems: UpperFooterItem[]
  lowerFooterItems: LowerFooterItem[]
}

const FooterLinksAndInfo: FC<FooterLinksAndInfoProps> = ({ lowerFooterItems, upperFooterItems }) => {
  return (
    <div className="bg-primary-base relative overflow-hidden">
      <ContentContainer>
        <div className="flex flex-col gap-8 px-6 py-9">
          <div className="grid gap-7 xs:grid-cols-2 md:grid-cols-4">
            {upperFooterItems.map(({ title, content }) => (
              <LinksAndInfoItem key={title} title={title} items={content} useOldBoraOrigin />
            ))}
          </div>
          <div className="grid gap-7 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {lowerFooterItems.map(({ icon, content }) => {
              return (
                <div className="xs:max-w-sm sm:max-w-72" key={icon}>
                  <LinksAndInfoItem icon={icon} items={content} />
                </div>
              )
            })}
          </div>
        </div>
      </ContentContainer>

      <div className="absolute bottom-[-400px] right-[-400px] size-[800px] rounded-full border border-secondary" />
      <div className="absolute bottom-[-250px] right-[-250px] size-[500px] rounded-full border border-secondary" />
    </div>
  )
}

export default FooterLinksAndInfo
