import { FC, PropsWithChildren } from 'react'

const Body: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="min-h-screen w-full flex-col justify-center space-y-5 bg-base p-4 text-center text-white">
      {children}
    </div>
  )
}

export default Body
