import { FC } from 'react'
import { CarIcon } from '@/root/ui/admaritime'
import { PropertyItem } from '@/root/modules/admaritime/BookTicket/components/VehicleInfo/Components/PropertyItem'
import { Vehicle } from '@/root/shared-types'
import { useGetVehicleInfo } from '@/root/modules/admaritime/BookTicket/hooks/useGetVehicleInfo'
import { useGetTranslations } from '@/root/shared-hooks'

interface VehicleInfoProps {
  vehicle: Vehicle
}

export const VehicleInfo: FC<VehicleInfoProps> = ({ vehicle }) => {
  const { licencePlate, length, weight, height, width } = useGetVehicleInfo(vehicle)
  const { getTranslation } = useGetTranslations()

  return (
    <div className="mb-5 flex flex-col gap-6 border-t border-base-very-light pt-5 md:border-s md:border-t-0 md:ps-5 md:pt-0">
      <span className="flex items-start gap-5">
        <CarIcon color="base" width={50} className="mb-1" />
        <h1 className="text-2xl font-bold text-primary-base">
          {getTranslation('confirmationVehicleInformationTitle')}
        </h1>
      </span>
      <div className="mb-10 flex flex-col gap-5">
        <PropertyItem title={getTranslation('confirmationCarRegNr')} value={licencePlate} />
        <PropertyItem title={getTranslation('confirmationCarLength')} value={length} />
        <PropertyItem title={getTranslation('confirmationCarHeight')} value={height} />
        <PropertyItem title={getTranslation('confirmationCarWidth')} value={width} />
        <PropertyItem title={getTranslation('confirmationCarWeight')} value={weight} />
      </div>
    </div>
  )
}
