import axios from 'axios'
import { useCustomRouter, useGetAuthToken, useSetAuthToken } from '@/root/shared-hooks'
import { decodeJWT, isDateInPast } from '@/root/shared-helpers'

type Body = {
  email: string
  password: string
}

interface LogInWithEmailOrPhone {
  method: 'emailOrPhone'
  body: Body
}

export const useAuth = () => {
  const { saveToken, resetToken } = useSetAuthToken()
  const { token } = useGetAuthToken()
  const { push } = useCustomRouter()

  const logIn = async ({ method, body }: LogInWithEmailOrPhone) => {
    if (token) {
      const { expiration } = decodeJWT(token)
      const isExpired = isDateInPast(expiration * 1000)
      if (isExpired) {
        resetToken()
      }
    }
    if (method === 'emailOrPhone') {
      const { data, status } = await axios({
        method: 'POST',
        url: `/api/auth/emailOrPhoneNumber`,
        data: body,
      })
      saveToken(data.token)
      if (status === 200) {
        await push('/my/passengersData')
      }
    }
  }

  const logOut = () => {
    resetToken()
    push('/')
  }

  return { logIn, logOut }
}
