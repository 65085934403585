import cn from 'classnames'
import Image from 'next/image'
import { FC } from 'react'
import ImageColorByFilter from '../admaritime/Icons/Icon/ImageColorByFilter/ImageColorByFilter'
import { IconProps } from '@/root/shared-types/ui/ui-types'
import { useConfig } from '@/root/shared-hooks'

interface IconGenericProps extends IconProps {
  type: string
  alt: string
}

const Icon: FC<IconGenericProps> = ({ type, alt, width, height = 0, className, ignoreRtl, color, ...rest }) => {
  const { theme } = useConfig()

  const themePath = `/${theme}/icons/${type}.svg`

  return (
    <div className="flex items-center justify-center">
      <Image
        className={cn(
          {
            'rtl:[transform:rotateY(-180deg)]': !ignoreRtl,
          },
          className
        )}
        alt={alt}
        src={themePath}
        width={width}
        height={height}
        style={{
          filter: color ? `url(#colorFilter-${color})` : 'none',
        }}
        {...rest}
      />
      {color && <ImageColorByFilter color={color} />}
    </div>
  )
}

export default Icon
