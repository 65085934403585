import React, { FC } from 'react'
import { ImageProps } from '@/root/shared-types'
import Image from 'next/image'
import { useCustomRouter } from '@/root/shared-hooks'

interface RoutePictureProps {
  image: ImageProps
}

export const RoutePicture: FC<RoutePictureProps> = ({ image }) => {
  const { locale } = useCustomRouter()

  return (
    <div className={`basis-1/2 ${locale === 'ar' ? '-scale-x-100' : ''}`}>
      <Image src={`/api${image.url}`} alt={image.alternativeText} width={164} height={150} />
    </div>
  )
}
