import { FC, PropsWithChildren } from 'react'
import ConfigProvider from '../ConfigProvider/ConfigProvider'
import I18nProvider from '../I18nProvider/I18nProvider'
import { BoraTheme } from '@/root/shared-types'
import AppConfigProvider from '@/root/shared-providers/AppConfigProvider/AppConfigProvider'
import ErrorBannerProvider from '@/root/shared-providers/ErrorBannerProvider/ErrorBannerProvider'
import { DrawerProvider, SailPackageProvider } from '@/root/shared-providers'
import { AppConfig } from '@/root/shared-types/app-config/app-config'
import ModalProvider from '@/root/shared-providers/ModalProvider/ModalProvider'
import AuthContextProvider from '../AuthContextProvider/AuthContextProvider'

interface Props extends PropsWithChildren {
  theme: BoraTheme
  appConfig: AppConfig
}

const Providers: FC<Props> = ({ children, theme, appConfig }) => {
  return (
    <AppConfigProvider initialConfig={appConfig}>
      <ConfigProvider theme={theme}>
        <I18nProvider>
          <ErrorBannerProvider>
            <ModalProvider>
              <DrawerProvider>
                <AuthContextProvider>
                  <SailPackageProvider>{children}</SailPackageProvider>
                </AuthContextProvider>
              </DrawerProvider>
            </ModalProvider>
          </ErrorBannerProvider>
        </I18nProvider>
      </ConfigProvider>
    </AppConfigProvider>
  )
}

export default Providers
