import { FC } from 'react'
import { Schedule, ScheduleType } from '@/root/shared-types/sales-static/sales-static-types'
import { useGetTableHeader } from '@/root/modules/admaritime/SchedulePage/hooks/useGetTableHeader'
import { TaxiTable } from '@/root/modules/admaritime/SchedulePage/components/TaxiTable/TaxiTable'
import { FerryTable } from '@/root/modules/admaritime/SchedulePage/components/FerryTable/FerryTable'

interface ScheduleTableProps extends Schedule {
  scheduleType: ScheduleType
}

export const ScheduleTable: FC<ScheduleTableProps> = ({ direction, options, scheduleType }) => {
  const cols = useGetTableHeader(scheduleType)

  switch (scheduleType) {
    case 'ferry':
      return <FerryTable cols={cols} options={options} direction={direction} />
    case 'taxi':
      return <TaxiTable cols={cols} options={options} direction={direction} />
  }
}
