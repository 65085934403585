import { FC } from 'react'

interface PropertyItemProps {
  title: string
  value: string | number
}

export const PropertyItem: FC<PropertyItemProps> = ({ title, value }) => (
  <span className="flex text-base">
    <p className="w-36 text-base-normal-lighter">{title}</p>
    <p className="font-bold text-base">{value}</p>
  </span>
)
