import { Locale, Reservation, ReservationItem, SailPackage, Terms } from '@/root/shared-types'
import { DateTime } from 'luxon'
import { ReservationOwner } from '@/root/shared-types/reservation/reservation-types'
import * as R from 'ramda'

export const getTermsByLocale = (terms: Terms[] = [], locale: Locale): Terms | undefined =>
  terms.find(({ language }) => language === locale) || terms[0]
export const getVesselTitleFromReservation = (reservation: Reservation) => {
  const { sailPackages: [sailPackageThere, sailPackageBack] = [] } = reservation
  const {
    sailRefs: [{ vesselTitle: vesselTitleThere }],
  } = sailPackageThere
  const { sailRefs: [{ vesselTitle: vesselTitleBack = '' }] = [{}] } = sailPackageBack || {}
  return {
    vesselTitleThere,
    vesselTitleBack,
  }
}

export const getItemsWithoutFee = (items: ReservationItem[]) => items.filter(({ type }) => type !== 'FEE')

export const getItemsWithFee = (items: ReservationItem[]) => items.filter(({ type }) => type === 'FEE')

export const getItemsFromReservation = (reservation: Reservation) => {
  const { sailPackages: [sailPackageThere, sailPackageBack] = [], items = [] } = reservation
  const { seqN: seqNThere } = sailPackageThere
  const { seqN: seqNBack } = sailPackageBack || {}

  const itemsWithoutFee = getItemsWithoutFee(items)
  const itemsWithFee = getItemsWithFee(items)

  const itemsThere = itemsWithoutFee.filter(({ sailPackageSeqN }) => sailPackageSeqN === seqNThere)

  const itemsBack = itemsWithoutFee.filter(({ sailPackageSeqN }) => sailPackageSeqN === seqNBack)

  return {
    itemsThere,
    itemsBack,
    itemsWithFee,
  }
}

export const getNumOfPriceCategorySubTypesFromItems = (items: ReservationItem[] = []) =>
  items.reduce(
    (acc, { priceCategorySubType, quantity }) => {
      if (priceCategorySubType === 'DECK') {
        acc.numOfPassengers += quantity
      }

      if (priceCategorySubType === 'VEHICLE') {
        acc.numOfCars += quantity
      }

      return acc
    },
    { numOfPassengers: 0, numOfCars: 0 }
  )

export const getVehiclesFromItemsAndReservation = (reservation: Reservation, items: ReservationItem[]) => {
  const { vehicles } = reservation

  return (
    vehicles &&
    vehicles.filter(({ seqN }) => {
      return items.some(({ ownerSeqNs }) => ownerSeqNs.includes(seqN))
    })
  )
}

export const getSailRefIdsFromReservation = (reservation: Reservation) => {
  const { sailPackages: [sailPackageThere, sailPackageBack] = [] } = reservation
  const {
    sailRefs: [{ sailRefId: sailRefIdThere }],
  } = sailPackageThere
  const { sailRefs: [{ sailRefId: sailRefIdBack = 0 }] = [{}] } = sailPackageBack || {}
  return {
    sailRefIdThere,
    sailRefIdBack,
  }
}

export const getDepartureAndArrivalFromSailPackage = (sailPackage?: SailPackage) => {
  const [{ arrivalAt }] = sailPackage?.sailRefs?.slice(0, 1) || [{}]
  const [{ departureAt }] = sailPackage?.sailRefs?.slice(-1) || [{}]

  return {
    arrivalAt,
    departureAt,
  }
}

export const getPortsFromSailPackage = (sailPackage?: SailPackage) => {
  const { title = '' } = sailPackage || {}
  const [departurePort, arrivalPort] = title.split('-').map((port) => port.trim())

  return {
    departurePort,
    arrivalPort,
  }
}

export const getDepartureAndArrivalDataFromReservation = (reservation: Reservation) => {
  const { sailPackages: [sailPackageThere, sailPackageBack] = [] } = reservation

  const { arrivalAt: arrivalThere, departureAt: departureThere } =
    getDepartureAndArrivalFromSailPackage(sailPackageThere)

  const { arrivalAt: arrivalBack, departureAt: departureBack } = getDepartureAndArrivalFromSailPackage(sailPackageBack)

  const { arrivalPort: arrivalPortThere, departurePort: departurePortThere } = getPortsFromSailPackage(sailPackageThere)

  const { arrivalPort: arrivalPortBack, departurePort: departurePortBack } = getPortsFromSailPackage(sailPackageBack)

  return {
    departureThere,
    departurePortThere,
    arrivalThere,
    arrivalPortThere,
    departureBack,
    departurePortBack,
    arrivalBack,
    arrivalPortBack,
  }
}

export const getRefundStatusesFromReservation = (reservation: Reservation) => {
  const { sailPackages: [sailPackageThere, sailPackageBack] = [] } = reservation

  return {
    isRefundThere: Boolean(sailPackageThere?.refundOfferId),
    isRefundBack: Boolean(sailPackageBack?.refundOfferId),
  }
}

interface getExpireAtDateFromISOStringProps {
  isoDate: Date | string
  locale: Locale
  defaultTimezone: string
}

export const getExpireAtDateFromISOString = ({ isoDate, locale, defaultTimezone }: getExpireAtDateFromISOStringProps) =>
  DateTime.fromISO(new Date(isoDate + 'Z').toISOString(), { zone: defaultTimezone })
    .setLocale(locale)
    .toFormat('dd LLL yyyy HH:mm')

export const getPhoneProp = (prop: string) => R.compose(R.propOr('', prop), R.head, R.propOr([], 'phones'))

export const getCustomerInfoFromReservationOwner = (reservationOwner?: ReservationOwner) => {
  if (reservationOwner) {
    const { email = '', fullName = '', lastName = '', firstName = '' } = reservationOwner
    const intlCode = getPhoneProp('intlCode')(reservationOwner)
    const phoneNumber = getPhoneProp('phoneNumber')(reservationOwner)
    return { email, fullName, firstName, lastName, phoneNumber: phoneNumber ? `+${intlCode} ${phoneNumber}` : '' }
  }
}
