import { FC } from 'react'
import PrintedTicketPart from '@/root/modules/admaritime/PrintedTicket/components/PrintedTicketPart/PrintedTicketPart'

import { TicketProps } from '@/root/shared-types/reservation/reservation-types'
import { Divider } from '@/root/ui/shared/Divider/Divider'

const PrintedTicket: FC<TicketProps> = ({ ticketData }) => {
  const { customerInfo, reservationId, there, back, isRoundTrip } = ticketData

  const dividerVariant = there.isRefund || back.isRefund ? 'cancelled' : 'default'

  return (
    <div>
      <PrintedTicketPart
        reservationId={reservationId}
        cancelled={there.isRefund}
        {...there}
        customerInfo={customerInfo}
        isRoundTrip={isRoundTrip}
      />

      {isRoundTrip && (
        <div className="relative">
          <Divider variant={dividerVariant} />
          <PrintedTicketPart
            reservationId={reservationId}
            cancelled={back.isRefund}
            {...back}
            isRoundTrip={isRoundTrip}
          />
        </div>
      )}
    </div>
  )
}

export default PrintedTicket
