import { FC, ReactNode } from 'react'

interface CookiesTextFieldProps {
  cookiesMessage: string
  privacyPolicyLink: ReactNode
}

const CookiesTextField: FC<CookiesTextFieldProps> = ({ cookiesMessage, privacyPolicyLink }) => {
  return (
    <div className="flex flex-col md:flex-row md:items-center md:gap-1">
      <p>{cookiesMessage}</p>
      <p>{privacyPolicyLink}</p>
    </div>
  )
}

export default CookiesTextField
