import { FC, HTMLAttributes, ReactNode } from 'react'
import { Severity } from '@/root/shared-types/ui/ui-types'
import cn from 'classnames'

interface BannerProps extends HTMLAttributes<HTMLDivElement> {
  variant: Severity
  title?: string
  body?: ReactNode
  bodyClassName?: string
}

const Banner: FC<BannerProps> = ({ body, title, variant, children, className, bodyClassName }) => {
  return (
    <article
      className={cn(
        'flex flex-col rounded-md border text-center text-2xl font-bold',
        {
          'border-success-dark bg-success-very-light px-6 py-4 text-success-dark': variant === 'success',
        },
        {
          'border-warning-dark bg-warning-lightest px-6 py-5 text-warning-dark': variant === 'info',
        },
        {
          'gap-1.5 border-error-dark bg-adm-pink-100 px-6 py-3': variant === 'warning',
        },
        {
          'border-error bg-error-lightest p-2.5 text-error': variant === 'error',
        },
        { 'border-none px-6 py-4 text-success-dark': variant === 'neutral' },
        className
      )}
    >
      {Boolean(title) && <h2 className={cn('mb-1', { 'text-lg text-alert-dark': variant == 'warning' })}>{title}</h2>}

      {Boolean(body) && (
        <p
          className={cn(
            { 'text-lg': variant == 'success' },
            { 'text-lg': variant == 'info' },
            { 'mb-2.5 text-base font-normal': variant === 'warning' },
            { 'text-base': variant === 'error' },
            { 'text-lg': variant === 'neutral' },
            bodyClassName
          )}
        >
          {body}
        </p>
      )}
      {children}
    </article>
  )
}

export default Banner
