import { FC, useState } from 'react'
import Header from '../Header/Header'
import Body from '../Body/Body'
import { Section } from '../Section/Section'
import { globalFont } from '@/root/modules/admaritime/AppLayout/assets/globalFonts/globalFonts'
import { LinkButton } from '@/root/modules/admaritime/UserManual/components/LinkButton/LinkButton'
import { ManualType } from '@/root/shared-types'
import { useGetUserManualData } from '@/root/modules/admaritime/UserManual/hooks/getUserManualData'
import { useGetTranslations } from '@/root/shared-hooks'

interface UserManualProps {
  onClose?(): void
  isInDrawer?: boolean
}

export const UserManual: FC<UserManualProps> = ({ onClose, isInDrawer = true }) => {
  const [manualType, setManualType] = useState<ManualType | null>(null)
  const sections = useGetUserManualData(manualType)
  const displaySelectManual = Boolean(!sections)
  const { getTranslation } = useGetTranslations()

  const onGoBack = () => {
    if (manualType) {
      return setManualType(null)
    }
    if (isInDrawer) {
      history.back()
    }
  }

  return (
    <div className={globalFont.className}>
      <Header
        onGoBack={onGoBack}
        displayGoBackButton={!isInDrawer || (isInDrawer && Boolean(manualType))}
        onClose={onClose}
      />
      <Body>
        {displaySelectManual && (
          <div className="flex flex-col gap-8">
            <LinkButton
              title={getTranslation('userManualTicketPurchase')}
              onClick={() => setManualType('ticket-purchase')}
            />
            <LinkButton title={getTranslation('userManualRegistrationLogin')} onClick={() => setManualType('login')} />
          </div>
        )}
        {sections?.map((section) => (
          <Section title={section.title} instructions={section.instructions} key={section.title} />
        ))}
      </Body>
    </div>
  )
}
