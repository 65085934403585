import { salesStaticService } from '@/root/shared-api'
import useSWR from 'swr'
import { useCustomRouter } from '@/root/shared-hooks'

export function useMenuItems() {
  const { locale } = useCustomRouter()
  const { data, error } = useSWR(`/menu-items/${locale}`, () => salesStaticService.getMenuItems(locale as string))
  return {
    menuItems: data,
    error,
  }
}
