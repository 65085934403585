import { FC, HTMLAttributes } from 'react'
import cn from 'classnames'
import React from 'react'
import { ReturnSailSepIcon } from '../icons/ReturnSailSepIcon'
import { useConfig } from '@/root/shared-hooks'

interface DividerProps extends HTMLAttributes<HTMLDivElement> {
  variant: 'default' | 'cancelled' | 'light'
}

const colorMapDefault: Record<DividerProps['variant'], string> = {
  default: 'black',
  light: 'base-very-light',
  cancelled: 'alert-base',
}

const colorMapBluewave: Record<DividerProps['variant'], string> = {
  default: 'base-normal-light',
  light: 'neutral-normal-lighter',
  cancelled: 'alert-base',
}

export const Divider: FC<DividerProps> = ({ variant }) => {
  const { theme } = useConfig()

  const isBluewave = theme === 'bluewave'

  const borderColor = {
    default: isBluewave ? 'border-t border-base-normal-light' : 'border-black',
    light: isBluewave ? 'h-[0.5px] flex-grow border-t-[0.5px] border-neutral-normal-lighter' : 'border-base-very-light',
    cancelled: 'border-t-4 border-alert-base',
  }

  const colorMap = isBluewave ? colorMapBluewave : colorMapDefault

  const hrClassName = cn('flex-1', borderColor[variant])

  return (
    <div className="absolute left-0 top-0 z-10 flex w-full -translate-y-1/2 items-center">
      <hr className={hrClassName} />
      <div className="px-2.5">
        <ReturnSailSepIcon width={48} color={colorMap[variant]} />
      </div>
      <hr className={hrClassName} />
    </div>
  )
}
