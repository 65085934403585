import { FC, PropsWithChildren, useState } from 'react'
import { ModalContext } from './ModalContext'

const ModalProvider: FC<PropsWithChildren> = ({ children }) => {
  const [modalBody, setModalBody] = useState<JSX.Element>()
  const onClose = () => {
    setModalBody(undefined)
  }

  return (
    <ModalContext.Provider
      value={{
        onClose,
        setModalBody,
        modalBody,
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}

export default ModalProvider
