import { createContext } from 'react'

export type AuthContextType = {
  token: string | null
  isAuth: boolean
}

export const AuthContext = createContext<AuthContextType>({} as AuthContextType)

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const AuthContextTokenSetter = createContext<(token: string | null) => void>(() => {})
