import { FC, ChangeEvent, HTMLAttributes } from 'react'

interface AuthMethodSelectorInputProps extends HTMLAttributes<HTMLInputElement> {
  value: string
  checked: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

export const AuthMethodSelectorInput: FC<AuthMethodSelectorInputProps> = ({ value, checked, onChange, ...props }) => (
  <input
    className="pointer-events-none absolute size-px appearance-none opacity-0"
    type="radio"
    value={value}
    checked={checked}
    onChange={onChange}
    {...props}
  />
)

export default AuthMethodSelectorInput
