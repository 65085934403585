import { FC, useState } from 'react'
import MobileMenu from '../MobileMenu/MobileMenu'
import TopHeaderMenu from '../TopHeaderMenu/TopHeaderMenu'
import MainHeaderNavBar from '../MainHeaderNavBar/MainHeaderNavBar'
import { useMenuItems, useStaticContent } from '@/root/shared-hooks'
import { getMenuItemsByLine } from '../../helpers/getMenuItemsByLine'

const Header: FC = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const { staticMenuItems } = useStaticContent()
  const { menuItems } = useMenuItems()

  const topHeaderMenuItems = getMenuItemsByLine(menuItems || staticMenuItems, 1)
  const mainHeaderMenuItems = getMenuItemsByLine(menuItems || staticMenuItems, 2)

  return (
    <header>
      <MobileMenu
        menuItemsLine1={topHeaderMenuItems}
        menuItemsLine2={mainHeaderMenuItems}
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
      />
      <TopHeaderMenu menuItems={topHeaderMenuItems} />
      <MainHeaderNavBar
        menuItems={mainHeaderMenuItems}
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
      />
    </header>
  )
}

export default Header
