import useSWR from 'swr/immutable'
import axios, { AxiosError } from 'axios'
import { SailPackage } from '@/root/shared-types'
import { SalesApiError } from '@/root/shared-types/api/ApiError'

type LocaleAndCodeParams = { locale: string; code?: string }

const fetchSailPackages = async (locale: string): Promise<SailPackage[]> => {
  try {
    const { data: packages } = await axios.get<SailPackage[]>('/api/get-packages/sailPackages', {
      params: { locale },
    })
    return packages
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      throw new SalesApiError(error.message, error.response?.status?.toString())
    }
    throw new SalesApiError('Unknown error occurred')
  }
}

export const useAllAndSelectedPackage = ({ locale, code }: LocaleAndCodeParams) => {
  const {
    data: packages,
    error,
    isValidating,
  } = useSWR<SailPackage[], AxiosError>(locale ? `get-packages-${locale}` : null, () => fetchSailPackages(locale))

  const sailPackage = packages?.find((pkg) => pkg.code === code)

  return {
    packagesData: { allPackages: packages, packageById: sailPackage },
    error: error ? new SalesApiError(error.message) : undefined,
    isLoading: isValidating,
  }
}
