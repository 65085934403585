import { FC } from 'react'
import { ReservationItem } from '@/root/shared-types'
import { TableCell } from '@/root/components/admaritime/ItemsTable/components/TableRow/TableCell'
import { RowContainer } from '@/root/components/admaritime/ItemsTable/components/RowContainer/RowContainer'

interface TableRowProps {
  item: ReservationItem
  formatMoney: (price: number, hideCurrency?: boolean) => string
  className?: string
}

export const TableRow: FC<TableRowProps> = ({
  item: { priceCategoryTranslation, quantity, price, taxAmount, pricePerUnitWithoutTax },
  className,
  formatMoney,
}) => {
  const rowContent = [
    priceCategoryTranslation,
    quantity,
    formatMoney(pricePerUnitWithoutTax, true),
    formatMoney(taxAmount, true),
    formatMoney(price, true),
  ]

  return (
    <RowContainer className={className}>
      {rowContent.map((value, index) => (
        <TableCell title={String(value)} key={`${value} ${index}`} />
      ))}
    </RowContainer>
  )
}
