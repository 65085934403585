import { FC } from 'react'
import { Checkbox } from '@/root/ui/admaritime'
import { useGetTranslations } from '@/root/shared-hooks'
import { RefundRouteInfoProps } from '@/root/shared-types'
import { dateToFormatFromISODate } from '@/root/shared-helpers'
import { dateFormats } from '@/root/shared-consts'
import cn from 'classnames'
import { RouteInfo } from '@/root/components/admaritime'

const RefundRouteInfo: FC<RefundRouteInfoProps> = ({
  arrivalPort,
  departurePort,
  isRoundTrip,
  checked,
  disabled,
  className,
  onChange,
  departure,
}) => {
  const { getTranslation } = useGetTranslations()
  const departureTime = departure ? dateToFormatFromISODate(departure, dateFormats.DETAILED_DATE) : null

  return (
    <div className={cn('flex flex-col gap-3 text-large font-bold text-base', className)}>
      <RouteInfo routeInfo={{ portFrom: departurePort, portTo: arrivalPort }} />
      {departureTime && <p className="text-xl">{departureTime}</p>}
      {isRoundTrip && (
        <Checkbox
          label={getTranslation('cancelRouteRefund')}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          variant="error"
          className="items-center gap-6 text-base font-normal"
          colorLabel
        />
      )}
    </div>
  )
}

export default RefundRouteInfo
