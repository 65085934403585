import React, { FC, RefObject, useState, useEffect, useRef, ChangeEvent, HTMLAttributes } from 'react'
import { DropdownOption } from '@/root/shared-types'
import cn from 'classnames'
import { useCustomRouter } from '@/root/shared-hooks'
import { ChevronDownIcon } from '@/root/ui/shared/icons/ChevronDownIcon'
import { GroupBadge } from '@/root/ui/admaritime'

interface RouteDropdownProps extends HTMLAttributes<HTMLDivElement> {
  isOpen: boolean
  options: DropdownOption[]
  handleToggleDropdown: () => void
  handleOptionSelection: (id: DropdownOption) => void
  dropdownRef: RefObject<HTMLDivElement>
  placeHolder: string
  testId?: string
  selectedOption?: DropdownOption
  selectedOptions?: DropdownOption[]
  disableFilter?: boolean
  displaySelectedOptions?: boolean
}

const Dropdown: FC<RouteDropdownProps> = ({
  isOpen,
  selectedOption,
  options,
  handleToggleDropdown,
  handleOptionSelection,
  dropdownRef,
  placeHolder,
  testId,
  selectedOptions,
  className,
  disableFilter = false,
  displaySelectedOptions = true,
}) => {
  const { locale } = useCustomRouter()
  const [inputValue, setInputValue] = useState<string>('')
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1)
  const optionRefs = useRef<(HTMLParagraphElement | null)[]>([])
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value)
    autoResizeTextarea(e.target)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (!isOpen) return

    if (e.key === 'ArrowDown') {
      e.preventDefault()
      setHighlightedIndex((prevIndex) => (prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : 0))
    } else if (e.key === 'ArrowUp') {
      e.preventDefault()
      setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : filteredOptions.length - 1))
    } else if (e.key === 'Enter') {
      e.preventDefault()
      if (highlightedIndex >= 0) {
        handleOptionSelection(filteredOptions[highlightedIndex])
      }
    } else if (e.key === 'Escape') {
      e.preventDefault()
      handleToggleDropdown()
    }
  }

  useEffect(() => {
    if (highlightedIndex >= 0 && optionRefs.current[highlightedIndex]) {
      optionRefs.current[highlightedIndex]?.scrollIntoView({
        block: 'nearest',
      })
    }
  }, [highlightedIndex])

  const filteredOptions = options.filter((option) =>
    (option.name[locale] ?? option.name['en']).toLowerCase().includes(inputValue.toLowerCase())
  )

  useEffect(() => {
    if (!isOpen) {
      setInputValue(selectedOption?.name[locale] || '')
      setHighlightedIndex(-1)
    }
  }, [locale, isOpen, selectedOption])

  useEffect(() => {
    if (textareaRef.current) {
      autoResizeTextarea(textareaRef.current)
    }
  }, [inputValue])

  const autoResizeTextarea = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = 'auto'
    textarea.style.height = `${textarea.scrollHeight}px`
  }

  return (
    <div className={cn('relative w-full', className)} ref={dropdownRef}>
      {!selectedOptions && (
        <textarea
          ref={textareaRef}
          value={inputValue}
          onChange={handleInputChange}
          placeholder={placeHolder}
          onClick={() => {
            setInputValue('')
            handleToggleDropdown()
          }}
          className="placeholder:text-neutral-medium-light text-primary-base flex w-full cursor-text resize-none items-center outline-none"
          readOnly={!isOpen}
          onKeyDown={handleKeyDown}
          rows={1}
        />
      )}

      {selectedOptions && (
        <span
          className="flex min-h-10 w-full rounded border p-3"
          onClick={handleToggleDropdown}
          data-testid="selected-options"
        >
          <div className="grid w-full grid-cols-[95%_5%]">
            <span className="flex flex-wrap gap-3 ">
              {(selectedOptions.length === 0 || !displaySelectedOptions) && <p>{placeHolder}</p>}
              {displaySelectedOptions &&
                selectedOptions.map((option) => <GroupBadge title={option.name['en']} key={option.id} />)}
            </span>
            <ChevronDownIcon />
          </div>
        </span>
      )}

      {isOpen && (
        <div
          className="absolute start-0 z-10 max-h-96 w-full overflow-y-scroll bg-white pt-4 text-start shadow-md"
          data-testid={testId}
        >
          {(disableFilter ? options : filteredOptions).map((option, index) => (
            <p
              key={option.id}
              ref={(el) => {
                optionRefs.current[index] = el
              }}
              className={cn(
                'min-h-10 w-full cursor-pointer py-2 ps-5 text-primary-base transition-colors duration-300',
                { 'font-bold': selectedOptions && selectedOptions.some((g) => g.id === option.id) },
                {
                  'bg-base-lightest': highlightedIndex === index,
                  'hover:bg-base-lightest': highlightedIndex !== index,
                }
              )}
              onClick={() => handleOptionSelection(option)}
            >
              {option.name[locale] || option.name['en']}
            </p>
          ))}
        </div>
      )}
    </div>
  )
}

export default Dropdown
