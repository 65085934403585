import React, { FC, useState } from 'react'
import { getDateFromISODate } from '@/root/shared-helpers'

import cn from 'classnames'
import { ChevronDownIcon } from '@/root/ui/shared/icons/ChevronDownIcon'
import { ChevronUpIconBlue } from '@/root/ui/admaritime'
import { ImageProps } from '@/root/shared-types'
import Image from 'next/image'

interface NotificationProps {
  title: string
  date: string
  body: string
  image: ImageProps
}

export const Notification: FC<NotificationProps> = ({ title, date, body, image }) => {
  const [expanded, setExpanded] = useState(false)
  const onExpand = () => setExpanded((prevState) => !prevState)

  return (
    <div
      className="flex cursor-pointer justify-between border-b border-adm-gray-300 pb-12 first:border-none"
      onClick={onExpand}
    >
      <span className="flex max-w-[95%] gap-10">
        <p className="text-base-very-light font-bold">{getDateFromISODate({ isoDate: date })}</p>
        <div className="flex flex-col gap-3">
          <h1 className={cn('font-semibold', { 'text-secondary': expanded })}>{title}</h1>
          {expanded && (
            <span className="flex items-start gap-3">
              <Image src={'/api' + image.url} alt={image.alternativeText} width={200} height={150} />
              <p>{body}</p>
            </span>
          )}
        </div>
      </span>
      <div className="flex size-6 cursor-pointer items-center justify-center rounded-full hover:bg-adm-gray-300">
        {expanded ? <ChevronUpIconBlue /> : <ChevronDownIcon color="primary-base" />}
      </div>
    </div>
  )
}
