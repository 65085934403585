import themeAdmaritime from './theme-admaritime.json'
import themeBluewave from './theme-bluewave.json'
import { BoraTheme } from '@/root/shared-types'

type Config = {
  theme: string
  oldBoraOrigin: string
  routeImagesSupported: boolean
  defaultLocale: string
  companyForm?: boolean
  privatePersonForm?: boolean
}

const configs: Record<string, Config> = {
  [themeAdmaritime.theme]: themeAdmaritime,
  [themeBluewave.theme]: themeBluewave,
}

export const currentConfig = configs[process.env.NEXT_PUBLIC_THEME as BoraTheme]
export const getCurrentConfig = (theme: BoraTheme) => configs[theme]
