import { FC, PropsWithChildren } from 'react'
import { AppConfigContext } from './AppConfigContext'
import { AppConfig } from '@/root/shared-types/app-config/app-config'
import { buildFormatter } from '@/root/utils/currency'

const AppConfigProvider: FC<PropsWithChildren<{ initialConfig: AppConfig }>> = ({ children, initialConfig }) => {
  return (
    <AppConfigContext.Provider
      value={{ ...initialConfig, formatMoney: buildFormatter(initialConfig.formats?.priceCustomConfig) }}
    >
      {children}
    </AppConfigContext.Provider>
  )
}

export default AppConfigProvider
