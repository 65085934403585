import { Button } from '@/root/ui/admaritime'
import { Icon } from '@/root/ui/admaritime'
import { ButtonHTMLAttributes, FC } from 'react'

interface ButtonWithIconProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  iconType: string
  label: string
  iconWidth?: number
}

const ButtonWithIcon: FC<ButtonWithIconProps> = ({ iconType, label, iconWidth, className, ...props }) => {
  return (
    <Button className={className} {...props}>
      <div className="flex items-center">
        <Icon alt={iconType} type={iconType} width={iconWidth || 20} />
        <div className="ms-2 pt-1">{label}</div>
      </div>
    </Button>
  )
}

export default ButtonWithIcon
