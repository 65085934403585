import React, { Dispatch, useEffect, useRef, useState } from 'react'
import { DropdownOption } from '@/root/shared-types'

interface useDropdownProps {
  options: DropdownOption[]
  selectedOption: DropdownOption
  setSelectedOption: Dispatch<React.SetStateAction<DropdownOption>>
  placeHolder: string
  onSelectOption?: (option: DropdownOption) => void
  selectedOptions?: DropdownOption[]
}
export const useDropdown = ({
  setSelectedOption,
  options,
  selectedOption,
  placeHolder,
  onSelectOption,
  selectedOptions,
}: useDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const dropdownRef = useRef<HTMLDivElement>(null)

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleOptionSelection = (route: DropdownOption) => {
    setSelectedOption(route)
    setIsOpen(false)
    if (onSelectOption) {
      onSelectOption(route)
    }
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  return {
    isOpen,
    selectedOption,
    options,
    handleToggleDropdown,
    handleOptionSelection,
    dropdownRef,
    placeHolder,
    selectedOptions,
  }
}
