import { FC } from 'react'
import { Banner, Checkbox } from '@/root/ui/admaritime'
import { useGetTranslations } from '@/root/shared-hooks'
import { getStyledHTML } from '@/root/utils/getStyledHTML'
import { admaritime } from '@/root/shared-consts/theme'

interface TermsAndConditionsProps {
  isAgree: boolean
  onAgree(): void
  terms?: string
}

export const TermsAndConditions: FC<TermsAndConditionsProps> = ({ onAgree, isAgree, terms }) => {
  const { getTranslation } = useGetTranslations()
  const styledTerms = terms ? getStyledHTML({ theme: admaritime, body: terms }) : ''
  return (
    <Banner className="w-[95%]" variant="info">
      <span className="flex pb-3 text-secondary">
        <Checkbox
          className="gap-2 text-start uppercase md:text-xl"
          label={`${getTranslation('confirmationTermsAndConditionsTermsQuestions')}:`}
          checked={isAgree}
          variant="success"
          onChange={onAgree}
        />
      </span>
      <div
        className="flex flex-col items-start gap-3 font-normal text-base"
        dangerouslySetInnerHTML={{ __html: styledTerms }}
      />
    </Banner>
  )
}
