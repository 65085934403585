import { DateTime } from 'luxon'

interface CheckIsDayDisabledProps {
  availableDates: Date[]
  disableBeforeDate?: Date
  disableAfter?: Date
}

export const checkIsDayDisabled =
  ({ availableDates, disableAfter, disableBeforeDate }: CheckIsDayDisabledProps) =>
  (date: Date) => {
    const dates = availableDates.map((date) => DateTime.fromJSDate(date)) || []
    const luxonDate = DateTime.fromJSDate(date)
    const intervalStart = disableBeforeDate ? DateTime.fromJSDate(disableBeforeDate).startOf('day') : DateTime.now().startOf('day')
    const isBeforeToday = luxonDate < intervalStart
    const isAfterPoint = disableAfter ? luxonDate > DateTime.fromJSDate(disableAfter).endOf('day') : false
    const isAvailableDate = dates.some((availableDate) => availableDate.hasSame(luxonDate, 'day'))

    return isBeforeToday || !isAvailableDate || isAfterPoint
  }
