import React, { FC, HTMLAttributes } from 'react'
import cn from 'classnames'

interface ContentContainerProps extends HTMLAttributes<HTMLDivElement> {
  disableBackground?: boolean
}
export const ContentContainer: FC<ContentContainerProps> = ({ children, className, disableBackground = false }) => (
  <div
    className={cn(
      `mx-auto my-10 flex max-w-screen-xl flex-col items-center gap-10 px-2 pb-14 pt-10 xs:px-4 xl:px-28`,
      className,
      {
        'bg-white shadow-md': !disableBackground,
      }
    )}
  >
    {children}
  </div>
)
