// These are endpoint used to call F-EDGE service

export const SAIL_CHANNEL = 'ONLINE'

export type CreateType = 'customer' | 'company'

interface ReservationIdParams {
  reservationId: string
}

interface EditPassengersDataParams extends ReservationIdParams {
  seqN: string
  modificationMode: string
}

interface TicketPreviewParams extends ReservationIdParams {
  format: 'PDF'
  locale: string
  reservationToken: string
}

type SailPackageAndDateParams = { sailPackage: string; date: string }
type SailRefIdAndRoundTripParams = { sailRefId: string; roundTrip: string }
type CompanyIdAndCustomerIdParams = { companyId: string; customersIds: string }

interface ReservationIdAndPaymentMethodParams extends ReservationIdParams {
  method: 'APPLE'
}

interface ReservationIdAndSailRefIdParams extends ReservationIdParams {
  sailRefId: string
}

interface ReservationPostAttributeParams extends ReservationIdParams {
  seqN: string
  attributeType: string
  attribute: string
}

export const getSailDatesPath = ({ sailPackage, date }: SailPackageAndDateParams) =>
  `/api/v1/sails/sailPackage/${sailPackage}/dates?fromDate=${date}`

export const getInventoryPath = ({ sailPackage, date }: SailPackageAndDateParams) =>
  `/api/v2/inventory/saleChannel/online/sailPackage/${sailPackage}/date/${date}`

export const getSailsPath = () => '/api/v1/sails/find?offset=0&limit=100'

export const getPricesPath = ({ sailRefId, roundTrip }: SailRefIdAndRoundTripParams) =>
  `/api/v1/price/sail/${sailRefId}?roundTrip=${roundTrip}`

export const getSignInByEmailPath = () => `/api/v1/security/token/email`

export const getCountryCatalogPath = () => `/api/v1/catalog/country`

export const getGoodsCatalogPath = () => `/api/v1/catalog/goods`

export const getConfigPath = () => `/api/v1/config`

export const getTenant = () => `/api/v1/tenant`

export const getRoutesPath = () => `/api/v1/routes`

export const getCallingCodesCatalogPath = () => `/api/v1/catalog/COUNTRY_CALLING_CODES`

export const getSailPackagesCompactPath = () => `/api/v1/sailPackages/${SAIL_CHANNEL}/compact`

export const getPaymentPayloadPath = ({ reservationId, method }: ReservationIdAndPaymentMethodParams) =>
  `/api/v1/reservation/${reservationId}/paymentMethod/${method}/paymentPayload`

export const getReservationPath = ({ reservationId }: ReservationIdParams) => `/api/v1/reservation/${reservationId}`

export const getEditPassengersDataPath = ({ reservationId, seqN, modificationMode }: EditPassengersDataParams) =>
  `/api/v1/reservation/${reservationId}/guest/${seqN}/${modificationMode}`

export const getReservationRefundOfferPath = ({ reservationId }: ReservationIdParams) =>
  `/api/v2/reservation/${reservationId}/refund/offer`

export const getTransactionPath = ({ reservationId }: ReservationIdParams) =>
  `/payment/api/v1/reservation/${reservationId}/transaction`

export const getTicketPreviewPath = ({ reservationId, format, locale, reservationToken }: TicketPreviewParams) =>
  `/document/api/v1/reservation/${reservationId}/confirmation/preview?format=${format}&locale=${locale}&token=${reservationToken}`

export const getReservationRefundSailsPath = ({ reservationId }: ReservationIdParams) =>
  `/api/v2/reservation/${reservationId}/refund/sails`

export const getReservationOwnerPath = ({ reservationId }: ReservationIdParams) =>
  `/api/v2/reservation/${reservationId}/owner`

export const getReservationTicketQrPath = ({ reservationId, sailRefId }: ReservationIdAndSailRefIdParams) =>
  `/api/v1/ticket/reservation/${reservationId}/sail/${sailRefId}/qr`

export const getV3ReservationPath = () => '/api/v3/reservation'

export const getV3ReservationByIdPath = ({ reservationId }: ReservationIdParams) =>
  `/api/v3/reservation/${reservationId}`

export const getV3ReservationConfirmPath = ({ reservationId }: ReservationIdParams) =>
  `/api/v3/reservation/${reservationId}/confirm`

export const getPostAttributePath = ({
  reservationId,
  seqN,
  attributeType,
  attribute,
}: ReservationPostAttributeParams) =>
  `/api/v1/reservation/${reservationId}/${attributeType}/${seqN}/attribute/${attribute}`

export const secured = {
  getReservationsForUserPath: () => `/secured/api/v1/reservations?offset=0&limit=300`,
  getCustomersPath: () => `/secured/api/v1/customers`,
  getCompaniesByUserPath: () => `/secured/api/v1/companies/by/user`,
  getCurrentCustomerInfoPath: () => `/secured/api/v1/customer/user/current`,
  getCreateCustomerWithTypePath: ({ type }: { type: CreateType }) => `/secured/api/v1/${type}`,
  getDeleteCompanyOrCustomerPath: ({ type, id }: { type: CreateType; id: string }) => `/secured/api/v1/${type}/${id}`,
  getCompanyCustomersPath: ({ companyId, customersIds }: CompanyIdAndCustomerIdParams) =>
    `/secured/api/v1/company/${companyId}/customers/${customersIds}`,
}
