import { FC } from 'react'
import { Control } from 'react-hook-form'
import { FormFieldContainer } from '@/root/components/admaritime/CustomerFormField/FormFieldContainer/FormFieldContainer'

interface RadioInputProps {
  control: Control
  options: { id: string; name: string }[]
  id: string
  label: string
}

export const RadioInput: FC<RadioInputProps> = ({ control, options, id, label }) => (
  <FormFieldContainer id={id} label={label} maxWidth="full">
    <div className="flex w-full gap-10">
      {options.map((opt) => (
        <div key={opt.id} className="flex items-center gap-5">
          <input {...control.register(id, { required: true })} value={opt.id} type="radio" />
          <p>{opt.name}</p>
        </div>
      ))}
    </div>
  </FormFieldContainer>
)
