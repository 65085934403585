export const dateFormats = {
  ISO_DATE: 'yyyy-MM-dd',
  US_DATE: 'MM-dd-yyyy',
  ISO_DATE_TIME: 'yyyy-MM-dd-hh-mm',
  DETAILED_DATE: 'dd.MM.yyyy - hh:mm a',
  TIME_12: 'hh:mm a',
  TIME_24: 'HH:mm',
  SIMPLE_TIME: 'h:mm a',
  FULL_TIME_24: 'HH:mm:ss',
  BIG_FORMAT: 'dd MMMM yyyy HH:mm',
  FULL_DATE_TIME_UPPER: 'MMMM d, yyyy h:mm a',
  FULL_DATE: 'dd MMMM yyyy',
  DD_MM_YYYY: 'dd-MM-yyyy',
}

export const dayPickerFormats = {
  general: 'MMMM yyyy',
}
