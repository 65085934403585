import { FC, ReactNode, ReactElement, cloneElement, useEffect, useMemo, useState, TouchEvent } from 'react'
import { SliderIconRight } from '@/root/ui/shared/icons/SliderIconRight'
import { SliderIconLeft } from '@/root/ui/shared/icons/SliderIconLeft'
import { SliderButtonContainer } from '../SliderButtonContainer/SliderButtonContainer'
import { SliderContainer } from '../SliderContainer/SliderContainer'
import { useCustomRouter } from '@/root/shared-hooks'

interface CarouselProps {
  slides: ReactNode[]
  slidesOnScreen?: number
  duration?: number
  infinite?: boolean
  elementWidth?: number
}

export const Carousel: FC<CarouselProps> = ({
  slides,
  slidesOnScreen = 1,
  duration = 300,
  infinite = true,
  elementWidth,
}) => {
  const { locale } = useCustomRouter()
  const isRTL = locale === 'ar'

  const [current, setCurrent] = useState(0)
  const [pages, setPages] = useState<ReactNode[]>([])
  const [transitionDuration, setTransitionDuration] = useState(0)
  const [touchStartX, setTouchStartX] = useState(0)
  const [touchEndX, setTouchEndX] = useState(0)

  useEffect(() => {
    if (!infinite) {
      setPages(slides)
      return
    }

    const headClones = slides
      .slice(-slidesOnScreen)
      .map((slide, url) => cloneElement(slide as ReactElement, { key: `head${url}` }))
    const tailClones = slides
      .slice(0, slidesOnScreen)
      .map((slide, url) => cloneElement(slide as ReactElement, { key: `tail${url}` }))
    setCurrent(slidesOnScreen)
    setPages([...headClones, ...slides, ...tailClones])
  }, [slides, infinite, slidesOnScreen])

  useEffect(() => {
    let timer1: NodeJS.Timeout
    let timer2: NodeJS.Timeout
    if (!infinite) return

    // jump from element 0 (clone) -> to the n-1 (real) for case with one slide on screen
    if (current === 0) {
      timer1 = setTimeout(() => {
        setTransitionDuration(0)
        setCurrent(pages.length - 1 - (2 * slidesOnScreen - 1))
      }, duration)
    }

    // jump from element n (clone) -> to the element 1 (real) for case with one slide on screen
    if (current === pages.length - slidesOnScreen) {
      timer2 = setTimeout(() => {
        setTransitionDuration(0)
        setCurrent(slidesOnScreen)
      }, duration)
    }
    return () => {
      clearTimeout(timer1)
      clearTimeout(timer2)
    }
  }, [infinite, current, pages, slidesOnScreen, duration])

  const previousSlide = () => {
    if (current !== 0) {
      setCurrent(current - 1)
    }
    if (transitionDuration === 0) {
      setTransitionDuration(duration)
    }
  }

  const nextSlide = () => {
    if (current !== pages.length - slidesOnScreen) {
      setCurrent(current + 1)
    }
    if (transitionDuration === 0) {
      setTransitionDuration(duration)
    }
  }

  const handleTouchStart = (e: TouchEvent<HTMLDivElement>) => {
    setTouchStartX(e.touches[0]?.clientX)
  }

  const handleTouchMove = (e: TouchEvent<HTMLDivElement>) => {
    setTouchEndX(e.touches[0]?.clientX)
  }

  const handleTouchEnd = () => {
    const swipeDistance = touchStartX - touchEndX
    if ((!isRTL && swipeDistance > 50) || (isRTL && swipeDistance < -50)) {
      nextSlide()
    } else if ((!isRTL && swipeDistance < -50) || (isRTL && swipeDistance > 50)) {
      previousSlide()
    }
  }

  const styleTransform = useMemo(
    () => ({
      transform: `translateX(${isRTL ? '' : '-'}${(current * 100) / slidesOnScreen}%)`,
      transition: `transform ease-out ${transitionDuration}ms`,
      display: 'flex',
    }),
    [current, slidesOnScreen, transitionDuration, isRTL]
  )

  return (
    <SliderContainer>
      <button onClick={previousSlide} className="justify-self-start">
        <SliderButtonContainer>
          <SliderIconLeft className="me-1 w-3 sm:w-5" />
        </SliderButtonContainer>
      </button>
      <div
        className="order-first col-span-full overflow-hidden xxs:order-none"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        style={{ ...(elementWidth && { maxWidth: `${elementWidth}px` }) }}
      >
        <div style={styleTransform} data-testid="carousel-pages-container">
          {pages}
        </div>
      </div>
      <button onClick={nextSlide} className="justify-self-end">
        <SliderButtonContainer>
          <SliderIconRight className="ms-1 w-3 sm:w-5" />
        </SliderButtonContainer>
      </button>
    </SliderContainer>
  )
}
