import { FC, FormEvent, InputHTMLAttributes } from 'react'
import cn from 'classnames'
import { FieldErrors, FieldValues, UseFormRegister, UseFormTrigger } from 'react-hook-form'
import { useGetTranslations } from '@/root/shared-hooks'

interface FormInputProps extends InputHTMLAttributes<HTMLInputElement> {
  register: UseFormRegister<FieldValues>
  errors: FieldErrors
  trigger: UseFormTrigger<FieldValues>
  validationPattern?: RegExp
  onBlur?(): void
}

interface ErrorMessages {
  required: string
  pattern: string
}

export const FormField: FC<FormInputProps> = ({
  id,
  className,
  register,
  placeholder,
  errors,
  trigger,
  validationPattern,
  inputMode,
  type,
  onBlur,
  ...rest
}) => {
  const { getTranslation } = useGetTranslations()

  const handleInput = (event: FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement

    if (inputMode === 'numeric') {
      target.value = target.value.replace(/(?!^)\D/g, '')

      if (!target.value.startsWith('+')) {
        target.value = target.value.replace(/\D/g, '')
      }

      target.value = target.value.replace(/^0+/, '')

      if (target.value.startsWith('+0')) {
        target.value = '+' + target.value.slice(2)
      }
    }

    trigger(id)
  }

  const errorMessages: ErrorMessages = {
    required: getTranslation('confirmationContactFormPhoneRequiredError'),
    pattern:
      type === 'email'
        ? getTranslation('confirmationContactFormInvalidEmailError')
        : getTranslation('confirmationContactFormInvalidFormatError'),
  }

  if (id) {
    const today = new Date().toISOString().split('T')[0]
    const dateProps = type === 'date' ? { min: '1923-01-01', max: today } : {}

    const errorType = errors[id]?.type as keyof ErrorMessages
    return (
      <span className={cn('flex min-h-16 gap-1 flex-col', className)}>
        <input
          type={type}
          {...dateProps}
          {...register(id, { required: true, pattern: validationPattern })}
          {...rest}
          placeholder={placeholder}
          className={cn('h-12 rounded border border-neutral-normal-lighter p-2', className)}
          required
          onInput={handleInput}
          onBlur={() => {
            if (onBlur) {
              onBlur()
            }
            trigger(id)
          }}
          onFocus={() => trigger(id)}
        />
        {errorType && (
          <p className="text-alert-dark flex justify-start text-xs font-bold">{errorMessages[errorType]}</p>
        )}
      </span>
    )
  }
}
