import { FC, PropsWithChildren, useState } from 'react'
import { ErrorBannerContext } from './ErrorBannerContext'

import { getDateFromTimeStamp } from '@/root/shared-helpers/Additional-info/additional-info-helpers'
import axios from 'axios'

interface ErrorHandlerProps {
  error: unknown
  time: Date
  message: string
}

const ErrorBannerProvider: FC<PropsWithChildren> = ({ children }) => {
  const [errorBody, setErrorBody] = useState('')

  const errorHandler = ({ error, time, message }: ErrorHandlerProps) => {
    if (axios.isAxiosError(error)) {
      setErrorBody(`${message} (${error.response?.status}, ${getDateFromTimeStamp(time)})`)
      return window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    setErrorBody(message)
  }

  return <ErrorBannerContext.Provider value={{ errorBody, errorHandler }}>{children}</ErrorBannerContext.Provider>
}

export default ErrorBannerProvider
