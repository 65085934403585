import { FC, HTMLAttributes, useState, useEffect, useRef } from 'react'
import { Kebab } from '@/root/ui/admaritime'

const KebabMenu: FC<HTMLAttributes<HTMLDivElement>> = ({ children }) => {
  const [isOpened, setIsOpened] = useState(false)
  const menuRef = useRef<HTMLDivElement>(null)

  const toggleMenu = () => {
    setIsOpened((prev) => !prev)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsOpened(false)
    }
  }

  useEffect(() => {
    if (isOpened) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpened])

  return (
    <div
      ref={menuRef}
      data-testid="passenger-row-actions-layout"
      className="relative flex cursor-pointer items-start justify-end"
    >
      <span onClick={toggleMenu} data-testid="passenger-group-row-actions-icon">
        <Kebab />
      </span>
      {isOpened && <div className="absolute right-0 top-full z-50 mt-2 w-32 bg-white shadow-lg">{children}</div>}
    </div>
  )
}

export default KebabMenu
