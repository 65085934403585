import { AppleIcon, Button } from '@/root/ui/admaritime'
import { ButtonHTMLAttributes, FC } from 'react'
import { useGetTranslations } from '@/root/shared-hooks'

export const ApplePayButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({ disabled, ...rest }) => {
  const { getTranslation } = useGetTranslations()
  return (
    <Button
      variant="black"
      disabled={disabled}
      className="flex w-96 justify-center gap-1 pt-5 text-2xl font-bold"
      {...rest}
    >
      <AppleIcon color={disabled ? 'adm-base-disable-button' : 'white'} width={20} />
      <p className="uppercase">{getTranslation('confirmationApplePay')}</p>
    </Button>
  )
}
