import { createContext, ReactNode } from 'react'

type ContextType = {
  onClose: () => void
  setModalBody: (body: JSX.Element) => void
  modalBody: ReactNode | null
}
export const ModalContext = createContext<ContextType>({
  onClose: () => {
    null
  },
  setModalBody: () => null,
  modalBody: null,
})
