import { createContext } from 'react'

interface ErrorHandlerProps {
  error: unknown
  time: Date
  message: string
}

type ContextType = { errorBody: string; errorHandler?: (props: ErrorHandlerProps) => void }
export const ErrorBannerContext = createContext<ContextType>({
  errorBody: '',
})
