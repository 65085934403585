import { FC, HTMLAttributes } from 'react'
import { useGetTranslations } from '@/root/shared-hooks'
import { HeadingType } from '@/root/shared-types/ui/ui-types'

export interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  type: HeadingType
}

export const Heading: FC<HeadingProps> = ({ type }) => {
  const { getTranslation } = useGetTranslations()

  const headings: Record<HeadingType, string> = {
    recovery: getTranslation('forgotPassHeaderTitle'),
    signup_email: getTranslation('authSignupWithEmail'),
    signup_facebook: getTranslation('authSignupWithFacebook'),
    signup_google: getTranslation('authSignupWithGoogle'),
    signup_uae: getTranslation('authSignupWithUae'),
    login_email: getTranslation('authSigninWithEmail'),
    login_facebook: getTranslation('authSigninWithFacebook'),
    login_google: getTranslation('authSigninWithGoogle'),
    login_uae: getTranslation('authSigninWithUae'),
  }

  const headingText = headings[type] || null

  return (
    <h1 className="text-primary-base mt-1.5 text-center text-base font-bold uppercase leading-7 xs:text-lg sm:ltr:text-large md:rtl:text-large">
      {headingText}
    </h1>
  )
}

export default Heading
