import React, { FC, ChangeEvent } from 'react'
import cn from 'classnames'
import { EmailIconBlue } from '@/root/ui/shared/icons/EmailIconBlue'
import { FacebookIconBlue } from '@/root/ui/shared/icons/FacebookIconBlue'
import { GoogleIconColored } from '@/root/ui/shared/icons/GoogleIconColored'
import { useGetTranslations } from '@/root/shared-hooks'
import { AuthMethod } from '@/root/shared-types/ui/ui-types'
import { AuthMethodSelectorInput, AuthMethodSelectorLabel } from '@/root/modules/admaritime/LoginPage'

interface AuthMethodSelectorProps {
  authMethod: AuthMethod
  onAuthMethodChange: (event: ChangeEvent<HTMLInputElement>) => void
}

export const AuthMethodSelector: FC<AuthMethodSelectorProps> = ({ authMethod, onAuthMethodChange }) => {
  const { getTranslation } = useGetTranslations()

  return (
    <form className="flex w-full flex-wrap justify-around gap-5 text-base font-bold uppercase">
      <AuthMethodSelectorLabel className="rtl:order-3 xs:rtl:order-1">
        <AuthMethodSelectorInput value="email" checked={authMethod === 'email'} onChange={onAuthMethodChange} />
        <EmailIconBlue color={authMethod === 'email' ? 'primary-base' : 'base-normal-light'} />
        <p
          className={cn('whitespace-pre-line', authMethod === 'email' ? 'text-primary-base' : 'text-base-normal-light')}
        >
          {getTranslation('authMethodEmailMobile')}
        </p>
      </AuthMethodSelectorLabel>

      <AuthMethodSelectorLabel className="rtl:order-2 xs:rtl:order-2">
        <AuthMethodSelectorInput value="facebook" checked={authMethod === 'facebook'} onChange={onAuthMethodChange} />
        <FacebookIconBlue color={authMethod === 'facebook' ? undefined : 'base-normal-light'} />
        <p className={cn(authMethod === 'facebook' ? 'text-primary-base' : 'text-base-normal-light')}>
          {getTranslation('authMethodFacebook')}
        </p>
      </AuthMethodSelectorLabel>

      <AuthMethodSelectorLabel className="rtl:order-1 xs:rtl:order-3">
        <AuthMethodSelectorInput value="google" checked={authMethod === 'google'} onChange={onAuthMethodChange} />
        <div className="h-6">
          <GoogleIconColored width={21} color={authMethod === 'google' ? undefined : 'base-normal-lighter'} />
        </div>
        <p className={cn(authMethod === 'google' ? 'text-primary-base' : 'text-base-normal-light')}>
          {getTranslation('authMethodGoogle')}
        </p>
      </AuthMethodSelectorLabel>
    </form>
  )
}

export default AuthMethodSelector
