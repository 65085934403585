export const validatePhoneNumber = (phoneNumber: string) => {
  return /^\+\d{8,}$/.test(phoneNumber)
}

export const validateEmail = (email: string) => {
  const emailRegex = /^[^._-][a-zA-Z0-9._-]+@[a-zA-Z.-]+\.[a-zA-Z]+$/
  const exceptedCombinations = /(\.@|@\.|\.\.|\._|_\.|--|_-|-_|@-|-@|@_|_@)/
  return emailRegex.test(email) && !exceptedCombinations.test(email)
}

export const validatePassword = (password: string) => {
  const passwordRegex = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[@$!%*?&])[^\s]{8,}$/
  return passwordRegex.test(password)
}

export const isValidEmailOrPhoneNumber = (text: string) => {
  return validateEmail(text) || validatePhoneNumber(text)
}
