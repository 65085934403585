import { FC } from 'react'
import { News, SiteInfo } from '@/root/shared-types'
import { Notifications, NotesFooter } from '@/root/modules/admaritime'

interface NewsSectionProps {
  news: News[]
  siteInfos: SiteInfo[]
}

export const NewsSection: FC<NewsSectionProps> = ({ news, siteInfos }) => (
  <div className="bg-white shadow-sm">
    <Notifications news={news} />
    <NotesFooter notes={siteInfos} />
  </div>
)
