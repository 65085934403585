import { FC, PropsWithChildren, useEffect, useState } from 'react'
import { AuthContext, AuthContextTokenSetter } from './AuthContext'

const AuthContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [token, setToken] = useState<string | null>(null)

  useEffect(() => {
    const getCookie = (name: string) => {
      const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
      return match ? match[2] : null
    }

    const authJWT = getCookie('AuthJWT')
    if (!authJWT) {
      return
    }
    setToken(authJWT)
  }, [])

  return (
    <AuthContextTokenSetter.Provider value={setToken}>
      <AuthContext.Provider value={{ token, isAuth: token !== null }}>{children}</AuthContext.Provider>
    </AuthContextTokenSetter.Provider>
  )
}

export default AuthContextProvider
