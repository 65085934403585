import { CaptionProps, useNavigation } from 'react-day-picker'
import { format } from 'date-fns'
import { dayPickerFormats } from '@/root/shared-consts'
import { ChevronDownIcon } from '@/root/ui/shared/icons/ChevronDownIcon'
import { useRouter } from 'next/router'

import { es } from 'date-fns/locale'
import { ar } from 'date-fns/locale'
import { enUS } from 'date-fns/locale'
import * as R from 'ramda'

const getLocaleData = R.cond([
  [R.equals('es'), R.always(es)],
  [R.equals('ar'), R.always(ar)],
  [R.T, R.always(enUS)],
])

export const CustomNavbar = (props: CaptionProps) => {
  const { goToMonth, nextMonth, previousMonth } = useNavigation()
  const { locale } = useRouter()

  const localeData = getLocaleData(locale ?? 'en')

  return (
    <div className="flex w-full justify-between px-3 text-base">
      <h2>{format(props.displayMonth, dayPickerFormats.general, { locale: localeData }).toUpperCase()}</h2>
      <span className="flex gap-5">
        <button disabled={!previousMonth} onClick={() => previousMonth && goToMonth(previousMonth)}>
          <ChevronDownIcon width={14} height={14} color="secondary" className="rotate-90" ignoreRtl />
        </button>
        <button disabled={!nextMonth} onClick={() => nextMonth && goToMonth(nextMonth)}>
          <ChevronDownIcon width={14} height={14} color="secondary" className="-rotate-90" ignoreRtl />
        </button>
      </span>
    </div>
  )
}
