import axios from 'axios'
import useSWRImmutable from 'swr/immutable'

interface GetReservationsByUserProps {
  customerId: number
}

export const useGetReservationByUser = ({ customerId }: GetReservationsByUserProps) => {
  const fetchReservationsByUser = async () => {
    const { data } = await axios({
      url: `/api/user/reservations`,
      data: { customerId },
      method: 'post',
    })
    return data
  }
  const { data, error, isLoading } = useSWRImmutable(`get-reservations`, fetchReservationsByUser)
  return { data, error, isLoading }
}
