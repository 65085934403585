import { admaritimeTranslations } from './admaritime'
import { admaritimeMessages } from './admaritime/messages'
import { bluewaveMessages } from '@/lang/bluewave/messages'
import { bluewaveTranslations } from '@/lang/bluewave'

const translations = {
  admaritime: admaritimeTranslations,
  bluewave: bluewaveTranslations,
}

const messages = {
  admaritime: admaritimeMessages,
  bluewave: bluewaveMessages,
}

export type AvailableTheme = keyof typeof translations

export const currentTranslations = translations[process.env.NEXT_PUBLIC_THEME as AvailableTheme] || {}
export const getCurrentTranslations = (theme: AvailableTheme) => translations[theme] || {}
// export const currentMessages: Messages = messages[process.env.NEXT_PUBLIC_THEME as AvailableTheme] || {}
export const getMessages = (theme: AvailableTheme) => messages[theme]
