import { FC } from 'react'
import { Row } from '@/root/shared-types'
import { TableCell } from '@/root/components/admaritime'

interface TableBodyProps {
  rows: Row[]
}

export const TableBody: FC<TableBodyProps> = ({ rows }) => (
  <div className="w-full">
    {rows.map(({ type, price }) => (
      <div className="grid h-16 w-full grid-cols-2 border border-t-0 border-adm-gray-300" key={type}>
        <TableCell label={type} />
        <TableCell label={price} />
      </div>
    ))}
  </div>
)
