import { FC } from 'react'
import { useGetTranslations } from '@/root/shared-hooks'
import { useRouter } from 'next/router'
import { Tabs } from '../AppLayout/components/Tabs/Tabs'
import { APP_ROUTES } from '@/root/shared-consts/routes/routes'

const CustomerHeader: FC = () => {
  const { getTranslation } = useGetTranslations()
  const router = useRouter()
  const currentPath = router.pathname

  const tabs = [
    { title: getTranslation('ticketsLabel'), key: 'ticketsLabel', path: APP_ROUTES.reservations },
    { title: getTranslation('passengersData'), key: 'passengersData', path: APP_ROUTES.passengersData },
    { title: getTranslation('customerCredentials'), key: 'customerCredentials', path: APP_ROUTES.details },
  ]

  const currentTabTitle = tabs.find((tab) => tab.path === currentPath)?.title || ''

  const handleTabClick = (title: string) => {
    const selectedTab = tabs.find((tab) => tab.title === title)
    if (selectedTab) {
      router.push(selectedTab.path)
    }
  }

  return (
    <main className="bg-primary-base flex h-16 items-center p-7 text-center md:h-20 md:w-full md:bg-bw-ad-body-background">
      <section className="flex w-full md:gap-16">
        <Tabs tabs={tabs.map((tab) => tab.title)} currentTab={currentTabTitle} setTab={handleTabClick} />
      </section>
    </main>
  ) 
}

export default CustomerHeader
