import { FC, InputHTMLAttributes } from 'react'
import { UseFormRegister, FieldValues, RegisterOptions, FieldErrors, ValidationRule} from 'react-hook-form'
import { useGetTranslations } from '@/root/shared-hooks'
import { LockIconGray } from '@/root/ui/admaritime'
import cn from 'classnames'

interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  value: string
  register: UseFormRegister<FieldValues>
  name: string
  validationPattern?: ValidationRule<RegExp>
  validate?: RegisterOptions['validate']
  errors?: FieldErrors
}

export const InputField: FC<InputFieldProps> = ({
  type,
  placeholder,
  className,
  value,
  register,
  name,
  validationPattern,
  validate,
  errors ={},
  ...rest
}) => {
  const { getTranslation } = useGetTranslations()
  return (
    <div data-testid="input-wrapper" className={cn('relative mt-2 w-full', className)}>
      {type === 'password' && <LockIconGray className="absolute inset-y-3.5 start-3 xxs:start-4" />}
      {!value && (
        <div
          className={cn(
            'text-base-normal-light pointer-events-none absolute inset-y-0 flex h-12 items-center pe-2 pt-0.5 text-sm leading-6 xxs:pe-4 sm:hidden',
            { 'ps-3 xxs:ps-4': type === 'text', 'ps-10 xxs:ps-12': type === 'password' }
          )}
        >
          {placeholder}
        </div>
      )}

      <input
        className={cn(
          'bg-transparent border-neutral-normal-lighter text-primary-base placeholder:text-base-normal-light h-12 w-full appearance-none justify-self-start rounded border pe-2 text-lg outline-none placeholder:invisible placeholder:text-sm xxs:pe-4 sm:placeholder:visible',
          { 'ps-3 xxs:ps-4': type === 'text', 'ps-10 xxs:ps-12': type === 'password' }
        )}
        type={type}
        placeholder={placeholder}
        {...rest}
        {...register(name, {
          required: getTranslation('requiredError'),
          pattern: validationPattern,
          validate,
        })}
      />
      <p
        className={cn('text-error-medium-light ps-3 text-xs xxs:pe-3 xs:ps-4', {
          ' h-3': type === 'text',
          ' h-7': type === 'password',
        })}
      >
        {errors[name]?.message as string}
      </p>
    </div>
  )
}

export default InputField
