export const bluewaveMessages = {
  headerLangSwitcher: {
    id: 'header.lang-switcher',
    defaultMessage: 'English',
    description: 'Locale switcher label in header',
  },
  ticketBannerTitle: {
    id: 'ticket.banner.title',
    defaultMessage: 'THANK YOU!',
    description: "Ticket's page banner title",
  },
  ticketBannerBody: {
    id: 'ticket.banner.body',
    defaultMessage: "Your payment succeeded, below you'll find your ticket(s)",
    description: "Ticket's page banner body",
  },
  ticketBannerErrorTitle: {
    id: 'ticket.error-banner.title',
    defaultMessage: 'WARNING',
    description: "Ticket's page error-banner title",
  },
  ticketBannerErrorBody: {
    id: 'ticket.error-banner.body',
    defaultMessage:
      'The server throws random errors with no specific codes. let’s pretend it’s aliens invasion, please reboot the Earth!',
    description: "Ticket's page error-banner body",
  },
  ticketBookingAwaitingPaymentTitle: {
    id: 'ticket.booking-awaiting-payment.title',
    defaultMessage: 'Your booking is awaiting payment within 48 hours',
    description: 'Awaiting payment banner title',
  },
  ticketBookingAwaitingPaymentBodyPart1: {
    id: 'ticket.booking-awaiting-payment-part1.body',
    defaultMessage: 'If no payment received before',
    description: 'Awaiting payment banner body',
  },
  ticketBookingAwaitingPaymentBodyPart2: {
    id: 'ticket.booking-awaiting-payment-part2.body',
    defaultMessage: 'booking will be automatically cancelled.',
    description: 'Awaiting payment banner body',
  },
  priceCategoryTranslation: {
    id: 'ticket.booking-details.header-ticket-type',
    defaultMessage: 'Ticket Type',
    description: 'Type of the ticket in booking',
  },
  quantity: {
    id: 'ticket.booking-details.header-quantity',
    defaultMessage: 'Quantity',
    description: 'Quantity of the tickets in booking',
  },
  price: {
    id: 'ticket.booking-details.header-price',
    defaultMessage: 'Price',
    description: 'Price of the ticket in booking',
  },
  tax: {
    id: 'ticket.booking-details.header-vat',
    defaultMessage: 'VAT',
    description: 'Tax of the ticket in booking',
  },
  subtotal: {
    id: 'ticket.booking-details.header-subtotal',
    defaultMessage: 'Subtotal',
    description: 'Subtotal price of the ticket in booking',
  },
  paymentAgreeTitle: {
    id: 'ticket.payment-agree',
    defaultMessage: 'I AM AGREE WITH TERMS OF SERVICES:',
    description: 'agreement with terms',
  },
  totalPrice: {
    id: 'ticket.booking-details.header-total-to-pay',
    defaultMessage: 'total to pay',
    description: 'Total price of the ticket in booking',
  },
  paymentPayWithCardButton: {
    id: 'ticket.payment-button.pay-with-card',
    defaultMessage: 'pay with card',
    description: 'Awaiting click to proceed the payment',
  },
  paymentBankTransferButton: {
    id: 'ticket.payment-button.bank-transfer',
    defaultMessage: 'obligatory bank transfer within 48 hours',
    description: 'Awaiting click to proceed the payment',
  },
  ticketTicketNumber: {
    id: 'ticket-number',
    defaultMessage: 'Ticket No',
    description: 'Ticket number',
  },
  ticketBookingNumber: {
    id: 'booking-number',
    defaultMessage: 'Booking No',
    description: 'Booking number',
  },
  ticketVehicleRegNumber: {
    id: 'vehicle_nr',
    defaultMessage: 'Vehicle reg. nr',
    description: 'Vehicle reg. nr',
  },
  ticketActionButtonsDownload: {
    id: 'ticket.action-buttons.download',
    defaultMessage: 'Download invoice',
    description: 'Download the ticket',
  },

  ticketActionButtonsPrint: {
    id: 'ticket.action-buttons.print',
    defaultMessage: 'Print out invoice',
    description: 'Print the ticket',
  },
  ticketPaymentInfoTitle: {
    id: 'ticket.payment-info.title',
    defaultMessage: 'PLEASE USE FOLLOWING PAYMENT DETAILS FOR YOUR TRANSFER:',
    description: 'Ticket payment info title',
  },
  ticketPaymentRecipient: {
    id: 'ticket.payment-recipient',
    defaultMessage: 'Payment recipient:',
    description: 'Payment recipient',
  },
  ticketPaymentRecipientName: {
    id: 'ticket.payment-recipient-name',
    defaultMessage: 'Blue Wave Corp.',
    description: 'Blue Wave Corp.',
  },
  ticketPaymentRecipientBankAccountNr: {
    id: 'ticket.payment-recipient-bank-account-nr',
    defaultMessage: 'Payment recipient:',
    description: 'Payment recipient',
  },
  ticketPaymentRecipientBankAccountNrValue: {
    id: 'ticket.payment-recipient-bank-account-nr-value',
    defaultMessage: '104343231',
    description: 'Payment recipient bank account',
  },
  ticketPaymentRecipientBank: {
    id: 'ticket.payment-recipient-bank',
    defaultMessage: 'Recipient bank:',
    description: 'Recipient bank',
  },
  ticketPaymentCIF: {
    id: 'ticket.payment-CIF',
    defaultMessage: 'Client number (CIF):',
    description: 'Client number (CIF)',
  },
  ticketPaymentCIFValue: {
    id: 'ticket.payment-CIF-value',
    defaultMessage: '985684',
    description: 'Client number (CIF)',
  },
  ticketPaymentRecipientBankName: {
    id: 'ticket.payment-recipient-bank-name',
    defaultMessage: 'BAC International Bank Inc',
    description: 'BAC International Bank Inc',
  },
  ticketPaymentRecipientBankCode: {
    id: 'ticket.payment-recipient-bank-code',
    defaultMessage: 'Recipient bank code:',
    description: 'Recipient bank code',
  },
  ticketPaymentDescription: {
    id: 'ticket.payment-payment-description',
    defaultMessage: 'Payment description:',
    description: 'Payment description',
  },
  ticketPaymentAmount: {
    id: 'ticket.payment-payment amount',
    defaultMessage: 'Payment amount:',
    description: 'Payment amount',
  },
  ticketPaymentSwiftCode: {
    id: 'ticket.payment-swift-code',
    defaultMessage: 'BCINPAPA',
    description: 'BCINPAPA',
  },
  ticketPaymentCurrency: {
    id: 'ticket.payment-currency',
    defaultMessage: 'US',
    description: 'US',
  },
  ticketPaymentCurrencyKey: {
    id: 'ticket.payment-currency-key',
    defaultMessage: 'Currency',
    description: 'Currency',
  },
  ticketDeletedBookingTitlePart1: {
    id: 'ticket.deleted-booking-title-part1',
    defaultMessage: 'Reservation Not Found! Solution is to start over reservation process.',
    description: 'Reservation Not Found! Solution is to start over reservation process.',
  },
  ticketDeletedBookingTitleLink: {
    id: 'ticket.deleted-booking-title-link',
    defaultMessage: 'start over',
    description: 'start over',
  },
  ticketDeletedBookingTitlePart2: {
    id: 'ticket.deleted-booking-title-part2',
    defaultMessage: 'Reservation Not Found! Solution is to start over reservation process.',
    description: 'Reservation Not Found! Solution is to start over reservation process.',
  },
  ticketDeletedBookingButton: {
    id: 'ticket.deleted-booking-button',
    defaultMessage: 'Back to main page',
    description: 'Back to main page',
  },
  appPoweredByBluewave: {
    id: 'app.powered-by-bluewave',
    defaultMessage: 'Blue Wave Corp. | Powered by Bora Systems',
    description: 'Blue Wave Corp. | Powered by Bora Systems',
  },
  driverLicenseNumber: {
    id: 'additional-info.driver-license-number',
    defaultMessage: "Driver's license number",
    description: "Driver's license number",
  },

  driverLicenseDocument: {
    id: 'additional-info.driver-license-document',
    defaultMessage: "Driver's license document",
    description: "Driver's license document",
  },
  driverTransporterFile: {
    id: 'additional-info.driver-transporter-file',
    defaultMessage: 'Document of the transporter such as his passport',
    description: 'Document of the transporter such as his passport',
  },
  vehicleCarrierCode: {
    id: 'additional-info.vehicle-carrier-code',
    defaultMessage: 'Carrier code',
    description: 'Carrier code',
  },
  vehicleShipper: {
    id: 'additional-info.vehicle-shipper',
    defaultMessage: 'Shipper’s name',
    description: 'Shipper’s name',
  },
  vehicleConsignee: {
    id: 'additional-info.vehicle-consignee',
    defaultMessage: 'Consignee’s name',
    description: 'Consignee’s name',
  },
  vehicleGoodsType: {
    id: 'additional-info.vehicle-goods-type',
    defaultMessage: 'Type of goods to transport',
    description: 'Type of goods to transport',
  },
  vehicleMerchandiseDescription: {
    id: 'additional-info.vehicle-merchandise-description',
    defaultMessage: 'Describe the merchandise to be transported',
    description: 'Describe the merchandise to be transported',
  },
  driver: {
    id: 'driver',
    defaultMessage: 'Driver: ',
    description: 'Driver',
  },
  additionalInfoBannerInfoNotFilled: {
    id: 'additional-info.banner-info-not-filled',
    defaultMessage: 'Additional data is required to be filled before Check-in!',
    description: 'Additional data is required to be filled before Check-in!',
  },
  additionalInfoBannerInfoFilled: {
    id: 'additional-info.banner-info-filled',
    defaultMessage: 'All required data is filled, you’re ready for Check-in!',
    description: 'All required data is filled, you’re ready for Check-in!',
  },
  additionalFieldsProcessError: {
    id: 'additional-info.additional-fields-process-error',
    defaultMessage: 'An error occurred during the editing process, please try again later',
    description: 'An error occurred during the editing process, please try again later',
  },
  actionEdit: {
    id: 'action-edit',
    defaultMessage: 'EDIT',
    description: 'EDIT',
  },
  actionSave: {
    id: 'action-save',
    defaultMessage: 'SAVE',
    description: 'SAVE',
  },
  actionSelectFile: {
    id: 'action-select-file',
    defaultMessage: 'SELECT FILE',
    description: 'SELECT FILE',
  },
  actionUploadFile: {
    id: 'action-upload-file',
    defaultMessage: 'UPLOAD',
    description: 'UPLOAD',
  },
  firstName: {
    id: 'first-name',
    defaultMessage: 'First name',
    description: 'First name',
  },
  lastName: {
    id: 'last-name',
    defaultMessage: 'Last name',
    description: 'Last name',
  },
  birthday: {
    id: 'birthday',
    defaultMessage: 'Birthday',
    description: 'birthday',
  },
  birthPlace: {
    id: 'birthPlace',
    defaultMessage: 'Place of birth',
    description: 'Place of birth',
  },
  passportNr: {
    id: 'passport-nr',
    defaultMessage: 'Passport nr.',
    description: 'Passport nr.',
  },
  passportExpirationDate: {
    id: 'passport-expiration-date',
    defaultMessage: 'Passport expiration date',
    description: 'Passport expiration date',
  },
  carWidth: {
    id: 'width',
    defaultMessage: 'Width',
    description: 'Width',
  },
  carHeight: {
    id: 'height',
    defaultMessage: 'Car Height',
    description: 'Car Height',
  },
  carLength: {
    id: 'length',
    defaultMessage: 'Length',
    description: 'Length',
  },
  carWeight: {
    id: 'weight',
    defaultMessage: 'Car Weight',
    description: 'Car Weight',
  },
  uploading: {
    id: 'uploading',
    defaultMessage: 'UPLOADING',
    description: 'UPLOADING',
  },
  saving: {
    id: 'saving',
    defaultMessage: 'SAVING',
    description: 'SAVING',
  },
  uploadingFile: {
    id: 'uploading-file',
    defaultMessage: 'Uploading file ...',
    description: 'Uploading file',
  },
  selectPlaceholder: {
    id: 'select-placeholder',
    defaultMessage: 'Pick one from the list',
    description: 'Pick one from the list',
  },
  chooseFiles: {
    id: 'choose-files',
    defaultMessage: 'Choose some files to upload',
    description: 'Choose some files to upload',
  },

  confirmPageTitle: {
    id: 'confirm-page-title',
    defaultMessage: 'Customer information',
    description: 'Customer information',
  },

  confirmPageCompanyTab: {
    id: 'confirm-page-company-tab',
    defaultMessage: 'Invoice to Company',
    description: 'Invoice to Company',
  },

  confirmPagePersonTab: {
    id: 'confirm-page-person-tab',
    defaultMessage: 'Private person',
    description: 'Private person',
  },

  confirmPageCompanyName: {
    id: 'confirm-page-company.company-name',
    defaultMessage: 'Company Name',
    description: 'Company Name',
  },

  confirmPageCompanyNamePlaceholder: {
    id: 'confirm-page-company.company-name-placeholder',
    defaultMessage: 'Type your company name here',
    description: 'Company Name Placeholder',
  },

  confirmPageVatNum: {
    id: 'confirm-page-company.vat-num',
    defaultMessage: 'VatNum',
    description: 'VatNum',
  },

  confirmPageVatNumPlaceholder: {
    id: 'confirm-page-company.vat-num-placeholder',
    defaultMessage: 'Type your VAT number here',
    description: 'VAT number Placeholder',
  },

  confirmPageCountry: {
    id: 'confirm-page-company.country',
    defaultMessage: 'Country',
    description: 'Country',
  },

  confirmPageCountryPlaceholder: {
    id: 'confirm-page-company.country-placeholder',
    defaultMessage: 'Type your country here',
    description: 'Country Placeholder',
  },

  confirmPageRuc: {
    id: 'confirm-page-company.ruc',
    defaultMessage: 'RUC',
    description: 'RUC',
  },

  confirmPageRucPlaceholder: {
    id: 'confirm-page-company.ruc-placeholder',
    defaultMessage: 'Type your RUC here',
    description: 'RUC Placeholder',
  },

  confirmPageCompanyPhoneNumber: {
    id: 'confirm-page-company.phone-number',
    defaultMessage: 'Phone Number',
    description: 'Phone Number',
  },

  confirmPageCompanyPhoneNumberPlaceholder: {
    id: 'confirm-page-company.phone-number-placeholder',
    defaultMessage: 'Type your phone number here',
    description: 'Phone number Placeholder',
  },

  confirmPageCompanyEmail: {
    id: 'confirm-page-company.e-mail',
    defaultMessage: 'E-mail',
    description: 'E-mail',
  },

  confirmPageCompanyEmailPlaceholder: {
    id: 'confirm-page-company.e-mail-placeholder',
    defaultMessage: 'Type your e-mail here',
    description: 'E-mail Placeholder',
  },

  confirmPageZipCode: {
    id: 'confirm-page-company.zip-code',
    defaultMessage: 'Zip Code',
    description: 'Zip Code',
  },

  confirmPageZipCodePlaceholder: {
    id: 'confirm-page-company.zip-code-placeholder',
    defaultMessage: 'Type your zip-code here',
    description: 'Zip-code Placeholder',
  },

  confirmPageFirstName: {
    id: 'confirm-page-person.first-name',
    defaultMessage: 'First name',
    description: 'First name',
  },

  confirmPageFirstNamePlaceholder: {
    id: 'confirm-page-person.first-name-placeholder',
    defaultMessage: 'Type your first name here',
    description: 'First name Placeholder',
  },

  confirmPageSurname: {
    id: 'confirm-page-person.surname',
    defaultMessage: 'Surname',
    description: 'Surname',
  },

  confirmPageSurnamePlaceholder: {
    id: 'confirm-page-person.surname-placeholder',
    defaultMessage: 'Type your surname here',
    description: 'Surname Placeholder',
  },

  confirmPagePersonPhoneNumber: {
    id: 'confirm-page-person.mob-phone',
    defaultMessage: 'Mob. phone nr.',
    description: 'Mob. phone nr.',
  },

  confirmPagePersonPhoneNumberPlaceholder: {
    id: 'confirm-page-person.mob-phone-placeholder',
    defaultMessage: 'Type your mobile phone here',
    description: 'Mobile phone Placeholder',
  },

  confirmPagePersonEmail: {
    id: 'confirm-page-person.e-mail',
    defaultMessage: 'E-mail',
    description: 'E-mail',
  },

  confirmPagePersonEmailPlaceholder: {
    id: 'confirm-page-person.e-mail-placeholder',
    defaultMessage: 'Type your e-mail here',
    description: 'E-mail Placeholder',
  },

  confirmPageErrorMessage: {
    id: 'confirm-page.error-message',
    defaultMessage: 'Invalid format',
    description: 'Invalid format',
  },

  confirmPageRequiredField: {
    id: 'confirm-page.required-field',
    defaultMessage: 'Required field',
    description: 'Required field',
  },

  confirmPageRequestSuccess: {
    id: 'confirm-page-company.request-success',
    defaultMessage: 'Data is sent successfully!',
    description: 'Data is sent successfully!',
  },

  confirmPageRequestError: {
    id: 'confirm-page-company.request-error',
    defaultMessage: 'An error occurred while sending data. Please try again.',
    description: 'An error occurred while sending data. Please try again.',
  },

  confirmPageTemporaryButton: {
    id: 'confirm-page-company.temporary-button',
    defaultMessage: 'Send',
    description: 'Send',
  },

  staticContentFaq: {
    id: 'static-content-faq',
    defaultMessage: 'FAQ',
    description: 'FAQ',
  },

  staticContentNews: {
    id: 'static-content-news',
    defaultMessage: 'NEWS',
    description: 'NEWS',
  },

  vesselPageTitle: {
    id: 'vessel-page-title',
    defaultMessage: 'OUR FLEET',
    description: 'OUR FLEET',
  },

  chooseRouteSearchBarLabel: {
    id: 'choose-route-routeBar.label',
    defaultMessage: 'Choose route',
    description: 'Choose route',
  },

  pickDateCalendarLabel: {
    id: 'pick-date-calendar.label',
    defaultMessage: 'Pick a date from calendar',
    description: 'Pick a date from calendar',
  },

  TicketPurchaseWhere: {
    id: 'ticket-purchase.where',
    defaultMessage: 'Where',
    description: 'Where',
  },

  TicketPurchaseWhen: {
    id: 'ticket-purchase.when',
    defaultMessage: 'Where',
    description: 'Where',
  },

  TicketPurchaseRoundTrip: {
    id: 'ticket-purchase.round-trip',
    defaultMessage: 'Round-Trip',
    description: 'Round-Trip',
  },

  TicketPurchaseGetTickets: {
    id: 'ticket-purchase.get-tickets',
    defaultMessage: 'Get Tickets',
    description: 'Get Tickets',
  },
  destinationStep: {
    id: 'view-progress.destination-step',
    defaultMessage: 'Select date and time',
    description: 'Select date and time',
  },
  selectTicketStep: {
    id: 'view-progress.select-ticket-step',
    defaultMessage: 'Select Ticket(s)',
    description: 'Select Ticket(s)',
  },
  selectConfirmStep: {
    id: 'view-progress.select-confirm-step',
    defaultMessage: 'Confirm & Pay',
    description: 'Confirm & Pay',
  },
  searchBarWhereLabel: {
    id: 'search-bar.where-label',
    defaultMessage: 'Where',
    description: 'Where',
  },

  searchBarWhenLabel: {
    id: 'search-bar.when-label',
    defaultMessage: 'When',
    description: 'When',
  },

  searchBarRoundTrip: {
    id: 'search-bar.round-trip',
    defaultMessage: 'Round-Trip?',
    description: 'Round-Trip',
  },

  searchBarGetTickets: {
    id: 'search-bar.get-tickets',
    defaultMessage: 'Get Tickets',
    description: 'Get Tickets',
  },

  searchBarWherePlaceholder: {
    id: 'search-bar.where-placeholder',
    defaultMessage: 'Choose route',
    description: 'Choose route',
  },
  searchBarWhenPlaceholder: {
    id: 'search-bar.when-placeholder',
    defaultMessage: 'Pick a date from calendar',
    description: 'Pick a date from calendar',
  },
  searchBarWhenTitle: {
    id: 'search-bar.title',
    defaultMessage: 'buy your ticket here:',
    description: 'buy your ticket here:',
  },
  changeDateLabel: {
    id: 'main-view.changeDate-label',
    defaultMessage: 'Change date',
    description: 'Change date',
  },
  changeDirectionLabel: {
    id: 'main-view.changeDirection-label',
    defaultMessage: 'Change direction',
    description: 'Change direction',
  },
  durationOnlyWithHours: {
    id: 'duration-only-with-hours',
    defaultMessage: 'Travel time {hours} hour(s)',
    description: 'Travel time {hours} hour(s)',
  },
  durationOnlyWithMinutes: {
    id: 'duration-only-with-minutes',
    defaultMessage: 'Travel time {minutes} minute(s)',
    description: 'Travel time {minutes} minute(s)',
  },
  durationWithHoursAndMinutes: {
    id: 'duration-with-hours-and-minutes',
    defaultMessage: 'Travel time {hours} hour(s) \n {minutes} minute(s)',
    description: 'Travel time {hours} hour(s) {minutes} minute(s)',
  },
  addReturnRouteLabel: {
    id: 'main-view.add-returnRoute-label',
    defaultMessage: 'Add return route',
    description: 'Add return route',
  },
  noMoreSailsTitle: {
    id: 'no-more-sails-title',
    defaultMessage: 'No more sails available on the selected date!',
    description: 'No more sails available on the selected date!',
  },
  noMoreSailsSubtitle: {
    id: 'no-more-sails-subtitle',
    defaultMessage: 'Choose a new available sail clicking on a specific date in the calendar',
    description: 'Choose a new available sail clicking on a specific date in the calendar',
  },
  availableCapacity: {
    id: 'available-capacity',
    defaultMessage: 'Available capacity',
    description: 'Available capacity',
  },
  clueAdmVehicleAndPassenger: {
    id: 'clue-adm-vehicle-and-passenger',
    defaultMessage:
      'Kindly note that the vehicle ticket does not provide travel for the driver or passengers. Please purchase individual tickets for both the driver and each passenger accompanying the vehicle.',
    description: 'Note',
  },
  outOfStockPart1: {
    id: 'choose-sail.out-of-stock-part1',
    defaultMessage: 'Pre-bookable capacity is sold out.',
    description: 'Pre-bookable capacity is sold out.',
  },
  goBackTicketLabel: {
    id: 'go-back-change-route',
    defaultMessage: 'Go back to change route',
    description: 'Go back to change route',
  },
  selectTicketLabel: {
    id: 'select-ticket-label',
    defaultMessage: 'Select Ticket',
    description: 'Select Ticket',
  },
  continueLabel: {
    id: 'continue-label',
    defaultMessage: 'continue to',
    description: 'continue to',
  },
  removeReturnRouteLabel: {
    id: 'remove-return-route-label',
    defaultMessage: 'cancel',
    description: 'cancel',
  },
  cookiesCollect: {
    id: 'cookie-banner-description',
    defaultMessage: '{url} website uses cookies. By continuing to use this website you agree to our to our',
    description: 'Collecting Cookies label',
  },
  cookiesAgreement: {
    id: 'agree-privacy-policy',
    defaultMessage: 'Agree',
    description: 'Agreement button',
  },
  privacyPolicy: {
    id: 'privacy-policy',
    defaultMessage: 'Privacy Policy',
    description: 'Privacy Policy',
  },
  bookingProcessErrorTitle: {
    id: 'bookingProcessError.title',
    defaultMessage: 'Warning!',
    description: 'Warning!',
  },
  bookingProcessError: {
    id: 'bookingProcessError',
    defaultMessage: 'An error occurred during the booking process, please try again later',
    description: 'An error occurred during the booking process, please try again later',
  },
  dangerousGoods: {
    id: 'note-about-dangerous-goods',
    defaultMessage: 'This sail is shipping Dangerous Goods',
    description: 'warning danger goods',
  },
  noHeavyTracks: {
    id: 'note-about-no-heavy-tracks',
    defaultMessage: 'Heavy trucks (2 tonnes and above) are restricted on this sail',
    description: 'warning no trucks',
  },
  noteAboutCancelled: {
    id: 'note-about-cancelled',
    defaultMessage: 'This sail was cancelled',
    description: 'warning cancelled sail',
  },
  goBackChangeRoute: {
    id: 'go-back-change-route',
    defaultMessage: 'Go back to change route',
    description: 'Go back to change route',
  },
  footerCopyright: {
    id: 'footer.copyright',
    defaultMessage: 'Blue Wave Corp. | Powered by  Bora Systems',
    description: 'Blue Wave Corp. | Powered by  Bora Systems',
  },
  ticketActionButtonsCancel: {
    id: 'ticket.action-buttons.cancel',
    defaultMessage: 'Cancel',
    description: 'Label for cancel action button',
  },
}
