import { FC, useState } from 'react'
import { useGetPageContent } from '@/root/shared-hooks/sales-static/usePages'
import type { UserManualData } from '@/root/shared-types'
import { Carousel } from '@/root/components/admaritime'
import Image from 'next/image'
import cn from 'classnames'

const OPTIMAL_WIDTH = 850
const MAX_HEIGHT = '478px'

export const DesktopUserManual: FC = () => {
  const [imagesLoaded, setImagesLoaded] = useState(false)
  const {
    pageContent: { page },
  } = useGetPageContent('userGuideModal', 'admaritime')
  const images = (page as UserManualData)?.images || []

  return (
    <div className={cn({ invisible: !imagesLoaded })}>
      <Carousel
        elementWidth={OPTIMAL_WIDTH}
        infinite={false}
        slides={images
          .map((image) => ({ ...image, width: OPTIMAL_WIDTH }))
          .map(({ url, height, alternativeText, width }) => {
            return (
              <div
                key={url}
                style={{
                  minWidth: `${width}px`,
                  maxWidth: `${width}px`,
                  maxHeight: MAX_HEIGHT,
                  minHeight: MAX_HEIGHT,
                  backgroundImage: "url('/admaritime/images/default-manual-image.png')",
                  backgroundSize: 'contain',
                }}
              >
                <Image
                  src={`/api${url}`}
                  alt={alternativeText}
                  height={height}
                  width={width}
                  quality="75"
                  loading="lazy"
                  onLoadingComplete={() => setImagesLoaded(true)}
                />
              </div>
            )
          })}
      />
    </div>
  )
}
