import { FC } from 'react'
import { News } from '@/root/shared-types'
import { Notifications } from '@/root/modules/admaritime'
import { ContentContainer } from '@/root/ui/admaritime'

interface NewsPageProps {
  news: News[]
}
export const NewsPage: FC<NewsPageProps> = ({ news }) => {
  return (
    <ContentContainer className="pt-28">
      <Notifications news={news} />
    </ContentContainer>
  )
}
