import { FC } from 'react'
import { BookTicketPart } from '@/root/modules/admaritime/BookTicket/components/BookTicketPart/BookTicketPart'
import { ChargesTable } from '@/root/modules/admaritime/BookTicket/components/ChargesTable/ChargesTable'
import { TicketProps } from '@/root/shared-types/reservation/reservation-types'
import { TermsAndConditions } from '@/root/modules/admaritime/BookTicket/components/TermsAndConditions/TermsAndConditions'
import { VehicleInfo } from '@/root/modules/admaritime/BookTicket/components/VehicleInfo/VehicleInfo'
import { PayButtons } from '@/root/modules/admaritime/BookTicket/components/PayButton/PayButtons'
import { useGetBookTicket } from '@/root/modules/admaritime/BookTicket/hooks/useGetBookTicket'
import { CustomerInfo } from '@/root/modules/admaritime/BookTicket/components/CustomerInfo/CustomerInfo'
import cn from 'classnames'
import { Divider } from '@/root/ui/shared/Divider/Divider'

export const BookTicket: FC<TicketProps> = (bookTicketProps) => {
  const {
    there,
    back,
    itemColumns,
    isRoundTrip,
    amount,
    amountFormatted,
    chargesTable,
    confirmationTotalText,
    terms,
    isAgree,
    onAgree,
    onPay,
    warningText,
    loading,
    customerInfoProps,
    isPaymentAvailable,
    isError,
    applePay,
    isApplePayAvailable,
  } = useGetBookTicket(bookTicketProps)

  const { privatePersonForm } = bookTicketProps

  const showInfoSection = privatePersonForm || there?.vehicles?.length === 1

  return (
    <div className="flex w-full flex-col items-center gap-10">
      <div className="border-base-very-light w-[95%] rounded md:border md:p-5">
        {showInfoSection && (
          <div className={cn('w-full', { 'flex flex-col md:grid md:grid-cols-2': there?.vehicles?.length === 1 })}>
            {privatePersonForm && <CustomerInfo {...customerInfoProps} />}
            {there?.vehicles?.length === 1 && <VehicleInfo vehicle={there.vehicles[0]} />}
          </div>
        )}
        <BookTicketPart {...there} columns={itemColumns} />
        {isRoundTrip && (
          <div className="relative">
            <Divider variant="light" />
            <BookTicketPart {...back} columns={itemColumns} />
          </div>
        )}
        <ChargesTable {...chargesTable} />
        <span className="flex w-full flex-col items-center gap-3 pt-10 text-2xl font-bold">
          <h1 className="text-2xl">
            {confirmationTotalText}
            <span>:</span>
          </h1>
          <p className="text-primary-base text-4xl">{amountFormatted}</p>
        </span>
      </div>
      <TermsAndConditions isAgree={isAgree} onAgree={onAgree} terms={terms} />
      {!isAgree && <h1 className="text-error text-center text-lg font-bold">{warningText.toUpperCase()}</h1>}

      <PayButtons
        amount={amount}
        disabled={!isPaymentAvailable || isError}
        onPay={() => onPay(amount, isPaymentAvailable)}
        isApplePayAvailable={isApplePayAvailable}
        loading={loading}
        applePay={applePay}
      />
    </div>
  )
}
