import { RouteImages, SailPackage, SailPackagesGroup } from '@/root/shared-types/reservation/reservation-types'
import { RouteCardProps, SailPackageForCard, SailPackagesGroups } from '@/root/shared-types'

interface AccumulatorType {
  taxi: RouteCardProps<SailPackagesGroups>[]
  ferry: RouteCardProps<SailPackagesGroups>[]
}
const findImageByRoute = (type: 'route' | 'sailPackage', code: string, routes: RouteImages[]) => {
  const imagePlaceHolder = {
    alternativeText: `Missing map for ${code}`,
    url: code,
    id: -1,
    height: 200,
    width: 200,
    name: code,
  }

  const desiredImage = routes?.find(({ routeCode, sailPackageCode }) =>
    type === 'route' ? routeCode === code : sailPackageCode === code
  )?.images[0]
  return desiredImage || imagePlaceHolder
}

const sortSailPackages = (sailPackages: SailPackage[], routes: RouteImages[]) =>
  sailPackages.reduce<SailPackagesGroups>(
    (total, current) => {
      const updatedPackage = {
        ...current,
        image: findImageByRoute('sailPackage', current.code, routes),
      } as SailPackageForCard
      if (current.fullday) {
        return {
          ...total,
          fullDay: [...total.fullDay, updatedPackage],
        }
      }
      if (current.roundtrip) {
        return {
          ...total,
          fullLoop: [...total.fullLoop, updatedPackage],
        }
      }
      return {
        ...total,
        semiLoop: [...total.semiLoop, updatedPackage],
      }
    },
    { semiLoop: [], fullDay: [], fullLoop: [] }
  )

export const useSortSailsPackages = (routes: RouteImages[], sailPackagesGroups: SailPackagesGroup[]) => {
  const sortedGroups = sailPackagesGroups.reduce<AccumulatorType>(
    (acc, current) => {
      if (current.taxi) {
        return {
          ...acc,
          taxi: [
            ...acc.taxi,
            {
              ...current,
              image: findImageByRoute('route', current.code, routes),
              sailPackages: sortSailPackages(current.sailPackages, routes),
            },
          ],
        }
      }
      return {
        ...acc,
        ferry: [
          ...acc.ferry,
          {
            ...current,
            image: findImageByRoute('route', current.code, routes),
            sailPackages: sortSailPackages(current.sailPackages, routes),
          },
        ],
      }
    },
    { taxi: [], ferry: [] }
  )

  return { data: sortedGroups }
}
