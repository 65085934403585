import { FC } from 'react'
import { News } from '@/root/shared-types'
import { Notification } from '@/root/modules/admaritime/NewsPage/components/Notification/Notification'
import { useGetTranslations } from '@/root/shared-hooks'

interface NotificationsProps {
  news: News[]
}
export const Notifications: FC<NotificationsProps> = ({ news }) => {
  const { getTranslation } = useGetTranslations()

  return (
    <div className="text-primary-base flex w-full flex-col items-center gap-14 p-10 pb-16 shadow-sm">
      <h1 className="text-2xl font-semibold">{getTranslation('newsPageTitle')}</h1>
      <div className="flex w-full flex-col-reverse gap-8">
        {news.map(({ body, id, published_at, subject, image }) => (
          <Notification title={subject} date={published_at} key={id} body={body} image={image} />
        ))}
      </div>
    </div>
  )
}
