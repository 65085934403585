import React, { FC } from 'react'
import { useCustomRouter, useGetTranslations } from '@/root/shared-hooks'
import { SailPackage } from '@/root/shared-types'
import { useGetRouteSearchBarProps } from '@/root/shared-hooks/useGetRouteSearchBarProps/useGetRouteSearchBarProps'
import { Button, Checkbox } from '@/root/ui/admaritime'
import { CellContent } from '@/root/modules/admaritime/RouteSearchBar/components/CellContent/CellContent'
import { Dropdown } from '@/root/components/admaritime'
import SearchBarCell from '@/root/modules/admaritime/RouteSearchBar/components/SearchBarCell/SearchBarCell'
import { SearchbarDayPickers } from '@/root/modules/admaritime/RouteSearchBar/components/SearchbarDayPickers/SearchbarDayPickers'

interface RouteSearchBarComponentProps {
  sailPackages: SailPackage[]
}
const RouteSearchBar: FC<RouteSearchBarComponentProps> = ({ sailPackages }) => {
  const { getTranslation } = useGetTranslations()

  const { locale } = useCustomRouter()

  const {
    handleDateChange,
    selectedDate,
    isRoundTripChecked,
    onRoundTripCheck,
    dropdownProps,
    selectedRoute,
    availableDates,
    onGoToSelectTicket,
    isDayPickerOpened,
    toggleDayPickers,
  } = useGetRouteSearchBarProps(sailPackages)

  return (
    <>
      <section className="flex w-full flex-col rounded-md bg-white lg:grid lg:min-h-20 lg:w-full lg:grid-cols-3 xl:grid-cols-[30%_30%_40%]">
        <SearchBarCell>
          <CellContent title={getTranslation('searchBarWhereLabel')}>
            <Dropdown
              {...dropdownProps}
              handleOptionSelection={(route) => {
                dropdownProps.handleOptionSelection(route)
                toggleDayPickers()
              }}
              className="ps-5"
            />
          </CellContent>
        </SearchBarCell>
        <SearchBarCell>
          <CellContent title={getTranslation('searchBarWhenLabel')}>
            <SearchbarDayPickers
              selectedDate={selectedDate}
              availableDates={availableDates}
              isRoundTrip={isRoundTripChecked}
              onDateChange={handleDateChange}
              titleTo={selectedRoute.name[locale]}
              titleFrom={selectedRoute.extraProps?.backwardsName && selectedRoute.extraProps?.backwardsName[locale]}
              isOpened={isDayPickerOpened}
              toggleDayPickers={toggleDayPickers}
            />
          </CellContent>
        </SearchBarCell>
        <SearchBarCell className="lg:justify-between">
          <Checkbox
            label={getTranslation('searchBarRoundTrip')}
            checked={isRoundTripChecked}
            variant="success"
            onChange={onRoundTripCheck}
            className="text-neutral-dark flex h-14 items-center gap-3 pt-0 text-lg font-bold uppercase lg:grid lg:h-fit lg:shrink-0 lg:grid-rows-[40px_minmax(40px,auto)] lg:gap-0 [&>*:last-child]:order-first lg:[&>*:last-child]:pt-1 rtl:lg:[&>*:last-child]:pe-4 ltr:[&>*:nth-child(2)]:pb-1"
            disabled={!selectedRoute.extraProps?.backwardsSailPackage}
          />
          <Button
            variant="contained"
            className="hidden min-h-20 rounded-none rounded-e-md border text-xl lg:block"
            disabled={Boolean(!selectedDate.to)}
            onClick={onGoToSelectTicket}
          >
            {getTranslation('searchBarGetTickets')}
          </Button>
        </SearchBarCell>
      </section>
      <Button
        variant="contained"
        className="h-fit w-full border text-xl lg:hidden"
        disabled={Boolean(!selectedDate.to)}
        onClick={onGoToSelectTicket}
      >
        {getTranslation('searchBarGetTickets').toUpperCase()}
      </Button>
    </>
  )
}

export default RouteSearchBar
