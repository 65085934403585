import { FC } from 'react'
import { FacebookIconBlue } from '@/root/ui/shared/icons/FacebookIconBlue'
import { GoogleIconColored } from '@/root/ui/shared/icons/GoogleIconColored'
import { AuthMethod } from '@/root/shared-types/ui/ui-types'
import { AuthButton, AuthMethodSelector, EmailForm, GrayLine } from '@/root/modules/admaritime/LoginPage'
import { useGetTranslations } from '@/root/shared-hooks'

interface AuthContentProps {
  authMethod: AuthMethod
  onAuthMethodChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const AuthContent: FC<AuthContentProps> = ({ authMethod, onAuthMethodChange }) => {
  const { getTranslation } = useGetTranslations()

  return (
    <>
      {authMethod === 'email' && <EmailForm />}

      {authMethod === 'facebook' && (
        <AuthButton>
          <FacebookIconBlue color="white" className="absolute start-3" />
          <span className="grow text-center">{getTranslation('authMethodFacebook')}</span>
        </AuthButton>
      )}

      {authMethod === 'google' && (
        <AuthButton>
          <GoogleIconColored color="white" className="absolute start-3 size-5" />
          <span className="grow text-center">{getTranslation('authMethodGoogle')}</span>
        </AuthButton>
      )}

      <p className="border-neutral-normal-lighter text-neutral w-full border-t pt-7 text-center text-base">
        {getTranslation('signInIntro')}
      </p>

      <AuthMethodSelector authMethod={authMethod} onAuthMethodChange={onAuthMethodChange} />

      <div className="text-neutral mt-5 grid w-full grid-cols-[1fr_auto_1fr] items-center gap-4 text-sm uppercase">
        <GrayLine />
        <span>{getTranslation('authOrLabel')}</span>
        <GrayLine />
      </div>
    </>
  )
}

export default AuthContent
