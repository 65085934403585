import { FC } from 'react'
import { useGetTranslations } from '@/root/shared-hooks'
import EditController from './components/EditController'
import { useEditable } from '@/root/shared-hooks/useEditable/useEditable'
import { useGetCustomerFields } from '@/root/shared-hooks'
import { CustomerFormField } from '@/root/components/admaritime'
import cn from 'classnames'
import CustomerDetailsHeader from './components/CustomerDetailsHeader'


const CustomerDetails: FC = () => {
  const { getTranslation } = useGetTranslations()
  const { isEditable, toggleEditable } = useEditable()
  const { fieldValuesContent, control, errors, trigger, fieldsIds, handleSave, handleCancel, isSaveDisabled } =
    useGetCustomerFields({
      fullName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      firstName: '',
    })

  const EditableFieldClass = cn('border-none', 'text-lg', 'text-base', {
    'drop-shadow-lg focus:outline-none rounded-none gap-2 pe-1': isEditable,
    'bg-white p-0 pe-0': !isEditable,
  })

  const handleToggleEdit = () => {
    if (isEditable) {
      handleCancel()
    }
    toggleEditable()
  }

  const handleSaveClick = () => {
    handleSave()
    toggleEditable()
  }

  return (
    <main className="min-h-36 w-full p-4 md:p-7">
      <div className="mb-5 flex flex-col align-middle">
        <CustomerDetailsHeader />
      </div>
      <form className="mt-3 flex w-full flex-col md:flex-row">
        <fieldset className="w-full min-w-0 border-0 p-0 md:w-3/4" disabled={!isEditable}>
          {fieldsIds.map((id) => (
            <CustomerFormField
              control={control}
              key={id}
              id={id}
              {...fieldValuesContent[id]}
              errors={errors}
              trigger={trigger}
              className={EditableFieldClass}
              isEditable={isEditable}
              maxWidth="full"
              textLeft="text-end"
              isCustomerDetailsProp={false}
            />
          ))}
        </fieldset>
        <div className="mt-4 w-full md:mt-0 md:w-1/4">
          <EditController
            label={getTranslation('customerInformationEdit')}
            onToggleEdit={handleToggleEdit}
            isEditing={isEditable}
            onSave={handleSaveClick}
            onCancel={handleCancel}
            isSaveDisabled={isSaveDisabled}
          />
        </div>
      </form>
    </main>
  )
}

export default CustomerDetails


