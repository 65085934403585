import { FC } from 'react'
import { dateToFormat } from '@/root/shared-helpers'
import { dateFormats } from '@/root/shared-consts'
import { ChevronDownIcon } from '@/root/ui/shared/icons/ChevronDownIcon'

interface RouteInfoProps {
  departurePort: string
  arrivalPort: string
  arrival: string
}

const RouteInfo: FC<RouteInfoProps> = ({ arrivalPort, arrival, departurePort }) => {
  const arrivalTime = dateToFormat(arrival, dateFormats.TIME_12)

  return (
    <div className="flex items-center justify-center gap-3 border-b py-4 text-xl font-bold uppercase md:items-start md:justify-start md:gap-3 md:text-2xl">
      <p className="text-center">{departurePort} </p>
      <ChevronDownIcon className="-rotate-90 md:mt-2 rtl:rotate-90" height={15} width={15} />
      <span className="flex flex-col text-center">
        <p>{arrivalPort}</p>
        <p className="hidden self-center md:block">{arrivalTime}</p>
      </span>
    </div>
  )
}

export default RouteInfo
