import { FC, useState } from 'react'
import { Icon, ListNumber } from '@/root/ui/admaritime'
import cn from 'classnames'
import { InstructionContent } from '@/root/modules/admaritime/UserManual/components/InstructionContent/InstructionContent'
import { InstructionProps } from '@/root/shared-types'

export const Instruction: FC<InstructionProps> = ({ index, instruction, title }) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <>
      <div className="flex w-full items-center gap-3">
        <ListNumber number={index} />
        <span className="flex w-full justify-between gap-3" onClick={() => setExpanded((expanded) => !expanded)}>
          <p className="w-fit max-w-[90%] pt-1 text-start text-lg">{title}</p>
          <Icon
            type="BottomArrow"
            alt="arrow"
            width={16}
            className={cn({ 'ltr:-rotate-90 rtl:rotate-90': !expanded })}
            ignoreRtl
          />
        </span>
      </div>
      {expanded && <InstructionContent title={instruction.title} images={instruction.images} />}
    </>
  )
}
