import { FC, HTMLAttributes } from 'react'
import cn from 'classnames'

export const RowContainer: FC<HTMLAttributes<HTMLDivElement>> = ({ children, className }) => (
  <div
    data-testid="passengers data"
    className={cn('border-base-very-light grid min-h-16 border-b py-5', className)}
  >
    {children}
  </div>
)
