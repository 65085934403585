import { FC } from 'react'
import { Instruction } from '@/root/modules/admaritime/UserManual/components/ListItem/Instruction'
import { InstructionProps } from '@/root/shared-types'

export interface SectionProps {
  title: string
  instructions: InstructionProps[]
}
export const Section: FC<SectionProps> = ({ title, instructions }) => {
  return (
    <section className="flex-col space-y-5 rounded-2xl bg-adm-layer-section p-5">
      <h1 className="w-fit whitespace-pre-line text-start text-[22px] text-adm-base-disable-button">{title}</h1>
      {instructions.map((instruction) => (
        <Instruction {...instruction} key={instruction.title} />
      ))}
    </section>
  )
}
