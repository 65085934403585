import { useState, useCallback } from 'react'

export const useEditable = () => {
  const [isEditable, setEditable] = useState(false)

  const toggleEditable = useCallback(() => {
    setEditable((edit) => !edit)
  }, [])

  return { isEditable, toggleEditable }
}
