import { useGetTranslations } from '@/root/shared-hooks'
import { ScheduleType } from '@/root/shared-types'

export const useGetTableHeader = (scheduleType: ScheduleType) => {
  const { getTranslation } = useGetTranslations()
  switch (scheduleType) {
    case 'taxi':
      return [
        { key: 'Departure', label: getTranslation('schedulePageTableDeparture') },
        { key: 'FirstDeparture', label: getTranslation('schedulePageTableFirstDeparture') },
        { key: 'LastDeparture', label: getTranslation('schedulePageLastDeparture') },
      ]
    case 'ferry':
      return [
        { key: 'Departure', label: getTranslation('schedulePageTableDeparture') },
        { key: 'DepartureTime', label: getTranslation('schedulePageTableDepartureTime') },
        { key: 'ArrivalTime', label: getTranslation('schedulePageTableArrivalTime') },
        { key: 'Days', label: getTranslation('schedulePageTableDays') },
      ]
  }
}
