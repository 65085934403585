import { ButtonWithIcon } from '@/root/components/admaritime'
import { FC } from 'react'

import { useActionsButtonHandlers } from '@/root/shared-hooks/reservation/useActionsButtonHandlers'
import { useGetTranslations } from '@/root/shared-hooks'

interface TicketActionButtonsProps {
  reservationId: number
  reservationToken: string
  className?: string
}

const TicketActionButtons: FC<TicketActionButtonsProps> = ({ className, reservationId, reservationToken }) => {
  const { getTranslation } = useGetTranslations()

  const { onDownload, onPrint, onCancel, iframeProps } = useActionsButtonHandlers(reservationId, reservationToken)

  return (
    <div className={`flex justify-around ${className}`}>
      <ButtonWithIcon iconType="Download" label={getTranslation('ticketActionButtonsDownload')} onClick={onDownload} />
      <ButtonWithIcon iconType="Print" label={getTranslation('ticketActionButtonsPrint')} onClick={onPrint} />
      <ButtonWithIcon
        className="text-error"
        iconType="CrossRed"
        label={getTranslation('ticketActionButtonsCancel')}
        onClick={onCancel}
      />
      <iframe {...iframeProps} />
    </div>
  )
}

export default TicketActionButtons
