import Link from 'next/link'
import { FC } from 'react'
import cn from 'classnames'
import { Icon } from '@/root/ui/admaritime'
import { FooterLinkAndInfoContentItem } from '@/root/shared-types'

interface LinksAndInfoItemProps {
  items: FooterLinkAndInfoContentItem[]
  icon?: string
  useOldBoraOrigin?: boolean
  title?: string
}

const LinksAndInfoItem: FC<LinksAndInfoItemProps> = ({ items, title, icon }) => {
  return (
    <div className="z-10 text-white">
      {title && <h6 className="mb-3 text-xl font-bold md:mb-7">{title}</h6>}
      {icon && (
        <div className="mb-3 w-fit md:mb-7">
          <Icon alt={icon} type={icon} width={32} height={32} />
        </div>
      )}

      {items.map(({ title, link, decoration, linkType, withBottomMargin }) => {
        if (title instanceof Array) {
          const [label, linkValueFromTitle] = title
          return (
            <div key={label} className="mb-1 flex justify-between">
              <span>{label}</span>
              {linkType && (
                <Link href={`${linkType}:${linkValueFromTitle}`} className="darken font-bold" dir="ltr">
                  {linkValueFromTitle}
                </Link>
              )}
              {link && (
                <Link href={link} target={link.startsWith('https:') ? '_blank' : '_self'} className="darken">
                  {linkValueFromTitle}
                </Link>
              )}
            </div>
          )
        }

        return (
          <div
            key={`${title}${withBottomMargin ? '-second' : ''}`}
            className={cn(
              {
                'font-bold': decoration === 'bold',
                'mb-5': withBottomMargin,
                darken: link,
              },
              'mb-1'
            )}
          >
            {link ? (
              <Link href={link} target={link.startsWith('https:') ? '_blank' : '_self'}>
                {title}
              </Link>
            ) : (
              title
            )}
          </div>
        )
      })}
    </div>
  )
}

export default LinksAndInfoItem
