import { FC } from 'react'
import { useGetProfilePopUpProps } from '@/root/components/admaritime/ProfilePopUp/hooks/useGetProfilePopUpProps'
import { CustomerFormField, Dropdown, RadioInput } from '@/root/components/admaritime'
import { Customer, Group, Member } from '@/root/shared-types'
import { useGetTranslations } from '@/root/shared-hooks'
import { CrossIcon } from '@/root/ui/shared/icons/CrossIcon'
import { Loader } from '@/root/ui/shared'
import { ActionButton } from '@/root/components/admaritime/ProfilePopUp/components/ActionButton'

type AddToGroupPopUp = { type: 'addToGroup'; customerId: number; groups: Group[]; customer?: Customer }
type AddNewGroupPopUp = { type: 'addNewGroup' | 'editGroup'; customers: Customer[]; members?: Member[]; group?: Group }
type AddNewPassengerPopUp = { type: 'addNewPassenger' | 'editPassenger'; groups: Group[]; customer?: Customer }

export const ProfilePopUp: FC<AddToGroupPopUp | AddNewGroupPopUp | AddNewPassengerPopUp> = (props) => {
  const { getTranslation } = useGetTranslations()
  const popUpProps = useGetProfilePopUpProps(props)

  const { type } = props

  if (type === 'addToGroup' && popUpProps) {
    const { fieldsIds, control, errors, trigger, fieldValuesContent, dropdownProps, title, disabled, isLoading } =
      popUpProps
    return (
      <div className="flex h-fit w-[600px] flex-col items-start gap-5">
        <span className="flex w-full justify-between border-b pb-6">
          <h1 className="text-lg font-bold text-adm-blue-800">{title}</h1>
        </span>
        {fieldsIds &&
          fieldValuesContent &&
          fieldsIds.map((id) => (
            <CustomerFormField
              control={control}
              key={id}
              id={id}
              {...fieldValuesContent[id]}
              errors={errors}
              trigger={trigger}
            />
          ))}
        <p>{getTranslation('profileAddToGroupPopUpBelongsToGroups')}</p>
        <span className="w-full border-b border-adm-gray-550 pb-5">
          {<Dropdown {...dropdownProps} disableFilter />}
          <p className="text-adm-gray-550">{getTranslation('profileAddToGroupPopUpHintText')}</p>
        </span>
        <ActionButton
          onClick={popUpProps.onClick}
          dataTestId="passenger-data-CE-button"
          disabled={isLoading || disabled}
        >
          {isLoading ? <Loader /> : getTranslation('profileAddToGroupPopUpButtonText')}
        </ActionButton>
      </div>
    )
  }
  if (
    type === 'addNewGroup' ||
    type === 'editGroup' ||
    ((type === 'addNewPassenger' || type === 'editPassenger') && popUpProps)
  ) {
    const { title, fieldsIds, fieldValuesContent, control, errors, trigger, dropdownProps } = popUpProps
    const isGroupPopup = type === 'addNewGroup' || type === 'editGroup'
    return (
      <div className="flex h-fit w-[600px] flex-col items-start gap-5 ">
        <span className="flex w-full justify-between border-b pb-6">
          <h1 className="text-lg font-bold text-adm-blue-800">{title}</h1>
        </span>
        {fieldsIds &&
          fieldValuesContent &&
          fieldsIds.map((id) => {
            if (fieldValuesContent[id].type === 'radio') {
              return (
                <RadioInput
                  control={control}
                  key={id}
                  options={[
                    { id: 'M', name: getTranslation('genderMale') },
                    { id: 'F', name: getTranslation('genderFemale') },
                  ]}
                  label={fieldValuesContent[id].label}
                  id={id}
                />
              )
            }
            return (
              <CustomerFormField
                control={control}
                key={id}
                id={id}
                {...fieldValuesContent[id]}
                errors={errors}
                trigger={trigger}
                maxWidth="full"
              />
            )
          })}

        <span className="flex w-full flex-col gap-3 pb-5">
          <p>
            {type === 'addNewGroup'
              ? getTranslation('profileAddNewGroupPopUpAddPassenger')
              : getTranslation('profileAddToGroupPopUpBelongsToGroups')}
          </p>
          {
            <Dropdown
              {...dropdownProps}
              displaySelectedOptions={!isGroupPopup}
              disableFilter
              placeHolder={type === 'addNewGroup' ? getTranslation('profileAddNewGroupPlaceholder') : ''}
            />
          }
        </span>

        {isGroupPopup && (
          <div className="w-full">
            {dropdownProps.selectedOptions?.map((option) => (
              <div
                key={option.id}
                className="text-primary-base flex w-full justify-between border-b px-2 py-3 font-bold first:border-t"
              >
                <p>{option.name['en']}</p>
                <div onClick={() => dropdownProps.handleOptionSelection(option)} className="cursor-pointer">
                  <CrossIcon />
                </div>
              </div>
            ))}
          </div>
        )}
        <ActionButton
          onClick={popUpProps.onClick}
          dataTestId="passenger-data-CE-button"
          disabled={popUpProps.isLoading || popUpProps.disabled}
        >
          {popUpProps.isLoading ? <Loader /> : getTranslation('profileAddToGroupPopUpButtonText')}
        </ActionButton>
      </div>
    )
  }
}
