import { FC, useEffect, useState } from 'react'
import { Banner, Button, InputField } from '@/root/ui/admaritime'
import { useGetTranslations } from '@/root/shared-hooks'
import { useForm, FieldValues } from 'react-hook-form'
import { isValidEmailOrPhoneNumber } from '@/root/shared-helpers'
import { timers } from '@/root/shared-consts'
import * as R from 'ramda'

interface RecoveryEmailFormProps {
  setIsEmailSent: (value: boolean) => void
}

export const RecoveryEmailForm: FC<RecoveryEmailFormProps> = ({ setIsEmailSent }) => {
  const { getTranslation } = useGetTranslations()

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm<FieldValues>({
    defaultValues: {
      login: '',
    },
    mode: 'all',
  })

  const [isSubmitted, setIsSubmitted] = useState(false)
  const loginValue = watch('login')

  const isResponseFromBESuccess = true //todo: Must be changed !!!
  const bannerBody = isResponseFromBESuccess ? getTranslation('recoveryLinkSentTitle') : getTranslation('userNotExist')
  const bannerVariant = isResponseFromBESuccess ? 'success' : 'error'

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (isSubmitted) {
      timer = setTimeout(() => {
        setIsSubmitted(false)
        setIsEmailSent(true)
      }, timers.SUBMIT_TIMEOUT_MS)
    }
    return () => clearTimeout(timer)
  }, [isSubmitted, setIsEmailSent])

  const onSubmit = (data: FieldValues) => {
    R.identity(data)
    setIsSubmitted(true)
  }

  const isDisabled = R.any(Boolean, [isSubmitted, errors.login, !loginValue])

  return (
    <>
      {isSubmitted && <Banner variant={bannerVariant} body={bannerBody} bodyClassName="!text-base" />}

      <form className="flex w-full flex-col" onSubmit={handleSubmit(onSubmit)}>
        <InputField
          type="text"
          placeholder={getTranslation('recoveryMailPlaceholder')}
          register={register}
          name="login"
          value={loginValue}
          validate={(value) => isValidEmailOrPhoneNumber(value) || getTranslation('invalidFormatText')}
          errors={errors}
        />

        <Button
          className="mt-5 flex w-full justify-center text-lg uppercase drop-shadow-lg"
          disabled={isDisabled}
          variant="contained"
          type="submit"
        >
          {getTranslation('recoverySendButton')}
        </Button>
      </form>
    </>
  )
}

export default RecoveryEmailForm
