import { ArrowLong } from '@/root/ui/admaritime'
import { FC } from 'react'

export const RouteInfo: FC<{ routeInfo: { portFrom: string; portTo: string } }> = ({ routeInfo }) => (
  <span className="flex flex-col gap-5">
    <p className="text-xl font-bold text-primary-base">{routeInfo.portFrom}</p>
    <div className="w-fit rotate-90">
      <ArrowLong color="base-normal-lighter" ignoreRtl />
    </div>
    <p className="text-xl font-bold text-primary-base">{routeInfo.portTo}</p>
  </span>
)
