import { FC } from 'react'
import FooterLinksAndInfo from '../FooterLinksAndInfo/FooterLinksAndInfo'
import FooterSocial from '../FooterSocial/FooterSocial'
import { useFooterItems, useMenuItems, useStaticContent } from '@/root/shared-hooks'
import { getMenuItemsByLine } from '../../helpers/getMenuItemsByLine'

const Footer: FC = () => {
  const { staticFooterItems, staticMenuItems } = useStaticContent()

  const { footerItems } = useFooterItems()
  const { menuItems } = useMenuItems()

  const { lower = [], social = [], upper = [], linkToGroup = [] } = staticFooterItems || footerItems || {}
  const footerMenuItems = getMenuItemsByLine(staticMenuItems || menuItems, 3)

  return (
    <footer>
      <FooterLinksAndInfo upperFooterItems={upper} lowerFooterItems={lower} />
      <FooterSocial socialItems={social} menuItems={footerMenuItems} linkToGroupItems={linkToGroup} />
    </footer>
  )
}

export default Footer
