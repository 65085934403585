import axios from 'axios'
import { useSWRConfig } from 'swr'

interface CreateFamilyProps {
  type: 'FAMILY'
  legalName: string
  companyId?: number
  customerIds?: number[]
}

interface AddToFamilyProps {
  companyId: number
  customersIds: number[]
}

interface CreatePassengerProps {
  firstName: string
  lastName: string
  birthday: string
  gender: 'M' | 'F'
  companyIds: string[]
}

export const useCreateOrEditFamily = () => {
  const { mutate } = useSWRConfig()

  const mutatePassengersAndGroups = () => {
    mutate('passengersData')
  }

  const createFamily = async (body: CreateFamilyProps) => {
    await axios({
      url: `/api/user/create-or-edit/company`,
      data: body,
      method: 'put',
    })
    mutatePassengersAndGroups()
  }

  const createPassenger = async (body: CreatePassengerProps) => {
    await axios({
      url: `/api/user/create-or-edit/customer`,
      data: body,
      method: 'put',
    })
    mutatePassengersAndGroups()
  }
  const addToFamily = async ({ customersIds, companyId }: AddToFamilyProps) => {
    await axios({
      url: `/api/user/${companyId}/${customersIds.join(',')}`,
      method: 'put',
    })
    mutatePassengersAndGroups()
  }

  const deleteFamily = async (id: number) => {
    await axios({
      url: `/api/user/delete-company-or-customer`,
      method: 'delete',
      data: {
        id,
        type: 'company',
      },
    })
    mutatePassengersAndGroups()
  }
  const deletePassenger = async (id: number) => {
    await axios({
      url: `/api/user/delete-company-or-customer`,
      method: 'delete',
      data: {
        id,
        type: 'customer',
      },
    })
    mutatePassengersAndGroups()
  }
  return { createFamily, createPassenger, addToFamily, deleteFamily, deletePassenger }
}
