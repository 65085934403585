import RefundRouteInfo from '../RefundRouteInfo/RefundRouteInfo'
import { RefundAmountInfo } from '@/root/modules/admaritime/RefundCard/components/RefundAmountInfo/RefundAmountInfo'
import { RefundAgreement } from '@/root/modules/admaritime/RefundCard/components/RefundAgreement/RefundAgreement'
import { RefundProps } from '@/root/shared-types'
import { ActionButtons } from '@/root/modules/admaritime/RefundCard/components/ActionButtons/ActionButtons'
import { FC } from 'react'

const RefundCard: FC<RefundProps> = ({
  refundRouteInfoPropsThere,
  refundRouteInfoPropsBack,
  refundAmountInfoProps,
  refundAgreementProps,
  actionButtonProps,
  isRoundTrip,
}) => {
  return (
    <div className="border-base-very-light rounded-xl py-8 md:border md:px-10">
      <div className="flex flex-col gap-6 pb-6 md:grid md:grid-cols-2">
        <RefundRouteInfo {...refundRouteInfoPropsThere} />
        {isRoundTrip && (
          <RefundRouteInfo
            className="border-neutral-normal-light border-t pt-5 md:border-s md:border-t-0 md:ps-5 md:pt-0"
            {...refundRouteInfoPropsBack}
          />
        )}
      </div>
      <div className="border-base-very-light flex flex-col gap-6 border-t py-7 md:grid md:grid-cols-2 md:gap-7">
        <RefundAmountInfo {...refundAmountInfoProps} />
        <RefundAgreement {...refundAgreementProps} />
      </div>
      <ActionButtons {...actionButtonProps} />
    </div>
  )
}

export default RefundCard
