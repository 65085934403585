import { useContext } from 'react'
import { AuthContextTokenSetter } from '@/root/shared-providers/AuthContextProvider/AuthContext'

export const useSetAuthToken = () => {
  const setToken = useContext(AuthContextTokenSetter)

  const saveToken = (token: string | null) => {
    if (token) {
      document.cookie = `AuthJWT=${token}; path=/;`
    } else {
      document.cookie = 'AuthJWT=; path=/;'
    }
    setToken(token)
  }

  const resetToken = () => {
    document.cookie = 'AuthJWT=; path=/;'
  }

  return { saveToken, resetToken }
}
