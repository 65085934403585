import { Vehicle } from '@/root/shared-types'
import { useGetTranslations } from '@/root/shared-hooks'
import * as R from 'ramda'

export const useGetVehicleInfo = ({ lengthInCm, heightInCm, weightInKg, widthInCm, licencePlate }: Vehicle) => {
  const { getTranslation } = useGetTranslations()

  const length = `${lengthInCm / 100}m`
  const weight = `${weightInKg / 1000}t`

  const getHeightLabel = R.cond([
    [R.lt(R.__, 400), R.always(getTranslation('confirmationCarHeightSmall'))],
    [R.T, R.always(getTranslation('confirmationCarHeightLarge'))],
  ])

  const getWidthLabel = R.cond([
    [R.lt(R.__, 200), R.always(getTranslation('confirmationCarWidthSmall'))],
    [R.lt(R.__, 260), R.always(getTranslation('confirmationCarWidthMedium'))],
    [R.T, R.always(getTranslation('confirmationCarWidthLarge'))],
  ])

  return { licencePlate, length, weight, height: getHeightLabel(heightInCm), width: getWidthLabel(widthInCm) }
}
