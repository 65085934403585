export const addToGroupFields = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  GENDER: 'gender',
  DATE_OF_BIRTH: 'birthday',
}

export const addNewGroup = {
  GROUP_NAME: 'groupName',
}
export const PopUpVariants = {
  ADD_TO_GROUP: 'addToGroup',
  ADD_NEW_GROUP: 'addNewGroup',
  ADD_NEW_PASSENGER: 'addNewPassenger',
}
