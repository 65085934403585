import { FC } from 'react'
import { TableHeader } from '@/root/components/admaritime'
import { TableCell } from '@/root/modules/admaritime/SchedulePage/components/TableCell/TableCell'
import { RouteInfo } from '@/root/modules/admaritime/SchedulePage/components/RouteInfo/RouteInfo'
import { Schedule } from '@/root/shared-types/sales-static/sales-static-types'

interface FerryTableProps extends Schedule {
  cols: { key: string; label: string }[]
}

export const FerryTable: FC<FerryTableProps> = ({ cols, options, direction }) => (
  <div>
    <TableHeader cols={cols} className="grid grid-cols-4" />
    <div className="flex w-full">
      <TableCell className="flex w-1/4 border-b border-s">
        <RouteInfo direction={direction} />
      </TableCell>
      <div className="w-3/4">
        {options.map((opt) => (
          <div key={opt.title} className="flex">
            <div className="flex w-2/3 flex-col">
              {opt.times.map((time) => (
                <div key={time[0]} className="grid h-16 grid-cols-2">
                  <TableCell>{time[0]}</TableCell>
                  <TableCell> {time[1]}</TableCell>
                </div>
              ))}
            </div>
            <TableCell className="w-1/3 border-e">{opt.title}</TableCell>
          </div>
        ))}
      </div>
    </div>
  </div>
)
