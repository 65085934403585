import { reservationService } from '@/root/shared-api'
import useSWR from 'swr/immutable'
import { Reservation } from '@/root/shared-types'
import { useCustomRouter } from '@/root/shared-hooks'

export function useReservation() {
  const {
    query: { reservationToken },
    isReady,
    locale,
  } = useCustomRouter()

  const { data, error, isLoading } = useSWR<Reservation>(isReady && `/reservation/${reservationToken}/${locale}`, () =>
    reservationService.getReservation(reservationToken as string, locale)
  )

  return {
    reservation: data,
    error,
    isLoading,
  }
}
