import { useEffect, useRef } from 'react'

const useScrollToRef = (dependency: string | null) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const mql = window.matchMedia('(max-width: 600px)')

    if (mql.matches && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [dependency])

  return { ref }
}

export default useScrollToRef
