import useSWR from 'swr/immutable'
import axios from 'axios'
import { ConfirmationTerm } from '@/root/shared-types/reservation/reservation-types'

export function useGetConfirmationTerms(locale: string) {
  const fetchConfirmationTerms = async () => {
    try {
      const { data } = await axios.get<ConfirmationTerm[]>('/api/sales-static/confirmation-terms', {
        params: { _locale: locale },
      })
      return data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.response?.data || error.message)
      } else {
        console.error('Unknown error:', error)
      }
      throw error
    }
  }

  const { data, error, isLoading } = useSWR<ConfirmationTerm[]>(
    `/api/sales-static/confirmation-terms?locale=${locale}`,
    fetchConfirmationTerms
  )
  return {
    confirmationTerms: data,
    isLoading,
    isError: !!error,
  }
}
