import { CarIcon } from '@/root/ui/shared/icons/CarIcon'
import { CloseIcon } from '@/root/ui/shared/icons/CloseIcon'
import { PassengerIcon } from '@/root/ui/shared/icons/PassengerIcon'
import { FC } from 'react'

interface INumOfTicketItemsProps {
  number: number
  iconType: 'car' | 'passenger'
  width?: number
}

const NumberOfTicketItems: FC<INumOfTicketItemsProps> = ({ iconType, number }) => {
  if (!number) {
    return null
  }

  return (
    <div className="flex items-center gap-3 px-4">
      <div>{iconType === 'car' ? <CarIcon width={41} color="black" /> : <PassengerIcon width={15} />}</div>
      <CloseIcon width={13} />
      <div className="mt-1 text-large font-bold">{number}</div>
    </div>
  )
}

export default NumberOfTicketItems
