import { Button } from '@/root/ui/admaritime'
import { SubmitButtonContent } from '@/root/modules/admaritime/BookTicket/components/SubmitButtonContent/SubmitButtonContent'
import { FC } from 'react'
import { PayWithCardButtonContent } from '@/root/modules/admaritime/BookTicket/components/PayWithCardButtonContent/PayWithCardButtonContent'
import { ApplePayButton } from '@/root/modules/admaritime/BookTicket/components/ApplePayButton/ApplePayButton'
import { Loader } from '@/root/ui/shared'

interface PayButtonProps {
  amount: number
  applePay: any
  disabled?: boolean
  isApplePayAvailable?: boolean
  onPay?(): void
  loading?: boolean
}

const buttonContentDefiner = (amount: number) => {
  if (amount === 0) {
    return <SubmitButtonContent />
  }
  return <PayWithCardButtonContent />
}

export const PayButtons: FC<PayButtonProps> = ({ onPay, applePay, amount, disabled, isApplePayAvailable, loading }) => {
  if (loading) {
    return <Loader />
  }
  return (
    <span className="flex flex-col gap-3 md:flex-row">
      {isApplePayAvailable && <ApplePayButton disabled={disabled} onClick={applePay} />}
      <Button variant="contained" disabled={disabled} className="w-96 !px-0" onClick={onPay}>
        {buttonContentDefiner(amount)}
      </Button>
    </span>
  )
}
