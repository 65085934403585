import React, { FC, HTMLAttributes, useState } from 'react'

interface ToolTipProps extends HTMLAttributes<HTMLDivElement> {
  title: string
  body: string
}

const Tooltip: FC<ToolTipProps> = ({ title, body, children }) => {
  const [visible, setVisible] = useState(false)

  return (
    <div
      className="relative inline-block"
      onClick={() => setVisible((prev) => !prev)}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      {children}
      {visible && (
        <div className="bg-gray-800 absolute -start-14 z-10 mt-5 flex w-72 flex-col gap-2 rounded bg-white p-4 pt-2 text-base shadow-sm md:top-full md:-mt-5 md:ms-10">
          <h1 className="border-b border-neutral-normal-lighter pb-1 text-sm font-bold">{title}</h1>
          <p className="text-small leading-5">{body}</p>
        </div>
      )}
    </div>
  )
}

export default Tooltip
