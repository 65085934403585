import React, { FC } from 'react'

interface LinkButtonProps {
  title: string
  onClick: React.MouseEventHandler<HTMLButtonElement>
}
export const LinkButton: FC<LinkButtonProps> = ({ title, onClick }) => {
  return (
    <button className="h-[58px] rounded bg-white text-lg text-primary-base" onClick={onClick}>
      {title}
    </button>
  )
}
