import { useApplePay, useCustomRouter, useGetCustomerFields, useGetTranslations } from '@/root/shared-hooks'
import { useAppConfig } from '@/root/shared-hooks/appConfig/useAppConfig'
import { useGetConfirmationTerms } from '@/root/shared-hooks/sales-static/useGetConfirmationTerms'
import { useEffect, useState } from 'react'
import { TicketData } from '@/root/shared-types/reservation/reservation-types'
import { paymentMethods } from '@/root/shared-consts'
import { reservationService } from '@/root/shared-api'
import { PaymentPayload, PreparePaymentRequestBody } from '@/root/shared-types'
import { useErrorBanner } from '@/root/shared-hooks/useErrorBanner/useErrorBanner'

interface useGetBookTicketProps {
  ticketData: TicketData
}

export const useGetBookTicket = ({
  ticketData: { amount, there, back, isRoundTrip, charges, route, reservationId, customerInfo },
}: useGetBookTicketProps) => {
  const { getTranslation } = useGetTranslations()
  const { formatMoney } = useAppConfig()
  const { locale } = useCustomRouter()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const { isApplePayAvailable, applePay, getPayload } = useApplePay({ reservationId })
  const [payload, setPayload] = useState<PaymentPayload>()

  useEffect(() => {
    if (isApplePayAvailable && !payload) {
      getPayload().then(setPayload)
    }
  }, [isApplePayAvailable])

  const customerInfoProps = useGetCustomerFields(customerInfo)

  const { confirmationTerms } = useGetConfirmationTerms(locale)

  const terms = confirmationTerms?.find((term) => term.sailpackage === route)?.body

  const [isAgree, setIsAgree] = useState(false)
  const { errorHandler } = useErrorBanner()

  const isFieldsFilledCorrect =
    Object.values(customerInfoProps.getValues()).every((value) => value !== '') &&
    Object.keys(customerInfoProps.errors).length === 0

  const isPaymentAvailable = isAgree && isFieldsFilledCorrect

  const values = customerInfoProps.getValues()

  const ownerData = {
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    phone: {
      intlCode: values.code.replace('+', ''),
      phoneNumber: values.phoneNumber,
    },
  }

  const saveOwner = async () => {
    try {
      await reservationService.postReservationOwner(reservationId, ownerData)
    } catch (error) {
      if (errorHandler) {
        errorHandler({ error, time: new Date(), message: getTranslation('bookingProcessError') })
        setIsLoading(false)
        setIsError(true)
      }
    }
  }

  const onBlur = async () => {
    if (isFieldsFilledCorrect) {
      await saveOwner()
    }
  }

  const onPay = async (amount: number, isPaymentAvailable: boolean) => {
    if (isPaymentAvailable) {
      const requestBody: PreparePaymentRequestBody = {
        reservationId,
        paymentMethod: amount === 0 ? paymentMethods.NONE : paymentMethods.ECOMMERCE,
        origin: window.origin,
        creditCard: false,
        locale: locale,
      }
      setIsLoading(true)
      await saveOwner()

      try {
        await reservationService.preparePayment(requestBody)
      } catch (error) {
        if (errorHandler) {
          errorHandler({ error, time: new Date(), message: getTranslation('bookingProcessError') })
          setIsLoading(false)
          setIsError(true)
        }
      }
    }
  }

  const commonColumns = [
    getTranslation('confirmationQty'),
    getTranslation('confirmationPrice'),
    getTranslation('confirmationVAT'),
    getTranslation('confirmationSubtotal'),
  ]

  const itemColumns = [getTranslation('confirmationTicketType'), ...commonColumns]
  const chargesColumns = [getTranslation('confirmationDescription'), ...commonColumns]

  return {
    there,
    back,
    itemColumns,
    isRoundTrip,
    amount,
    amountFormatted: formatMoney(amount),
    chargesTable: {
      ...charges,
      description: getTranslation('confirmationOnlineChargesLabel'),
      columns: chargesColumns,
      isRoundTrip: isRoundTrip,
    },
    confirmationTotalText: getTranslation('confirmationTotal').toUpperCase(),
    terms,
    isAgree,
    onAgree: () => setIsAgree((prev) => !prev),
    onPay,
    applePay: async () => {
      if (payload) {
        await applePay(payload)
      }
    },
    isPaymentAvailable,
    isApplePayAvailable,
    warningText: getTranslation('confirmationPaymentWarning'),
    loading: isLoading,
    customerInfo,
    customerInfoProps: { ...customerInfoProps, onBlur },
    isError,
  }
}
