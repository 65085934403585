import Image from 'next/image'
import { BoraTheme } from '@/root/shared-types'
interface LogoProps {
  clientName: BoraTheme
  width: number
  height: number
  logo: string
  altText?: string
}
const ClientLogo = ({ clientName, width, height, logo, altText }: LogoProps) => {
  return (
    <Image
      alt={altText || `${clientName} logo`}
      src={`/${clientName}/images/${logo}.svg`}
      width={width}
      height={height}
      priority={true}
    />
  )
}

export default ClientLogo
