import { FC, HTMLAttributes } from 'react'
import cn from 'classnames'

export const RowContainer: FC<HTMLAttributes<HTMLDivElement>> = ({ children, className }) => (
  <div
    className={cn(
      'flex flex-col items-center border-b gap-5 md:gap-0 py-5 md:py-1 md:grid md:grid-cols-[30%_40%_30%]',
      className
    )}
  >
    {children}
  </div>
)
