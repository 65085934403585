import { useContext } from 'react'
import { SailPackageContext } from '@/root/shared-providers'

export const useGetSailPackage = () => {
  const context = useContext(SailPackageContext)

  return (
    context || {
      sailPackage: null,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setSailPackage: () => {},
    }
  )
}
