import { FC, LabelHTMLAttributes } from 'react'
import cn from 'classnames'

export const AuthMethodSelectorLabel: FC<LabelHTMLAttributes<HTMLLabelElement>> = ({
  children,
  className,
  ...rest
}) => (
  <label className={cn('flex cursor-pointer flex-col items-center gap-0.5', className)} {...rest}>
    {children}
  </label>
)

export default AuthMethodSelectorLabel
