import { useDrawer } from '@/root/shared-hooks/useDrawer/useDrawer'
import { DesktopUserManual, UserManual } from '@/root/modules/admaritime'
import { useModal } from '@/root/shared-hooks'

export const useOpenUserManual = () => {
  const { setDrawerBody, onResize: onMobileManualToggle } = useDrawer()
  const { setModalBody } = useModal()
  const onManualOpen = () => {
    if (window.innerWidth <= 800) {
      return setDrawerBody(<UserManual onClose={onMobileManualToggle} />)
    }
    setModalBody(<DesktopUserManual />)
  }

  return { onManualOpen }
}
