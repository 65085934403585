import { FC } from 'react'
import { Time } from '@/root/shared-types'

interface TaxiTableTimesProps {
  times: Time[]
}

export const TaxiTableTimes: FC<TaxiTableTimesProps> = ({ times }) => (
  <div className="flex h-full flex-col justify-center">
    {times.map((time) => (
      <span key={time[0]} className="grid grid-cols-2">
        {time[0].split('-').map((el) => (
          <p key={el} className="flex h-20 items-center justify-center">
            {el}
          </p>
        ))}
      </span>
    ))}
  </div>
)
