import { FC, useCallback, useEffect, useState } from 'react'
import { LoginPageTab } from '@/root/shared-types/ui/ui-types'
import { ForgotPasswordTab, SignTab, TabSelector } from '@/root/modules/admaritime/LoginPage'
import { APP_ROUTES } from '@/root/shared-consts'
import { useCustomRouter } from '@/root/shared-hooks'

export const LoginPage: FC = () => {
  const router = useCustomRouter()
  const { tab } = router.query

  const getDefaultTab = useCallback((): LoginPageTab => {
    if (tab === 'signup' || tab === 'login' || tab === 'recovery') {
      return tab as LoginPageTab
    }
    return 'signup'
  }, [tab])

  const [selectedTab, setSelectedTab] = useState<LoginPageTab>(getDefaultTab())

  useEffect(() => {
    if (tab !== selectedTab) {
      setSelectedTab(getDefaultTab())
    }
  }, [getDefaultTab, selectedTab, tab])

  const handleTabChange = (newTab: LoginPageTab) => {
    setSelectedTab(newTab)
    router.push(APP_ROUTES[newTab], undefined, { shallow: true })
  }

  return (
    <div className="grid w-full max-w-[760px] grid-cols-[minmax(60px,_auto)_minmax(auto,_535px)] sm:grid-cols-[30%_70%]">
      <TabSelector selectedTab={selectedTab} setSelectedTab={handleTabChange} />

      <div className="flex w-full bg-white">
        {selectedTab === 'signup' && <SignTab variant="signup" />}
        {selectedTab === 'login' && <SignTab variant="login" />}
        {selectedTab === 'recovery' && <ForgotPasswordTab />}
      </div>
    </div>
  )
}

export default LoginPage
