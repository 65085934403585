import { useCustomRouter, useGetTranslations } from '@/root/shared-hooks'
import { SectionProps } from '@/root/modules/admaritime/UserManual/components/Section/Section'
import { ManualType } from '@/root/shared-types'

export const useGetUserManualData = (type: ManualType | null): SectionProps[] | null => {
  const { getTranslation } = useGetTranslations()
  const { locale } = useCustomRouter()
  if (!type) {
    return null
  }

  if (type === 'ticket-purchase') {
    return [
      {
        title: getTranslation('userManualTicketPurchase'),
        instructions: [
          {
            title: getTranslation('userManualTicketPurchaseWhere'),
            index: 1,
            instruction: {
              title: getTranslation('userManualTicketPurchaseWhereSelectRoute'),
              images: [
                {
                  src: `/admaritime/userManual/ticket-purchase/ticketPurchase/${locale}/1.png`,
                  height: 237,
                  width: 219,
                  alt: 'where',
                },
              ],
            },
          },
          {
            title: getTranslation('userManualTicketPurchaseFerry'),
            index: '1a',
            instruction: {
              title: '',
              images: [
                {
                  src: `/admaritime/userManual/ticket-purchase/ticketPurchase/${locale}/1a1.png`,
                  height: 69,
                  width: 307,
                  alt: 'Taxi',
                },
              ],
            },
          },
          {
            title: getTranslation('userManualTicketPurchaseWhen'),
            index: 2,
            instruction: {
              title: getTranslation('userManualTicketPurchaseWhenSelectDate'),
              images: [
                {
                  src: `/admaritime/userManual/ticket-purchase/ticketPurchase/${locale}/2.png`,
                  height: 241,
                  width: 228,
                  alt: 'When',
                },
              ],
            },
          },
          {
            title: getTranslation('userManualTicketPurchaseRoundTrip'),
            index: 3,
            instruction: {
              title: getTranslation('userManualTicketPurchaseRoundTripChooseDestination'),
              images: [],
            },
          },
          {
            title: getTranslation('userManualTicketPurchaseGetTickets'),
            index: 4,
            instruction: {
              title: getTranslation('userManualTicketPurchaseGetTicketsClick'),
              images: [
                {
                  src: `/admaritime/userManual/ticket-purchase/ticketPurchase/${locale}/4.png`,
                  height: 41,
                  width: 104,
                  alt: 'GET TICKETS',
                },
              ],
            },
          },
        ],
      },
      {
        title: getTranslation('userManualDepartureDate'),
        instructions: [
          {
            title: getTranslation('userManualDepartureDateSelectDate'),
            index: 1,
            instruction: {
              title: getTranslation('userManualDepartureDateSelectDateNote'),
              images: [
                {
                  src: `/admaritime/userManual/ticket-purchase/departureDate/${locale}/1.png`,
                  height: 300,
                  width: 300,
                },
              ],
            },
          },
          {
            title: getTranslation('userManualDepartureDateChangeDate'),
            index: 2,
            instruction: {
              title: getTranslation('userManualDepartureDateChangeDateNoSeats'),
              images: [
                { src: `/admaritime/userManual/ticket-purchase/departureDate/${locale}/2.png`, height: 81, width: 247 },
              ],
            },
          },
          {
            title: getTranslation('userManualDepartureDateReturnRoute'),
            index: 3,
            instruction: {
              title: getTranslation('userManualDepartureDateReturnRouteNote'),
              images: [
                { src: `/admaritime/userManual/ticket-purchase/departureDate/${locale}/3.png`, height: 82, width: 169 },
              ],
            },
          },
          {
            title: getTranslation('userManualDepartureDateSelectTicket'),
            index: 4,
            instruction: {
              title: getTranslation('userManualDepartureDateSelectTicketClick'),
              images: [
                { src: `/admaritime/userManual/ticket-purchase/departureDate/${locale}/4.png`, height: 35, width: 250 },
              ],
            },
          },
        ],
      },
      {
        title: getTranslation('userManualTicketSelection'),
        instructions: [
          {
            title: getTranslation('userManualTicketSelectionFullFreePassengers'),
            index: 1,
            instruction: {
              title: getTranslation('userManualTicketSelectionFullFreePassengersSelectType'),
              images: [
                {
                  src: `/admaritime/userManual/ticket-purchase/ticketSection/${locale}/1_1.png`,
                  height: 90,
                  width: 200,
                },
                {
                  src: `/admaritime/userManual/ticket-purchase/ticketSection/${locale}/1_2.png`,
                  height: 73,
                  width: 305,
                },
              ],
            },
          },
          {
            title: getTranslation('userManualTicketSelectionFillInData'),
            index: '1a',
            instruction: {
              title: '',
              images: [
                {
                  src: `/admaritime/userManual/ticket-purchase/ticketSection/${locale}/1a.png`,
                  height: 348,
                  width: 259,
                },
              ],
            },
          },
          {
            title: getTranslation('userManualTicketVehicleTickets'),
            index: 2,
            instruction: {
              title: getTranslation('userManualTicketVehicleTicketsChooseType'),
              images: [
                {
                  src: `/admaritime/userManual/ticket-purchase/ticketSection/${locale}/2.png`,
                  height: 130,
                  width: 300,
                },
              ],
            },
          },
          {
            title: getTranslation('userManualTicketCheckYourCart'),
            index: 3,
            instruction: {
              title: '',
              images: [
                {
                  src: `/admaritime/userManual/ticket-purchase/ticketSection/${locale}/3.png`,
                  height: 145,
                  width: 211,
                },
              ],
            },
          },
          {
            title: getTranslation('userManualTicketCheckClickContinue'),
            index: 4,
            instruction: {
              title: '',
              images: [
                {
                  src: `/admaritime/userManual/ticket-purchase/ticketSection/${locale}/4.png`,
                  height: 33,
                  width: 250,
                  alt: 'where',
                },
              ],
            },
          },
        ],
      },
      {
        title: getTranslation('userManualCheckOut'),
        instructions: [
          {
            title: getTranslation('userManualCheckOutFillInfo'),
            index: 1,
            instruction: {
              title: '',
              images: [
                { src: `/admaritime/userManual/ticket-purchase/checkOut/${locale}/1.png`, height: 212, width: 305 },
              ],
            },
          },
          {
            title: getTranslation('userManualCheckOutTermsAndConditions'),
            index: 2,
            instruction: {
              title: '',
              images: [
                { src: `/admaritime/userManual/ticket-purchase/checkOut/${locale}/2.png`, height: 49, width: 305 },
              ],
            },
          },
          {
            title: getTranslation('userManualCheckOutPayment'),
            index: 3,
            instruction: {
              title: getTranslation('userManualCheckOutPaymentSelectOption'),
              images: [
                { src: `/admaritime/userManual/ticket-purchase/checkOut/${locale}/3.png`, height: 49, width: 305 },
              ],
            },
          },
        ],
      },
      {
        title: getTranslation('userManualPurchaseConfirmation'),
        instructions: [
          {
            title: getTranslation('userManualPurchaseConfirmationDownloadTicket'),
            index: 1,
            instruction: {
              title: getTranslation('userManualPurchaseConfirmationDownloadTicketChooseOption'),
              images: [
                {
                  src: `/admaritime/userManual/ticket-purchase/purchaseConfirmation/${locale}/1.png`,
                  height: 30,
                  width: 150,
                },
              ],
            },
          },
          {
            title: getTranslation('userManualPurchaseConfirmationCancelTicket'),
            index: 2,
            instruction: {
              title: getTranslation('userManualPurchaseConfirmationCancelTicketClick'),
              images: [
                {
                  src: `/admaritime/userManual/ticket-purchase/purchaseConfirmation/${locale}/2.png`,
                  height: 30,
                  width: 150,
                },
              ],
            },
          },
        ],
      },
      {
        title: getTranslation('userManualTicketCancellation'),
        instructions: [
          {
            title: getTranslation('userManualTicketCancellationOneWay'),
            index: 1,
            instruction: {
              title: '',
              images: [
                {
                  src: `/admaritime/userManual/ticket-purchase/ticketCancelletion/${locale}/1.png`,
                  height: 150,
                  width: 305,
                },
              ],
            },
          },
          {
            title: getTranslation('userManualTicketCancellationRoundTrip'),
            index: 2,
            instruction: {
              title: '',
              images: [
                {
                  src: `/admaritime/userManual/ticket-purchase/ticketCancelletion/${locale}/2.png`,
                  height: 150,
                  width: 305,
                },
              ],
            },
          },
        ],
      },
    ]
  }

  if (type === 'login') {
    return [
      {
        title: getTranslation('userManualSignUpEmailMobile'),
        instructions: [
          {
            title: getTranslation('userManualSignUpEmailMobileEnterInfo'),
            index: 1,
            instruction: {
              title: '',
              images: [
                {
                  src: `/admaritime/userManual/login/signUpEmailMobile/${locale}/1.png`,
                  height: 154,
                  width: 305,
                },
              ],
            },
          },
          {
            title: getTranslation('userManualSignUpEmailMobileAccountVerification'),
            index: 2,
            instruction: {
              title: '',
              images: [
                {
                  src: `/admaritime/userManual/login/signUpEmailMobile/${locale}/2.png`,
                  height: 154,
                  width: 305,
                },
              ],
            },
          },
        ],
      },

      {
        title: getTranslation('userManualSignInEmailMobile'),
        instructions: [
          {
            title: getTranslation('userManualSignUpEmailMobileEnterInfo'),
            index: 1,
            instruction: {
              title: '',
              images: [
                {
                  src: `/admaritime/userManual/login/signInEmailMobile/${locale}/1.png`,
                  height: 210,
                  width: 305,
                },
              ],
            },
          },
        ],
      },

      {
        title: getTranslation('userManualSignUpUaePass'),
        instructions: [
          {
            title: getTranslation('userManualSignUpUaePassContinue'),
            index: 1,
            instruction: {
              title: '',
              images: [
                {
                  src: `/admaritime/userManual/login/signUpUaePass/${locale}/1.png`,
                  height: 210,
                  width: 305,
                },
              ],
            },
          },
          {
            title: getTranslation('userManualSignUpEmailMobileEnterInfo'),
            index: 2,
            instruction: {
              title: getTranslation('userManualSignUpUaePassContinue'),
              images: [
                {
                  src: `/admaritime/userManual/login/signUpUaePass/${locale}/2.png`,
                  height: 210,
                  width: 305,
                },
              ],
            },
          },
          {
            title: getTranslation('userManualSignUpUaePassAccessRequest'),
            index: 3,
            instruction: {
              title: '',
              images: [
                {
                  src: `/admaritime/userManual/login/signUpUaePass/${locale}/3.png`,
                  height: 210,
                  width: 305,
                },
              ],
            },
          },
        ],
      },

      {
        title: getTranslation('userManualSignUpFacebook'),
        instructions: [
          {
            title: getTranslation('userManualSignUpFacebookClick'),
            index: 1,
            instruction: {
              title: '',
              images: [
                {
                  src: `/admaritime/userManual/login/signUpFacebook/${locale}/1.png`,
                  height: 210,
                  width: 305,
                },
              ],
            },
          },
          {
            title: getTranslation('userManualSignUpFacebookLogIn'),
            index: 2,
            instruction: {
              title: '',
              images: [
                {
                  src: `/admaritime/userManual/login/signUpFacebook/${locale}/2_1.png`,
                  height: 210,
                  width: 305,
                  title: getTranslation('userManualSignUpEmailMobileEnterInfo'),
                },
                {
                  src: `/admaritime/userManual/login/signUpFacebook/${locale}/2_2.png`,
                  height: 210,
                  width: 305,
                  title: getTranslation('userManualSignUpFacebookLogInClickContinue'),
                },
              ],
            },
          },
        ],
      },
      {
        title: getTranslation('userManualSignUpGoogle'),
        instructions: [
          {
            title: getTranslation('userManualSignUpGoogleClick'),
            index: 1,
            instruction: {
              title: '',
              images: [
                {
                  src: `/admaritime/userManual/login/signUpGoogle/${locale}/1.png`,
                  height: 210,
                  width: 305,
                },
              ],
            },
          },
          {
            title: getTranslation('userManualSignUpGoogleLogIn'),
            index: 2,
            instruction: {
              title: '',
              images: [
                {
                  src: `/admaritime/userManual/login/signUpGoogle/${locale}/2.png`,
                  height: 210,
                  width: 305,
                },
              ],
            },
          },
        ],
      },
      {
        title: getTranslation('userManualProfile'),
        instructions: [
          {
            title: getTranslation('userManualProfileClick'),
            index: 1,
            instruction: {
              title: '',
              images: [
                {
                  src: `/admaritime/userManual/login/profile/${locale}/1.png`,
                  height: 210,
                  width: 305,
                },
              ],
            },
          },
          {
            title: getTranslation('userManualProfileEnterCustomersInfo'),
            index: 2,
            instruction: {
              title: '',
              images: [
                {
                  src: `/admaritime/userManual/login/profile/${locale}/2.png`,
                  height: 210,
                  width: 305,
                },
              ],
            },
          },
        ],
      },
    ]
  }
  return null
}
