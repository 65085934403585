import { BoraTheme, Slug } from '@/root/shared-types'

interface getStyledHTMLProps {
  theme: BoraTheme
  body: string
  slug?: Slug
}

const removeStyleTags = (html: string) => {
  return html.replace(/<style\s*[^>]*>.*?<\/style>|style="[^"]*"|class="[^"]*"/gis, '')
}

export const getStyledHTML = ({ theme, body, slug = 'default' }: getStyledHTMLProps) => {
  switch (theme) {
    case 'admaritime':
      return removeStyleTags(body).replace(
        /<td\s*>|<tr\s*>|<table\s*>|<strong\s*>|<p\s*>|<a|<img\s*src="\/uploads|<ul\s*>|<ol\s*>|<blockquote\s*>|<h1\s*>|<h2\s*>|<h3\s*>|<h4\s*>/g,
        (match) => {
          if (match === '<a') {
            return '<a class="underline" '
          }
          if (/<tr\s*>/.test(match)) {
            return '<tr class="first:text-secondary text-base">'
          }
          if (/<p\s*>/.test(match)) {
            if (slug === 'default') {
              return '<p class="text-base text-start">'
            }
            return '<p class="text-base">'
          }
          if (/<td\s*>/.test(match)) {
            return '<td class=" px-5 py-3 text-center align-middle border border-adm-gray-300">'
          }
          if (/<table\s*>/.test(match)) {
            return '<table class="w-full">'
          }
          if (/<img\s*src="\/uploads/.test(match)) {
            return '<img src="/api/uploads'
          }
          if (/<ul\s*>/.test(match)) {
            return '<ul class="list-disc list-inside text-[16px]">'
          }
          if (/<ol\s*>/.test(match)) {
            return '<ol class="list-decimal list-inside text-[16px]">'
          }
          if (/<blockquote\s*>/.test(match)) {
            return '<blockquote class=" border-s-8 ps-2 ms-5 border-adm-gray-300 italic">'
          }
          if (/<h1\s*>/.test(match)) {
            return '<h1 class="text-3xl font-semibold">'
          }
          if (/<h2\s*>/.test(match)) {
            return '<h2 class="text-2xl font-semibold">'
          }
          if (/<h3\s*>/.test(match)) {
            return '<h2 class="font-semibold">'
          }
          if (/<h4\s*>/.test(match)) {
            return '<h2 class="font-semibold">'
          }
          return match
        }
      )
    case 'bluewave':
      return (
        removeStyleTags(body).replace(
          /<td\s*>|<tr\s*>|<strong\s*>|<p\s*>|<a|<ol\s*>|<li\s*>|<div\s*>|<img\s*src="|<h2\s*>/g,
          (match) => {
            if (/<td\s*>/.test(match)) {
              if (slug === 'contacts') {
                return `<td class="w-1/2 p-10 flex flex-col gap-6 [&>p]:text-lg [&>p]:text-black [&>p]:font-normal">`
              }
              if (slug === 'routes') {
                return `<td class="border text-lg  first:indent-5 first:px-3 px-8">`
              }
              return `<td class="border [&>b]:text-primary-base [&>b]:text-lg h-12 px-2">`
            }
            if (/<strong\s*>/.test(match)) {
              return `<strong class="text-primary-base">`
            }
            if (/<p\s*>/.test(match)) {
              if (slug === 'contacts') {
                return `<p class="[&>a]:underline [&>a]:text-black text-primary-base text-2xl font-bold">`
              }
            }
            if (/<div\s*>/.test(match)) {
              if (slug === 'about') {
                return '<div class="text-lg mb-6">'
              }
            }
            if (/<tr\s*>/.test(match)) {
              if (slug === 'contacts') {
                return `<tr class="flex justify-between ">`
              }
              return `<tr class="first:border">`
            }
            if (/<ol\s*>/.test(match)) {
              if (slug === 'routes') {
                return '<ol class="list-decimal pl-4 mb-5 marker:text-primary-base marker:font-bold">'
              }
            }
            if (/<li\s*>/.test(match)) {
              if (slug === 'routes') {
                return '<li class="mb-8 text-lg">'
              }
            }
            if (/<img\s*src="/.test(match)) {
              return '<img src="/api'
            }
            if (/<h2\s*>/.test(match)) {
              return '<h2 class="flex items-center text-primary-base font-bold h-24 ml-3 text-2xl">'
            }
            if (match === '<a') {
              return '<a class="underline" '
            }
            return match
          }
        ) || ''
      )
  }
}
