import { FC, ReactNode } from 'react'
import cn from 'classnames'
import { useSwipeable } from 'react-swipeable'

interface DrawerProps {
  body: ReactNode
  isOpen: boolean
  semiOpened: boolean
  setSemiOpened: (isOpen: boolean) => void
}

export const Drawer: FC<DrawerProps> = ({ body, isOpen, semiOpened, setSemiOpened }) => {
  const handlers = useSwipeable({
    onSwipedUp: () => {
      setSemiOpened(false)
    },
  })
  return (
    <div
      {...handlers}
      className={cn(
        ' fixed overflow-scroll z-20 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out shadow-sm',
        { 'transition-opacity opacity-100 duration-500 translate-y-1/2': semiOpened && isOpen },
        { 'transition-opacity opacity-100 duration-500 translate-y-0': !semiOpened && isOpen },
        { 'transition-all delay-500 opacity-0 translate-y-full': !isOpen }
      )}
    >
      {body}
    </div>
  )
}
