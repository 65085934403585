import { DateTime } from 'luxon'

interface FormatToFixedNumberProps {
  value?: number
  decimalPlaces?: number
}
export const formatToFixedNumber = ({ value = 0, decimalPlaces = 2 }: FormatToFixedNumberProps) =>
  value.toFixed(decimalPlaces)

interface GetDateFromISODateProps {
  isoDate: string
}
export const getDateFromISODate = ({ isoDate }: GetDateFromISODateProps) => {
  return DateTime.fromISO(new Date(isoDate).toISOString()).toFormat('dd.MM.yyyy')
}
