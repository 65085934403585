import { FC } from 'react'

import { useGetTranslations } from '@/root/shared-hooks'
import { RowContainer } from '@/root/modules/admaritime'

interface CustomerInfoProps {
  customerName: string
  customerPhoneNumber: string
  customerEmail: string
  ticketNumber: number
}

const CustomerInfo: FC<CustomerInfoProps> = ({ customerEmail, customerName, customerPhoneNumber, ticketNumber }) => {
  const { getTranslation } = useGetTranslations()

  return (
    <RowContainer>
      <p className="text-xl font-bold md:text-[28px]">{customerName}</p>
      <div className="flex w-full flex-col items-start justify-center text-lg">
        <span>
          <p dir="ltr" className="rtl:text-right">
            {customerPhoneNumber}
          </p>
          <p>{customerEmail}</p>
        </span>
      </div>
      <p className="flex w-full justify-center text-2xl md:justify-end">
        {getTranslation('ticketPrintedTicketNumber')}
        <strong className="text-[28px]">{ticketNumber}</strong>
      </p>
    </RowContainer>
  )
}

export default CustomerInfo
