import { HeadRow, useDayPicker } from 'react-day-picker'
import React from 'react'

export function Head(): JSX.Element {
  const { styles, components } = useDayPicker()
  const HeadRowComponent = components?.HeadRow ?? HeadRow
  return (
    <thead
      style={styles.head}
      className="border-neutral-normal-light text-base-darker translate-y-2 border-b text-lg [&>tr>th]:font-normal [&>tr>th]:lowercase"
    >
      <HeadRowComponent />
    </thead>
  )
}
