import { FC, MouseEventHandler } from 'react'
import { Button } from '@/root/ui/admaritime'
import cn from 'classnames'

interface InfoMessageContainerProps {
  body: string
  ButtonText: string
  onClick: MouseEventHandler<HTMLButtonElement>
  title?: string
}

export const InfoMessageContainer: FC<InfoMessageContainerProps> = ({ title, body, onClick, ButtonText }) => (
  <div
    className={cn('flex w-full max-w-screen-xl flex-col items-center gap-10 bg-white pb-10 md:px-0', {
      'pt-10 min-h-96 justify-center': !title,
    })}
  >
    {title && (
      <h1 className="bg-base flex h-28 w-full items-center justify-center text-2xl font-bold text-white">{title}</h1>
    )}
    <p
      className={cn(' text-center  text-base [&>a]:font-bold [&>a]:underline', {
        'text-2xl max-w-full': !title,
        'text-lg max-w-screen-sm': title,
      })}
      dangerouslySetInnerHTML={{ __html: body }}
    />
    <Button onClick={onClick} variant="contained" className="uppercase">
      {ButtonText}
    </Button>
  </div>
)
