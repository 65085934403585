import { FC, useEffect, useMemo, useState } from 'react'
import { Tariff } from '@/root/shared-types'
import { Tabs } from '@/root/modules/admaritime/AppLayout/components/Tabs/Tabs'
import { PageTitle } from '@/root/modules/admaritime'
import { TariffsTable } from '@/root/modules/admaritime/TariffsPage/components/TariffsTable/TariffsTable'
import { useGetTranslations } from '@/root/shared-hooks'
import { ContentContainer } from '@/root/ui/admaritime'

interface TariffsPageProps {
  tariffs?: Tariff[]
}

export const TariffsPage: FC<TariffsPageProps> = ({ tariffs }) => {
  const { getTranslation } = useGetTranslations()
  const tabs = useMemo(() => tariffs?.map((tariff) => tariff.title) || [], [tariffs])
  const [currentTab, setCurrentTab] = useState<string>(tabs[0])

  useEffect(() => {
    setCurrentTab(tabs[0] || '')
  }, [tabs])

  const currentTariff = tariffs?.find((tariff) => tariff.title === currentTab)

  return (
    <ContentContainer>
      <PageTitle>{getTranslation('tariffsPageTitle')}</PageTitle>
      {tabs && currentTab && <Tabs tabs={tabs} currentTab={currentTab} setTab={(tab: string) => setCurrentTab(tab)} />}
      {currentTariff && <TariffsTable cols={currentTariff.data.cols} rows={currentTariff.data.rows} />}
    </ContentContainer>
  )
}
