import { ButtonHTMLAttributes, FC } from 'react'
import cn from 'classnames'
import { Button } from '@/root/ui/admaritime'

export const KebabButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({ children, disabled, ...rest }) => (
  <Button
    className={cn(
      'block w-full text-left px-4 py-2 font-normal border-b border-adm-gray-550 last:border-none text-primary-base',
      {
        'bg-adm-gray-450 cursor-not-allowed': disabled,
      }
    )}
    variant="blue"
    disabled={disabled}
    {...rest}
  >
    {children}
  </Button>
)
