import { FC } from 'react'
import { ActionButtonProps } from '@/root/shared-types'
import { useGetTranslations } from '@/root/shared-hooks'
import { ActionButton } from '@/root/modules/admaritime/RefundCard/components/ActionButton/ActionButon'

export const ActionButtons: FC<ActionButtonProps> = ({
  onKeepTickets,
  onCancel,
  isAvailableCancelButton,
  isCancellationInProgress,
}) => {
  const { getTranslation } = useGetTranslations()
  return (
    <div className="flex flex-col gap-3 md:flex-row md:justify-around">
      <ActionButton onClick={onKeepTickets} variant="neutral" title={getTranslation('keepTicket')} />
      <ActionButton
        onClick={onCancel}
        variant="error"
        title={getTranslation('removeTicket')}
        disabled={!isAvailableCancelButton}
        isLoading={isCancellationInProgress}
      />
    </div>
  )
}
