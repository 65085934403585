import { FC } from 'react'
import { useGetTranslations } from '@/root/shared-hooks'
import { BankCardIcon } from '@/root/ui/admaritime'

export const PayWithCardButtonContent: FC = () => {
  const { getTranslation } = useGetTranslations()
  return (
    <span className="flex w-full items-center justify-center gap-5 text-2xl">
      <BankCardIcon />
      <h1 className="uppercase">{getTranslation('confirmationPayWithCard')}</h1>
    </span>
  )
}
