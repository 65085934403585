import React, { FC, ChangeEvent } from 'react'
import cn from 'classnames'
import { ImageProps } from '@/root/shared-types'
import { useGetTranslations } from '@/root/shared-hooks'
import RadioButton from '@/root/ui/admaritime/RadioButton/RadioButton'

interface RouteRadioBarProps {
  handleRouteChange: (event: ChangeEvent<HTMLInputElement>, image: ImageProps) => void
  title: string
  code: string
  type: 'semiloop' | 'fullDay' | 'fullLoop'
  image: ImageProps
  portFrom?: string
  selectedRoute?: string
}

const RouteRadioBar: FC<RouteRadioBarProps> = ({
  selectedRoute,
  handleRouteChange,
  title,
  code,
  type,
  image,
  portFrom,
}) => {
  const { getTranslation } = useGetTranslations()
  const textContent =
    type === 'fullDay' ? getTranslation('fullDayRoundLabel') : getTranslation('fullLoopRideLabel', { port: portFrom })

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleRouteChange(event, image)
  }
  return (
    <RadioButton
      htmlFor={code}
      checked={selectedRoute === code}
      id={code}
      value={title}
      onChange={handleChange}
      className="text-primary-base ms-2 text-base font-bold xs:ms-5"
    >
      <span
        className={cn(' w-full', {
          'grid grid-cols-2 gap-4 xs:grid-cols-[170px_minmax(150px,_3fr)]': type === 'semiloop',
        })}
      >
        {type === 'semiloop' ? (
          (title || code).split('-').map((str) => <p key={str}>{str}</p>)
        ) : (
          <p className="pe-3 text-base font-normal leading-5 md:pe-0 lg:pe-1">{textContent}</p>
        )}
      </span>
    </RadioButton>
  )
}

export default RouteRadioBar
