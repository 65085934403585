import { Loader } from 'next/dynamic'
import Button from './Button/Button'
import { If } from '@/root/ui/shared'
import Icon from '@/root/ui/shared/Icon'
import { AgreeIcon } from '@/root/ui/shared/icons/AgreeIcon'
import Checkbox from './Checkbox/Checkbox'
import { ContentContainer } from './ContentContainer/ContentContainer'
import { GroupBadge } from './GroupBadge/GroupBadge'
import { ArrowFullIcon } from '@/root/ui/shared/icons/ArrowFullIcon'
import { ArrowIcon } from '@/root/ui/shared/icons/ArrowIcon'
import ArrowLong from '@/root/ui/shared/icons/ArrowLong'
import { BankCardIcon } from '@/root/ui/shared/icons/BankCardIcon'
import { BicycleIcon } from '@/root/ui/shared/icons/BicycleIcon'
import { BidirectionIcon } from '@/root/ui/shared/icons/BidirectionIcon'
import { BottomArrowIcon } from '@/root/ui/shared/icons/BottomArrowIcon'
import { CancelIcon } from '@/root/ui/shared/icons/CancelIcon'
import { CarIcon } from '@/root/ui/shared/icons/CarIcon'
import { ChevronDownIconWhite } from '@/root/ui/shared/icons/ChevronDownIconWhite'
import { ChevronUpIconBlue } from '@/root/ui/shared/icons/ChevronUpIconBlue'
import { ClockIcon } from '@/root/ui/shared/icons/ClockIcon'
import { CustomerInfoIcon } from '@/root/ui/shared/icons/CustomerInfoIcon'
import { LocationPointerIcon } from '@/root/ui/shared/icons/LocationPointerIcon'
import { PassengerIconCyan } from '@/root/ui/shared/icons/PassengerIconCyan'
import { ReturnSailSepIcon } from '@/root/ui/shared/icons/ReturnSailSepIcon'
import { SliderIconRight } from '@/root/ui/shared/icons/SliderIconRight'
import { StepCompletedIcon } from '@/root/ui/shared/icons/StepCompletedIcon'
import { TruckIconCyan } from '@/root/ui/shared/icons/TruckIconCyan'
import { VesselIcon } from '@/root/ui/shared/icons/VesselIcon'
import ListNumber from './ListNumber/ListNumber'
import { Tab } from './Tab/Tab'
import Banner from './Banner/Banner'
import { Divider } from '@/root/ui/shared/Divider/Divider'
import { KebabButton } from '@/root/ui/admaritime/KebabButton/KebabButton'
import { UaePassIconWhite } from '@/root/ui/shared/icons/UaePassIconWhite'
import { SignUpIconBlue } from '@/root/ui/shared/icons/SignUpIconBlue'
import { SignInIconBlue } from '@/root/ui/shared/icons/SignInIconBlue'
import { LockIconGray } from '@/root/ui/shared/icons/LockIconGray'
import { InputField } from '@/root/ui/admaritime/InputField/InputField'
import { GoogleIconColored } from '@/root/ui/shared/icons/GoogleIconColored'
import { FacebookIconBlue } from '@/root/ui/shared/icons/FacebookIconBlue'
import { EmailIconBlue } from '@/root/ui/shared/icons/EmailIconBlue'
import { Kebab } from '@/root/ui/shared/icons/Kebab'
import { BackIcon } from '@/root/ui/shared/icons/BackIcon'
import { AppleIcon } from '@/root/ui/shared/icons/AppleIcon'
import { CalendarIcon } from '@/root/ui/shared/icons/CalendarIcon'
import { ChevronDownIcon } from '@/root/ui/shared/icons/ChevronDownIcon'
import { CloseIcon } from '@/root/ui/shared/icons/CloseIcon'
import { InfoIcon } from '@/root/ui/shared/icons/InfoIcon'

export {
  AgreeIcon,
  AppleIcon,
  ArrowFullIcon,
  ArrowIcon,
  ArrowLong,
  BackIcon,
  BankCardIcon,
  Banner,
  BicycleIcon,
  BidirectionIcon,
  BottomArrowIcon,
  Button,
  CalendarIcon,
  CancelIcon,
  CarIcon,
  Checkbox,
  ChevronDownIcon,
  ChevronDownIconWhite,
  ChevronUpIconBlue,
  ClockIcon,
  CloseIcon,
  ContentContainer,
  CustomerInfoIcon,
  Divider,
  EmailIconBlue,
  FacebookIconBlue,
  GoogleIconColored,
  Icon,
  If,
  InfoIcon,
  InputField,
  ListNumber,
  LocationPointerIcon,
  LockIconGray,
  PassengerIconCyan,
  ReturnSailSepIcon,
  SignInIconBlue,
  SignUpIconBlue,
  SliderIconRight,
  StepCompletedIcon,
  Tab,
  TruckIconCyan,
  VesselIcon,
  UaePassIconWhite,
  GroupBadge,
  KebabButton,
  Kebab,
}

export type { Loader }
