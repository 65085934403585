import { FC, HTMLAttributes, ReactNode } from 'react'
import cn from 'classnames'

interface TableCellProps extends HTMLAttributes<HTMLParagraphElement> {
  isHeader?: boolean
  icon?: ReactNode
}

export const TableCell: FC<TableCellProps> = ({ title, isHeader = false, className, icon }) => {
  return (
    <div
      className={cn(
        'flex justify-center items-center min-w-14 md:min-w-20 first:w-full first:justify-start gap-2 text-xs md:text-base',
        { 'text-base-normal-lighter text-center': isHeader },
        className
      )}
    >
      {title}
      {icon}
    </div>
  )
}
