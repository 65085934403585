import { FC } from 'react'
import { Route as RouteProps } from '@/root/shared-types'
import { ScheduleTable } from '@/root/modules/admaritime/SchedulePage/components/SceduleTable/ScheduleTable'

export const Route: FC<RouteProps> = ({ description, schedule, type }) => {
  return (
    <div className="flex flex-col">
      {description && <h1>{description}</h1>}
      <div className="flex flex-col gap-8">
        {schedule.map((sch, index) => (
          <ScheduleTable key={sch.direction.join() + index} {...sch} scheduleType={type} />
        ))}
      </div>
    </div>
  )
}
