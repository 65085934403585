import { FC } from 'react'

export const TheeDotsLoader: FC = () => (
  <div className="flex items-center justify-center space-x-2 bg-white dark:invert ">
    <span className="sr-only">...</span>
    <div className="bg-base-very-light size-1 animate-bounce rounded-full [animation-delay:-0.3s]"></div>
    <div className="bg-base-very-light size-1 animate-bounce rounded-full [animation-delay:-0.15s]"></div>
    <div className="bg-base-very-light size-1 animate-bounce rounded-full"></div>
  </div>
)
