import { FC, useState } from 'react'
import { Heading, RecoveryEmailForm, ResetPasswordForm } from '@/root/modules/admaritime/LoginPage'

export const ForgotPasswordTab: FC = () => {
  const [isEmailSent, setIsEmailSent] = useState(false)

  return (
    <div className="flex w-full flex-col items-center gap-8 p-7">
      <Heading type="recovery" />
      {!isEmailSent ? (
        <RecoveryEmailForm setIsEmailSent={setIsEmailSent} />
      ) : (
        <ResetPasswordForm setIsEmailSent={setIsEmailSent} />
      )}
    </div>
  )
}

export default ForgotPasswordTab
