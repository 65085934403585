import React, { FC, MouseEventHandler } from 'react'
import { RouteCardContainerButton } from '@/root/modules/admaritime/RouteButtonsSection/Components/RouteCardContainerButton/RouteCardContainerButton'
import RouteInfo from '../RouteInfo/RouteInfo'
import { ImageProps, SailPackagesGroups } from '@/root/shared-types'
import { SailPackagesGroup } from '@/root/shared-types/reservation/reservation-types'
import { RoutePicture } from '@/root/modules/admaritime/RouteButtonsSection/Components/RoutePicture/RoutePicture'
import { useCustomRouter } from '@/root/shared-hooks'

interface RouteCardProps extends SailPackagesGroup<SailPackagesGroups> {
  image?: ImageProps
  onClick?: MouseEventHandler<HTMLButtonElement>
  clicked?: boolean
}

export const RouteCard: FC<RouteCardProps> = ({
  from,
  to,
  taxi,
  features: { supportTrucks, supportPassengers, supportCars },
  image,
  onClick,
  clicked = false,
}) => {
  const { locale } = useCustomRouter()
  return (
    <RouteCardContainerButton onClick={onClick} clicked={clicked}>
      <RouteInfo
        locationFrom={from[locale]}
        locationTo={to[locale]}
        isTaxi={taxi}
        containsTrucks={supportTrucks}
        containsCars={supportCars}
        containsPassengers={supportPassengers}
      />
      {image && <RoutePicture image={image} />}
    </RouteCardContainerButton>
  )
}
