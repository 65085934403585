import { Vehicle } from '@/root/shared-types'
import { FC } from 'react'

import { useGetTranslations } from '@/root/shared-hooks'

interface VehiclesWithNumbers {
  vehicles?: Vehicle[]
}

const VehiclesWithNumbers: FC<VehiclesWithNumbers> = ({ vehicles }) => {
  const { getTranslation } = useGetTranslations()

  if (!vehicles) {
    return null
  }

  return (
    <div className="flex flex-col items-end space-y-1 pb-5 text-xl">
      {vehicles.map(({ licencePlate }) => {
        return (
          <div key={licencePlate}>
            {getTranslation('ticketPrintedTicketVehicleNumberLabel')}
            <span className="ms-2 font-bold">{licencePlate}</span>
          </div>
        )
      })}
    </div>
  )
}

export default VehiclesWithNumbers
