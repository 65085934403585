import { useRef } from 'react'
import { useCustomRouter } from '@/root/shared-hooks'

export const useActionsButtonHandlers = (reservationId: number, reservationToken: string) => {
  const { locale, push } = useCustomRouter()

  const iframeRef = useRef<HTMLIFrameElement>(null)

  const frameId = 'ticket-frame'

  const previewUrl = `/api/reservation/${reservationId}/preview/${locale}/${reservationToken}`

  const onDownload = () => window.open(previewUrl, '_blank')

  const onPrint = () => iframeRef.current?.contentWindow?.print()

  const onCancel = () => push(`/refund/${reservationToken}`)

  const onViewClick = () => push(`/ticket/${reservationToken}`)

  const iframeProps = {
    id: frameId,
    name: frameId,
    title: frameId,
    ref: iframeRef,
    className: 'hidden',
    src: previewUrl,
  }

  return { onDownload, onPrint, onCancel, onViewClick, iframeProps }
}
