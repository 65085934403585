import { CustomerInfoProps } from '@/root/shared-types/reservation/reservation-types'
import { FieldValues, useForm } from 'react-hook-form'
import { useFixRHFAutocomplete, useGetTranslations } from '@/root/shared-hooks'
import { InputHTMLAttributes, useRef, useState } from 'react'
import { generateRandomCustomer } from '@/root/utils/generateRandomCustomer'
import { contactFields } from '@/root/shared-consts'

export const useGetCustomerFields = (customerInfo: CustomerInfoProps) => {
  const { getTranslation } = useGetTranslations()
  const code = customerInfo.phoneNumber?.split(' ')[0] || ''
  const phoneNumber = customerInfo.phoneNumber?.split(' ')[1] || ''
  const { firstName = '', lastName = '', email = '' } = customerInfo

  const {
    watch,
    control,
    formState: { errors },
    trigger,
    getValues,
    setValue,
    reset,
    clearErrors,
  } = useForm<FieldValues>({
    defaultValues: { firstName, lastName, email, code, phoneNumber },
  })

  const oldFieldsValues = useRef(getValues())

  const [initialValues, setInitialValues] = useState(oldFieldsValues.current)

  useFixRHFAutocomplete({ getValues, trigger, watch })
  const watchAllFields = watch()
  const isAllFieldsFilled = Object.values(watchAllFields).every((field) => field && field.trim() !== '')
  const hasChanged = Object.keys(watchAllFields).some((key) => watchAllFields[key] !== initialValues[key])

  const handleSave = () => {
    if (isAllFieldsFilled && !Object.keys(errors).length) {
      setInitialValues(getValues())
    }
  }

  const handleCancel = () => {
    reset(initialValues)
    clearErrors()
    setTimeout(() => {
      clearErrors()
    }, 0)
  }

  const fillRandomData = () => {
    const randomData: Record<string, string> = generateRandomCustomer()

    fieldsIds.forEach((id) => {
      if (randomData[id]) {
        if (id === contactFields.PHONE_NUMBER) {
          setValue(id, randomData[id], { shouldValidate: true, shouldDirty: true })
          setValue(contactFields.CODE, randomData[contactFields.CODE], { shouldValidate: false })
        }
        setValue(id, randomData[id], { shouldValidate: true, shouldDirty: true })
      }
    })
  }

  const fieldsIds = [contactFields.FIRST_NAME, contactFields.LAST_NAME, contactFields.PHONE_NUMBER, contactFields.EMAIL]
  const fieldValuesContent: Record<
    string,
    { label: string; type: InputHTMLAttributes<HTMLInputElement>['type']; placeholder?: string }
  > = {
    firstName: {
      label: getTranslation('confirmationContactFormFirstName'),
      type: 'text',
      placeholder: getTranslation('confirmationContactFormFirstNamePlaceHolder'),
    },
    lastName: {
      label: getTranslation('confirmationContactFormLastName'),
      type: 'text',
      placeholder: getTranslation('confirmationContactFormLastNamePlaceHolder'),
    },
    email: {
      label: getTranslation('confirmationContactFormEmail'),
      placeholder: getTranslation('confirmationContactFormEmailPlaceHolder'),
      type: 'email',
    },
    phoneNumber: {
      label: getTranslation('confirmationContactFormPhoneNumber'),
      type: 'tel',
    },
  }

  return {
    fieldsIds,
    fieldValuesContent,
    control,
    errors,
    trigger,
    getValues,
    handleSave,
    handleCancel,
    fillRandomData,
    isSaveDisabled: !isAllFieldsFilled || !hasChanged || Object.keys(errors).length > 0,
  }
}