import { ButtonHTMLAttributes, FC } from 'react'
import { Icon } from '@/root/ui/admaritime'
import cn from 'classnames'
import { Loader } from '@/root/ui/shared'

interface ActionButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant: 'error' | 'neutral'
  isLoading?: boolean
}
export const ActionButton: FC<ActionButtonProps> = ({ onClick, variant, title, disabled, isLoading }) => {
  const IconsMap = {
    error: <Icon type="CrossWhite" alt="cancel" width={18} />,
    neutral: <Icon type="Back" alt="back" width={29} ignoreRtl />,
  }
  return (
    <button
      disabled={disabled}
      className={cn(
        'flex h-[60px] max-w-96 w-full items-center justify-between rounded-md px-4',
        {
          'border border-neutral-dark bg-neutral-lightest font-bold text-neutral-dark': variant === 'neutral',
        },
        {
          'bg-error text-white font-bold disabled:opacity-40': variant === 'error',
        },
        {
          'opacity-40': isLoading,
        }
      )}
      onClick={onClick}
    >
      {IconsMap[variant]}
      {isLoading ? <Loader /> : title?.toUpperCase()}
    </button>
  )
}
