import { useEffect, useRef } from 'react'
import { FieldValues, UseFormGetValues, UseFormTrigger, UseFormWatch } from 'react-hook-form'

interface FixRHFAutocompleteProps {
  getValues: UseFormGetValues<FieldValues>
  watch: UseFormWatch<FieldValues>
  trigger: UseFormTrigger<FieldValues>
}

export const useFixRHFAutocomplete = ({ getValues, watch, trigger }: FixRHFAutocompleteProps) => {
  const oldFieldsValues = useRef(getValues())
  const watchAllFields = watch()

  useEffect(() => {
    Object.keys(watchAllFields).forEach((target) => {
      if (watchAllFields?.[target] !== oldFieldsValues?.current?.[target]) {
        trigger(target)
      }
    })

    oldFieldsValues.current = watchAllFields
  }, [trigger, watchAllFields])
}
