import { FC } from 'react'
import { DateTime } from 'luxon'
import { NumberOfTicketItems } from '@/root/components/admaritime'
import { Icon, VesselIcon } from '@/root/ui/admaritime'
import { useGetTranslations } from '@/root/shared-hooks'
import { RowContainer } from '@/root/modules/admaritime'

interface DepartureAndVesselInfoProps {
  departure: string
  vesselTitle: string
  numOfPassengers: number
  numOfCars: number
  cancelled: boolean
}

const DepartureAndVesselInfo: FC<DepartureAndVesselInfoProps> = ({
  departure,
  vesselTitle,
  numOfPassengers,
  numOfCars,
  cancelled,
}) => {
  const { getTranslation } = useGetTranslations()

  const departureValue = DateTime.fromISO(departure).toFormat('dd.MM.yyyy - hh:mm a')

  return (
    <RowContainer>
      <p className="text-2xl font-bold">{departureValue}</p>
      <div className="flex items-center font-bold">
        {cancelled ? (
          <span className="flex gap-3">
            <Icon alt="cancel" width={29} type="iconStop" />
            <p className=" text-alert-dark text-2xl uppercase">{getTranslation('cancelled')}</p>
          </span>
        ) : (
          <span className="flex gap-3">
            <VesselIcon width={41} height={18} />
            <p className="text-2xl uppercase">{vesselTitle}</p>
          </span>
        )}
      </div>
      <div className="flex justify-end">
        <div className="flex">
          <NumberOfTicketItems iconType="car" width={35} number={numOfCars} />
          <NumberOfTicketItems iconType="passenger" number={numOfPassengers} />
        </div>
      </div>
    </RowContainer>
  )
}

export default DepartureAndVesselInfo
