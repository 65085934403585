import React, { FC } from 'react'
import NotesFooterSection, { Notes } from './NotesFooterSection'

interface NotesFooterProps {
  notes: Notes[]
}

const NotesFooter: FC<NotesFooterProps> = ({ notes }) => {
  return (
    <div className="max-w-screen-xl">
      <div className="bg-primary-base flex w-full flex-wrap text-white">
        {notes.map(({ title, body, id, icon }) => (
          <div className="min-w-[280px] flex-1 xs:min-w-[420px]" key={id}>
            <NotesFooterSection title={title} body={body} icon={icon} id={id} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default NotesFooter
