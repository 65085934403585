import { UserIcon } from '@/root/ui/shared/icons/UserIcon'
import { FC } from 'react'
import { useGetTranslations } from '@/root/shared-hooks'

const CustomerDetailsHeader: FC = () => {
  const { getTranslation } = useGetTranslations()

  return (
    <section className="flex flex-col gap-2 ">
      <div className="flex gap-5">
        <UserIcon />
        <label className="text-primary-base pt-2 text-lg font-bold uppercase">Test Test</label>
      </div>
      <label className="text-neutral text-sm">{getTranslation('customerSectionText')}</label>
    </section>
  )
}

export default CustomerDetailsHeader
