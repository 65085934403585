import React, { ChangeEvent, FC } from 'react'
import { WaterTaxiIcon } from '@/root/ui/shared/icons/WaterTaxiIcon'
import { ImageProps, LocalizedLocation, SailPackageForCard } from '@/root/shared-types'
import { useGetTranslations } from '@/root/shared-hooks'
import { SailPackagesBar } from '@/root/modules/admaritime/TaxiRoutes/components/SailPackegesBar/SailPackagesBar'

interface SailPackagesBarsProps {
  handleRouteChange: (event: ChangeEvent<HTMLInputElement>, image: ImageProps) => void
  semiLoopSailPackages: SailPackageForCard[]
  fullDaySailPackages: SailPackageForCard[]
  fullLoopSailPackages: SailPackageForCard[]
  selectedRoute?: string
  portFrom?: LocalizedLocation
}

export const SailPackagesBars: FC<SailPackagesBarsProps> = ({
  selectedRoute,
  handleRouteChange,
  semiLoopSailPackages,
  fullDaySailPackages,
  fullLoopSailPackages,
  portFrom,
}) => {
  const { getTranslation } = useGetTranslations()
  const notOnlySemiloopTrips = Boolean(fullDaySailPackages.length || fullLoopSailPackages.length)
  return (
    <div className="flex w-full flex-col gap-4 px-3 pb-3 pt-4 ">
      <form className="flex flex-col gap-8 lg:flex-row">
        <div className="flex basis-1/3 flex-col gap-4 lg:min-w-[360px]">
          <SailPackagesBar
            selectedRoute={selectedRoute}
            handleRouteChange={handleRouteChange}
            title={getTranslation('semiLoopRideTitle')}
            sailPackages={semiLoopSailPackages}
            showTitle={notOnlySemiloopTrips}
          />
          <div className="hidden w-full justify-center lg:flex">
            <WaterTaxiIcon />
          </div>
        </div>

        <div className="flex basis-2/3 flex-col gap-8">
          {Boolean(fullLoopSailPackages.length) && (
            <SailPackagesBar
              sailPackages={fullLoopSailPackages}
              selectedRoute={selectedRoute}
              handleRouteChange={handleRouteChange}
              showTitle
              title={getTranslation('fullLoopRideTitle')}
              type="fullLoop"
              portFrom={portFrom}
            />
          )}
          {Boolean(fullDaySailPackages.length) && (
            <SailPackagesBar
              sailPackages={fullDaySailPackages}
              selectedRoute={selectedRoute}
              handleRouteChange={handleRouteChange}
              showTitle
              title={getTranslation('fullDayRoundTitle')}
              type="fullDay"
            />
          )}
        </div>
      </form>
      <div className="flex w-full justify-center lg:hidden">
        <WaterTaxiIcon />
      </div>
    </div>
  )
}
