import useSWR from 'swr/immutable'
import axios, { AxiosError } from 'axios'
import { Inventory } from '@/root/shared-types'
import { SalesApiError } from '@/root/shared-types/api/ApiError'

interface UseGetInventoryParams {
  locale: string
  sailPackage: string
  date: string
}

const fetchInventory = async ({ locale, sailPackage, date }: UseGetInventoryParams): Promise<Inventory> => {
  if (!sailPackage || !date) {
    throw new SalesApiError('Sail package ID and date are required', 'INVALID_REQUEST')
  }

  try {
    const { data } = await axios.get<Inventory>(`/api/inventory/${sailPackage}/${date}`, {
      headers: { 'Accept-Language': locale },
    })
    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      throw new SalesApiError(error.message, error.response?.status?.toString())
    }
    throw new SalesApiError('Unknown error occurred')
  }
}

export const useGetInventory = ({ locale, sailPackage, date }: UseGetInventoryParams) => {
  const {
    data: inventory,
    error,
    isValidating,
  } = useSWR<Inventory, AxiosError>(`get-inventory ${sailPackage} ${date}`, () =>
    fetchInventory({ locale, sailPackage, date })
  )

  return {
    inventory,
    error: error && new SalesApiError(error.message, error.response?.status?.toString()),
    isLoading: isValidating,
  }
}
