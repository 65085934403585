import { FC } from 'react'
import { ArrowLong } from '@/root/ui/admaritime'
import { useGetTranslations } from '@/root/shared-hooks'

export const SubmitButtonContent: FC = () => {
  const { getTranslation } = useGetTranslations()
  return (
    <span className="flex w-full justify-between px-6">
      <p className="flex items-center font-thin">{getTranslation('confirmationContinueToLabel')}</p>
      <div className="flex gap-3 text-2xl">
        <p className="mt-1 flex">{getTranslation('confirmationSubmitButton').toUpperCase()}</p>
        <ArrowLong color="white" />
      </div>
    </span>
  )
}
