import { FC, ComponentType } from 'react'
import { IconProps } from '@/root/shared-types/ui/ui-types'

interface EditBtnProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  Icon: ComponentType<IconProps>
  label: string
}

const EditBtn: FC<EditBtnProps> = ({ Icon, label, onClick, className, disabled }) => {
  return (
    <button
      onClick={onClick}
      className={`flex items-center gap-1 ${className} ${disabled ? 'cursor-not-allowed opacity-50' : ''}`}
      disabled={disabled}
    >
      <Icon />
      {label}
    </button>
  )
}

export default EditBtn
