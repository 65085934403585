import { ArrowIcon } from '@/root/ui/shared/icons/ArrowIcon'
import { LocationPointerIcon } from '@/root/ui/shared/icons/LocationPointerIcon'
import { FC } from 'react'

interface RouteInfoProps {
  direction: string[]
}

export const RouteInfo: FC<RouteInfoProps> = ({ direction }) => (
  <div className="flex flex-col items-center gap-3">
    {direction.map((dir, index, array) => (
      <>
        <span className="flex items-center justify-center">
          <LocationPointerIcon />
          <p className="mt-2" key={dir}>
            {dir}
          </p>
        </span>
        {index !== array.length - 1 && <ArrowIcon />}
      </>
    ))}
  </div>
)
