import { FC, HTMLAttributes } from 'react'
import cn from 'classnames'

interface RouteCardContainerButtonProps extends HTMLAttributes<HTMLButtonElement> {
  clicked?: boolean
}
export const RouteCardContainerButton: FC<RouteCardContainerButtonProps> = ({ children, onClick, clicked = false }) => (
  <button onClick={onClick}>
    <div
      className={cn('w-[328px] h-[150px] mx-auto bg-white rounded-xl flex justify-between overflow-hidden', {
        'opacity-40': clicked,
      })}
    >
      {children}
    </div>
  </button>
)
