import { FC } from 'react'

interface TaxiTableHeaderProps {
  cols: { key: string; label: string }[]
}

export const TaxiTableHeader: FC<TaxiTableHeaderProps> = ({ cols }) => (
  <nav className="grid grid-cols-[50%_25%_25%] border-b border-adm-gray-300 font-bold text-secondary">
    {cols.map((col) => (
      <p className="flex justify-center" key={col.key}>
        {col.label}
      </p>
    ))}
  </nav>
)
