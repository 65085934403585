import AppLayout from './AppLayout/components/AppLayout/AppLayout'
import PrintedTicket from './PrintedTicket/components/PrintedTicket/PrintedTicket'
import TicketActionButtons from './TicketActionButtons/components/TicketActionButtons/TicketActionButtons'
import TicketTerms from './TicketTerms/components/TicketTerms/TicketTerms'
import RefundCard from './RefundCard/components/RefundCard/RefundCard'
import { UserManual } from './UserManual/components/UserManual/UserManual'
import { DefaultPage } from '@/root/modules/admaritime/DefaultPage/DefaultPage'
import { NewsPage } from '@/root/modules/admaritime/NewsPage/components/NewsPage/NewsPage'
import { PageTitle } from '@/root/components/bluewave/PageTitle/PageTitle'
import { Notifications } from '@/root/modules/admaritime/NewsPage/components/Notifications/Notifications'
import { RowContainer } from '@/root/modules/admaritime/RowContainer/RowContainer'
import { TariffsPage } from '@/root/modules/admaritime/TariffsPage/components/TariffsPage/TariffsPage'
import { SchedulePage } from '@/root/modules/admaritime/SchedulePage/components/ScedulePage/SchedulePage'
import { SiteMap } from '@/root/modules/admaritime/SiteMap/components/SiteMap/SiteMap'
import { DesktopUserManual } from '@/root/modules/admaritime/DesktopUserManual/DesktopUserManual'
import { NewsSection } from '@/root/modules/admaritime/MainPage/components/NewsSection/NewsSection'
import NotesFooter from '@/root/modules/admaritime/MainPage/components/NotesFooter/NotesFooter'
import { VesselParametersBar } from '@/root/modules/admaritime/Fleet/components/VesselParametersBar/VesselParametersBar'
import { RouteButtonsSection } from '@/root/modules/admaritime/RouteButtonsSection/Components/RouteButtonsSection/RouteButtonsSection'
import RouteSearchBar from '@/root/modules/admaritime/RouteSearchBar/components/RouteSearchBar/RouteSearchBar'
import { BookTicket } from '@/root/modules/admaritime/BookTicket/components/BookTicket/BookTicket'
import LoginPage from '@/root/modules/admaritime/LoginPage/components/LoginPage/LoginPage'
import CustomerHeader from './CustomerInformation/CustomerHeader'
import CustomerDetails from './CustomerInformation/CustomerDetails'
import PassengersAndGroups from './CustomerInformation/PassengersAndGroups/PassengersAndGroups'

export {
  AppLayout,
  DefaultPage,
  TicketActionButtons,
  PrintedTicket,
  TicketTerms,
  RefundCard,
  UserManual,
  NewsPage,
  PageTitle,
  Notifications,
  RowContainer,
  TariffsPage,
  SchedulePage,
  SiteMap,
  NewsSection,
  DesktopUserManual,
  NotesFooter,
  VesselParametersBar,
  RouteButtonsSection,
  RouteSearchBar,
  BookTicket,
  LoginPage,
  CustomerHeader,
  CustomerDetails,
  PassengersAndGroups,
}
