import { CloseIcon } from '@/root/ui/shared/icons/CloseIcon'
import { FC, HTMLAttributes, useEffect } from 'react'

export interface ModalProps extends HTMLAttributes<HTMLDivElement> {
  isOpen: boolean
  onClose: () => void
}

export const Modal: FC<ModalProps> = ({ children, isOpen, onClose }) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose()
      }
    }
    if (isOpen) {
      document.body.style.overflow = 'hidden'
      window.addEventListener('keydown', handleKeyDown)
    } else {
      document.body.style.overflow = ''
    }
    return () => {
      document.body.style.overflow = ''
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isOpen, onClose])

  if (!isOpen) return null
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="size-fit max-w-screen-lg bg-white p-5 pb-10 shadow-lg edit-modal-content">
        <div className="flex justify-end pb-2">
          <button onClick={onClose}>
            <CloseIcon color="neutral-medium-light" />
          </button>
        </div>
        {children}
      </div>
    </div>
  )
}
