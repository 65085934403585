import { FC, Dispatch, SetStateAction, useEffect, useRef } from 'react'
import Link from 'next/link'
import { MenuItem } from '@/root/shared-types'
import { useIntl } from 'react-intl'
import { takeAnother } from '../../helpers/takeAnother'
import { AvailableTheme, getMessages } from '@/lang'
import { useConfig, useCustomRouter, useGetTranslations } from '@/root/shared-hooks'
import { Button } from '@/root/ui/admaritime'
import { useOpenUserManual } from '@/root/shared-hooks/useOpenUserManual/useOpenUserManual'
import { APP_ROUTES } from '@/root/shared-consts'
import { CloseIcon } from '@/root/ui/shared/icons/CloseIcon'

interface MobileMenuProps {
  menuItemsLine1: MenuItem[]
  menuItemsLine2: MenuItem[]
  mobileMenuOpen: boolean
  setMobileMenuOpen: Dispatch<SetStateAction<boolean>>
}

const MobileMenu: FC<MobileMenuProps> = ({ menuItemsLine1, menuItemsLine2, mobileMenuOpen, setMobileMenuOpen }) => {
  const { formatMessage } = useIntl()
  const { theme } = useConfig()
  const { locale, locales, asPath, events } = useCustomRouter()
  const nextLocale = takeAnother(locales as string[], locale)
  const currentMessages = getMessages(theme as AvailableTheme)
  const { getTranslation } = useGetTranslations()

  const langSwitcherRef = useRef<HTMLLIElement>(null)
  const { onManualOpen } = useOpenUserManual()

  useEffect(() => {
    const handleRouteChange = () => {
      const langSwitcherCLick = Boolean(langSwitcherRef.current?.contains(document.activeElement))
      setMobileMenuOpen(langSwitcherCLick)
    }

    events.on('routeChangeComplete', handleRouteChange)

    return () => {
      events.off('routeChangeComplete', handleRouteChange)
    }
  }, [events, setMobileMenuOpen])

  return (
    <>
      {mobileMenuOpen && (
        <div className="text-primary-base fixed z-20 flex size-full flex-col overflow-auto bg-white pb-8 pe-6 ps-5 md:hidden">
          <div className="mb-5 mt-12 flex justify-end">
            <button onClick={() => setMobileMenuOpen(false)}>
              <CloseIcon color="primary-base" />
            </button>
          </div>

          <ul className="flex flex-col">
            <li ref={langSwitcherRef} className="flex h-12 items-center text-lg font-normal">
              <Link href={asPath} locale={nextLocale}>
                {formatMessage(currentMessages.headerLangSwitcher)}
              </Link>
            </li>
            {menuItemsLine1?.map(({ id, link, subject }) => {
              return link === 'userGuideModal' ? (
                <li key={id} className="flex h-12 items-center text-xl font-bold">
                  <button className="w-fit" onClick={onManualOpen}>
                    {subject}
                  </button>
                </li>
              ) : (
                <li key={id} className="flex h-12 items-center text-xl font-bold">
                  <Link href={link}>{subject}</Link>
                </li>
              )
            })}
          </ul>

          <ul className="flex flex-col text-base font-bold">
            {menuItemsLine2?.map(({ id, link, subject, className }) => {
              if (className === 'login') {
                return (
                  <li key={id} className="order-first">
                    <Link href={APP_ROUTES.signup}>
                      <Button variant="contained">{getTranslation('headerRegisterOrLoginButton')}</Button>
                    </Link>
                  </li>
                )
              }
              return (
                <li key={id} className="flex h-12 items-center">
                  <Link href={link}>{subject}</Link>
                </li>
              )
            })}
          </ul>
        </div>
      )}
    </>
  )
}

export default MobileMenu
