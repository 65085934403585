import { InfoFieldId } from '@/root/shared-types/additionalInfo/additional-info-types'
import { Attribute, Guest } from '@/root/shared-types/reservation/reservation-types'
import { DateTime } from 'luxon'
export const getValueFromAttribute = (attributes: Attribute[] | undefined, attributeId: InfoFieldId) => {
  if (!attributes) {
    return attributes
  }
  const desiredAttribute = attributes.find((atr) => atr.attribute.code === attributeId)

  return desiredAttribute?.structure.value?.__type === 'file'
    ? desiredAttribute?.structure.value?.fileName
    : desiredAttribute?.structure.value?.value
}

export const capitalizeFirstLetter = (string: string | number) => {
  if (typeof string === 'string') {
    return string.toLowerCase().charAt(0).toUpperCase() + string.toLowerCase().slice(1)
  }
  return string
}

export const getPersonalPassengerData = (
  property: 'firstName' | 'lastName' | 'birthday' | 'number' | 'expireDate' | 'birthPlace',
  driver?: Guest
) => {
  if (!driver) {
    return ''
  }
  if (property === 'number' || property === 'expireDate') {
    return driver.travelDocument[property]
  }
  return driver[property]
}

export const getDateFromTimeStamp = (date: Date) =>
  DateTime.fromJSDate(date, { zone: 'UTC' }).toFormat('dd LLL yyyy HH:mm:ss') + ' UTC'
