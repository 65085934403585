import { FC } from 'react'
import { LoginPageTab } from '@/root/shared-types/ui/ui-types'
import { SignUpIconBlue } from '@/root/ui/shared/icons/SignUpIconBlue'
import { SignInIconBlue } from '@/root/ui/shared/icons/SignInIconBlue'
import cn from 'classnames'
import { useGetTranslations } from '@/root/shared-hooks'

interface TabSelectorProps {
  selectedTab: LoginPageTab
  setSelectedTab: (tab: LoginPageTab) => void
}

export const TabSelector: FC<TabSelectorProps> = ({ selectedTab, setSelectedTab }) => {
  const { getTranslation } = useGetTranslations()

  return (
    <div className="flex w-full flex-col bg-adm-gray-50 text-base">
      <button
        className={cn(
          'flex h-[100px] items-center gap-2.5 border-s-[6px] ps-4',
          selectedTab === 'signup' ? 'border-secondary' : 'border-primary-base'
        )}
        onClick={() => setSelectedTab('signup')}
      >
        <SignUpIconBlue color={selectedTab === 'signup' ? 'secondary' : 'primary-base'} />
        <p className={cn(selectedTab === 'signup' ? 'text-secondary' : 'text-primary-base', 'hidden sm:block')}>
          {getTranslation('signUpTitle')}
        </p>
      </button>

      <button
        className={cn(
          'flex h-[100px] items-center gap-4 border-s-[6px] ps-4',
          selectedTab === 'login' ? 'border-secondary' : 'border-primary-base'
        )}
        onClick={() => setSelectedTab('login')}
      >
        <SignInIconBlue color={selectedTab === 'login' ? 'secondary' : 'primary-base'} />
        <p className={cn(selectedTab === 'login' ? 'text-secondary' : 'text-primary-base', 'hidden sm:block')}>
          {getTranslation('signInTitle')}
        </p>
      </button>

      <button
        className={cn(
          'h-[100px] border-s-[6px] text-start',
          selectedTab === 'recovery' ? 'border-secondary text-secondary' : 'border-primary-base text-primary-base'
        )}
        onClick={() => setSelectedTab('recovery')}
      >
        <span className="hidden ps-4 sm:block">{getTranslation('signForgotPass')}</span>
        <span className="ps-5 text-xl sm:hidden">?</span>
      </button>
      <div className="border-primary-base grow border-s-[6px]" />
    </div>
  )
}

export default TabSelector
