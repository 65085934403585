import { FC, useEffect, useMemo, useState } from 'react'
import { Route as RouteProps } from '@/root/shared-types'
import { PageTitle } from '@/root/modules/admaritime'
import { Tabs } from '@/root/modules/admaritime/AppLayout/components/Tabs/Tabs'
import { Route } from '../Route/Route'
import { useGetTranslations } from '@/root/shared-hooks'
import { ContentContainer } from '@/root/ui/admaritime'

interface SchedulePageProps {
  routes: RouteProps[]
}

export const SchedulePage: FC<SchedulePageProps> = ({ routes }) => {
  const { getTranslation } = useGetTranslations()
  const tabs = useMemo(
    () =>
      routes
        ?.sort((a, b) => a.sortOrder - b.sortOrder)
        .reduce<string[]>(
          (total, currentValue) => (currentValue.schedule !== null ? [...total, currentValue.title] : total),
          []
        ),
    [routes]
  )
  useEffect(() => {
    setCurrentTab(tabs[0])
  }, [tabs])
  const [currentTab, setCurrentTab] = useState<string>(tabs[0])

  const currentRoute = routes?.find(({ title }) => title === currentTab)

  return (
    <ContentContainer>
      <PageTitle>{getTranslation('schedulePageTitle')}</PageTitle>
      <Tabs tabs={tabs} currentTab={currentTab} setTab={(tab: string) => setCurrentTab(tab)} />
      <div className="w-full">{currentRoute && <Route {...currentRoute} />}</div>
    </ContentContainer>
  )
}
