import { FC } from 'react'
import CustomerInfo from '../CustomerInfo/CustomerInfo'
import DepartureAndVesselInfo from '../DepartureAndVesselInfo/DepartureAndVesselInfo'
import RouteInfo from '../RouteInfo/RouteInfo'
import TicketItemsWithQR from '../TicketItemsWithQR/TicketItemsWithQR'
import VehiclesWithNumbers from '../VehiclesWithNumbers/VehiclesWithNumbers'
import { CustomerInfoProps, TicketPartProps } from '@/root/shared-types/reservation/reservation-types'
import cn from 'classnames'

interface PrintedTicketPartProps extends TicketPartProps {
  reservationId: number
  cancelled: boolean
  isRoundTrip: boolean
  customerInfo?: CustomerInfoProps
}

const PrintedTicketPart: FC<PrintedTicketPartProps> = ({
  reservationId,
  vesselTitle,
  numOfPassengers,
  numOfCars,
  sailRefId,
  items,
  vehicles,
  departurePort,
  arrivalPort,
  departure,
  customerInfo,
  cancelled,
  arrival,
  isRefund,
  isRoundTrip,
}) => {
  return (
    <div
      className={cn('md:px-5 md:py-[10px] md:border', {
        'border-4 p-1 border-alert-base md:border-[5px]': isRefund,
        'last:border-t-0 first:border-b-0 first:rounded-t-xl last:rounded-b-xl': isRoundTrip,
        'rounded-xl': !isRoundTrip,
      })}
    >
      {customerInfo && (
        <CustomerInfo
          customerEmail={customerInfo.email}
          customerName={customerInfo.fullName}
          customerPhoneNumber={customerInfo.phoneNumber}
          ticketNumber={reservationId}
        />
      )}
      <DepartureAndVesselInfo
        departure={departure || ''}
        vesselTitle={vesselTitle}
        numOfPassengers={numOfPassengers || 0}
        numOfCars={numOfCars || 0}
        cancelled={cancelled}
      />
      <RouteInfo arrivalPort={arrivalPort} arrival={arrival || ''} departurePort={departurePort} />
      <TicketItemsWithQR items={items} reservationId={reservationId} sailRefId={sailRefId} />
      <VehiclesWithNumbers vehicles={vehicles} />
    </div>
  )
}

export default PrintedTicketPart
