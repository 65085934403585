import { FC, InputHTMLAttributes } from 'react'
import cn from 'classnames'

interface RadioButtonProps extends InputHTMLAttributes<HTMLInputElement> {
  htmlFor: string
}

const CLASS_NAME = 'absolute w-5 h-5 rounded-full transition-all duration-[450ms]'

const RadioButton: FC<RadioButtonProps> = ({ htmlFor, checked = false, value, children, className, ...inputProps }) => (
  <label className={cn('flex gap-9 cursor-pointer pointer-events-auto', className)} htmlFor={htmlFor}>
    <div className="relative">
      <input
        type="radio"
        checked={checked}
        value={value}
        {...inputProps}
        className="pointer-events-none absolute size-px appearance-none opacity-0"
      />

      <span
        className={cn(CLASS_NAME, 'border-2 border-neutral-normal-light', {
          'opacity-100 scale-100': !checked,
          'opacity-0 scale-0': checked,
        })}
      />

      <span
        className={cn(CLASS_NAME, 'border-[5px] border-secondary', {
          'opacity-0 scale-0': !checked,
          'opacity-100 scale-100': checked,
        })}
      />
    </div>
    {children}
  </label>
)

export default RadioButton
