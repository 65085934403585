import { axios } from '@/configs'
import { FooterItems, Locale, MenuItem, Page, ResponseContent, Slug } from '@/root/shared-types'

class SalesStaticService {
  async getFooterItems(locale: string) {
    const { data: footerItems } = await axios<FooterItems>({
      method: 'get',
      url: 'sales-static/footer',
      headers: { 'Accept-Language': locale },
    })
    return footerItems
  }

  async getMenuItems(locale: string) {
    const { data: menuItems } = await axios<MenuItem[]>({
      url: 'sales-static/menu-items',
      method: 'get',
      headers: { 'Accept-Language': locale },
    })
    return menuItems
  }
  async getPages(locale: Locale, slug: Slug) {
    const { data: pages } = await axios<ResponseContent<Page>>({
      url: 'sales-static/pages',
      method: 'post',
      headers: { 'Accept-Language': locale },
      data: { slug },
    })
    return pages
  }
}

const salesStaticService = new SalesStaticService()

export default salesStaticService
