import { Checkbox } from '@/root/ui/admaritime'
import { FC } from 'react'
import { useGetTranslations } from '@/root/shared-hooks'
import { RefundAgreementProps } from '@/root/shared-types'

export const RefundAgreement: FC<RefundAgreementProps> = ({ isAgreementAccepted, onAcceptAgreement }) => {
  const { getTranslation } = useGetTranslations()
  return (
    <div className="flex flex-col gap-4">
      <p className="text-neutral">{getTranslation('amountRefundTip')}</p>
      <Checkbox
        label={getTranslation('refundAgreement')}
        checked={isAgreementAccepted}
        onChange={onAcceptAgreement}
        variant="error"
        className="gap-6"
      />
    </div>
  )
}
