import { FC } from 'react'
import EditBtn from '@/root/ui/admaritime/EditBtn/EditBtn'
import { PenIcon } from '@/root/ui/shared/icons/PenIcon'
import { useGetTranslations } from '@/root/shared-hooks'
import { AgreeIcon } from '@/root/ui/shared/icons/AgreeIcon'
import { CancelIcon } from '@/root/ui/shared/icons/CancelIcon'

interface Props {
  label: string
  onToggleEdit: () => void
  isEditing: boolean
  onCancel: () => void
  onSave: () => void
  isSaveDisabled: boolean
}

const EditController: FC<Props> = ({ label, onToggleEdit, isEditing, onSave, isSaveDisabled }) => {
  const { getTranslation } = useGetTranslations()

  return (
    <div className="text-secondary me-0 flex cursor-pointer flex-row items-start justify-center gap-2 text-base font-bold md:me-4 md:justify-end">
      {isEditing ? (
        <div className="flex flex-row justify-end gap-5">
          <EditBtn
            Icon={() => <AgreeIcon />}
            label={getTranslation('customerInformationSave')}
            onClick={onSave}
            disabled={isSaveDisabled}
            className="text-adm-cyan"
          />
          <EditBtn
            className="text-error"
            Icon={() => <CancelIcon />}
            label={getTranslation('customerInformationCancel')}
            onClick={onToggleEdit}
          />
        </div>
      ) : (
        <EditBtn Icon={() => <PenIcon />} label={label} onClick={onToggleEdit} className="text-adm-cyan" />
      )}
    </div>
  )
}

export default EditController
